@import '../settings';

.pagenotfound{
    position:relative;
    width: 100vw;
    height: 100vh;
    font-family: $home-font;
    overflow: hidden;
    display: flex;
    background-color: #cfd8d8;
    flex-direction: column;
    justify-content: center;
    .vdBg,.container{
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .vdBg{
		position: absolute;
		width: 60%;
        margin-left: 20.5% !important;
        margin-top: auto;
        margin-bottom: auto;
		object-fit: cover;

		z-index: 1;
	}
    .container{
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        text-align: center;
        h1, h2, button{
            color: aliceblue;
        }
        h1{
            font-size: 36px;
            font-weight: 800;
            margin-bottom: 0px;
            background: -webkit-linear-gradient(-20deg, #ffffff, #9e9e9e);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
        }
        h2{
            color: rgb(227, 227, 227);
            margin-top: 0px;
            margin-bottom: 0px;
        }
        h3{
            font-size: 14px;
            width: 300px;
            margin-left: auto;
            margin-right: auto;
            font-weight: 300;
            color: rgb(211, 211, 211);
        }
        button{
            background-color: transparent;
            border: none;
            font-size: 16px;
            font-weight: 300;
            padding: 10px 20px;
            backdrop-filter: blur(45px);
            border-radius: 20px;
            border: 1px solid rgba(240, 248, 255, 0.085);
            margin-top: 20px;
            cursor: pointer;
            transition: 0.2s;
        }
        button:hover{
            border-color: transparent;
            border-radius: 12px;
        }
    }
}

@media screen and  (max-width: 1000px) {
    .pagenotfound{
        .vdBg{
            width: 90% !important;
            margin-left: 5% !important;
        }
    }
}
@media screen and  (max-width: 700px) {
    .pagenotfound{
        .vdBg{
            width: 130% !important;
            margin-left: -15% !important;
        }
    }
}
@media screen and  (max-width: 500px) {
    .pagenotfound{
        .vdBg{
            width: 150% !important;
            margin-left: -25% !important;
        }
    }
}