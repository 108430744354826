@import '../settings';
.profile_card_content{
    color: $forum_font_white;
    background-color: $forum-light;
    text-align: center;
    width: $side_max_width !important;
    max-width: $side_max_width !important;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid rgba(155, 210, 255, 0.09);
    margin-bottom: 8px;
    .divdiv{
        height: 95px;
        img{
            width: 100%;
            height: 110px;
            object-fit: cover;
        }
    }


    .profile_card_top{
        display: flex;
        flex-direction: column;
        margin-top: -50px;
        img{
            margin-left: auto;
            margin-right: auto;
            width: 82px;
            background-color: $forum-light;
            height: 82px;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid $forum-light;
        }
        h1{
            font-size: 21px;
            font-weight: 700;
            margin-top: 5px;
            color: $forum_font_header;
        }
        h2{
            color: $forum_font_lightgray;
            margin-top: -12px;
            margin-bottom: 2px;
            font-size: 17px;
            font-weight: 300;
        }
        h3{
            margin-top: 15px;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            font-weight: 400;
            margin-bottom: 25px;
            font-size: 17px;
        }
    }

    .profile_card_mid{
        border-top: 2px solid $forum-clear;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-evenly;
        flex-direction: row;
        border-bottom: 2px solid $forum-clear;
        padding-bottom: 5px;
        padding-top: 5px;
        .bar{
            border-right: 2px solid $forum-clear;
        }
        h1{
            margin-bottom: 8px;margin-top: 5px;
            font-weight: 400;
            color: $forum_font_lightgray;
            font-size: 15px;
        }
        p{
            margin-bottom: 5px;
            margin-top: 10px;
            font-weight: 500;
            font-size: 18px;
        }
    }
    
    .profile_card_bottom{
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        h2{
            font-size: 17px;
            font-weight: 400;
            color: $form_blue-logo;
            transition: 0.3s;
        }
        h2:hover{
            transform: scale(1.05);
        }
    }
}

@media screen and  (max-height: 800px ){
   .profile_card_top{
        margin-top: -60px !important;
        img{
            border: 1px solid $forum-light;
        }
        h1{
            font-size: 22px !important;
            margin-top: 8px !important;
        }
        h3{
            font-size: 13px !important;
            margin-bottom: 13px !important;
        }
   }

   .profile_card_bottom{
        h2{
            margin-top: 8px;
            margin-bottom: 8px;
        }
   }
}




