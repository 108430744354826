@import '../settings';

.home_div{
	font-family: $home-font;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;
	justify-content: space-between;
	.vdBg{
		position: absolute;
		width: 100%;
		min-width: 100%;
		object-fit: cover;
		min-height: 100vh;
		max-height: 99vh;
		z-index: 1;
	}
    width: 100%;
	height: 100vh;
	background-color: $homle-page-bg;
	color: white;
	button{
		font-family: $home-font;
		padding: 10px 18px 10px 18px;
		color: rgb(255, 255, 255);
		border-radius: 14px;
		font-weight: 600;
		border: 1px solid transparent; 
		background-color: transparent;
		font-size: 15px;
		margin-left: 15px;
		transition: 0.3s;
		cursor: pointer;
	}
	button:hover{
		border-radius: 20px;
	}
	.btn-1{
		color: rgb(222, 240, 255);
		backdrop-filter: blur(20px);
		background-color: rgba(0, 0, 0, 0.043);
		font-weight: 400;
		border: 2px solid rgba(193, 226, 255, 0.2);
	}
	.btn-2{
		background-color: #00b3ff;
		border-color: rgba(172, 218, 255, 0.836);
	}
	.header, .content, .bottom{
		z-index: 2;
	}
	.header{
		backdrop-filter: blur(40px);
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		height: 70px;
		min-height: 70px;
		border-bottom: 1px solid rgba(240, 248, 255, 0.111);
		.left{
			margin-left: 7%;
			display: flex;
			flex-direction: row;
			.ttttle{
				font-family: $nitro-font;
			}
			h1{
				span{
					color: #00b7ff;
					font-size: 20px;
				}
				font-size: 22px;
				margin-top: auto;
				font-weight: 800;
				margin-bottom: auto;
				background: -webkit-linear-gradient(-20deg, #1b90d8, #3fefff);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		.mid{
			display: flex;
			flex-direction: row;
			h2{
				font-size: 15px;
				margin-top: auto;
				margin-bottom: auto;
				font-weight: 300;
				margin-left: 23px;
				cursor: pointer;
				padding-top: 5px;
				padding-bottom: 5px;
				transition: 0.3s;
				border-bottom: 1px solid transparent;
				color: white;
			}
	
			h2:hover{
				color: white;
				border-color: rgba(255, 255, 255, 0.306);
				transform: translateY(1px);
			}
		}
		.right{
			button{
				padding: 10px 14px;
				font-size: 14px;
				border: 2px solid rgba(172, 218, 255, 0.836);
			}
			margin-right: 7%;
			margin-top: auto;
			margin-bottom: auto;
		}

	}

	.content{
		margin-left: 7%;
		margin-right: 7%;
		display: flex;
		flex-direction: column;
		.top{
			display: grid;	
			grid-template-columns: 1fr 1.2fr;
			.left{
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-right: 20px;
				.search{
					margin-top: 15px;
					border-radius: 22px;
					display: flex;
					flex-direction: row;
					transition: 0.2s;
					justify-content: space-between;
					width: 90%;
					padding: 6px;
					border: 1px solid rgba(162, 210, 255, 0.255);
					background-color: rgba(255, 255, 255, 0.117);
					backdrop-filter: blur(15px);
					input{
						width: 100%;
						padding-left: 6px;
						font-family: $home-font;background-color: transparent;border: none;
					}
					input, input::placeholder{
						font-size: 17px;
						color: #e4f3ff;
					}
					input{
						color: white;
					}
					input:focus{outline: none;}

					a{
						height: max-content !important;
						margin-top: auto !important;
						margin-bottom: auto !important;
					}
					button{
						border-radius: 16px;
						margin-right: 7px;
						transition: 0.2s;
					}
				}
				.search:hover{
					button{
						border-radius: 12px;
					}
					border-radius: 12px;
				}
				button{
					margin-left: 2px !important;
				}
				.btns{
					display: flex;
					flex-direction: row;
					margin-top: 30px;
					a{
						min-width: 44% !important;
						width: 44% !important;
						margin-right: 15px !important;
					}
					button{
						width: 100% !important;
					}
				}
			}
			.spline_div{
				display: flex;
				flex-direction: column;
				margin-top: auto;
				margin-bottom: auto;
				height: 92%;
				justify-content: center;
				.swiper{
					margin-top: auto !important;
					margin-bottom: auto !important;
					width: 100%;
					max-height: 600px;
					overflow-y: hidden;
					position: relative;
					.swiper-slide {
						display: flex;
						flex-direction: column;
						justify-content: center;
						.templateVid {
							margin-left: auto;
							margin-right: auto;
							border-radius: 25px;
							border: 5px solid rgba(240, 248, 255, 0.111);
							width: 85%;
						}
					}
					.swiper-pagination-bullet{
						width: 11px !important;
						height: 11px !important;
						margin-left: auto !important;
						margin-right: auto !important;
						background-color: rgb(216, 237, 255) !important;
					}
					.swiper-pagination-bullet-active{
						border: 2px solid $form_blue-logo;	
					}
				}
			
				.discover_home{
					background-color: rgba(255, 255, 255, 0.038);
					border: 2px solid rgba(206, 232, 255, 0.084);
					backdrop-filter: blur(25px);
					border-radius: 20px;
					margin-left: auto;
					margin-right: auto;
					height: 95%;
					width: 87%;
					overflow-y: scroll;
					margin-top: auto !important;
					margin-bottom: auto !important;
					.find{
						text-align: center;
						font-size: 18px;
						font-weight: 800;
						span{
							background: -webkit-linear-gradient(-20deg, #1b90d8, #3fefff);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}
					.discover_div{
						display: flex;
						flex-direction: row;
						margin-left: 6px;
						margin-top: 10px;
						width: 98%;
						border-top: 1px solid rgba(204, 231, 255, 0.156);
						padding-top: 10px;
						.rw{display: flex;flex-direction: row;}
						.cl{display: flex;flex-direction: column;}
						img, video{
							object-fit: cover;
							border-radius: 12px;
							border: 1px solid rgba(166, 213, 255, 0.125);
						}
						.rCl{
							display: flex;
							flex-direction: column;
							justify-content: center;
						}
						.bnrrr{
							min-width: 50%;
							margin-top: auto;
							margin-bottom: auto;
							max-width: 50%;
							max-height: 160px;
							min-height: 160px;
							margin-right: 6px;
						}
						.pfp{
							width: 50px;
							height: 50px;
						}
						h1, h2{
							margin-left: 6px;
							margin-top: 0px;
							margin-bottom: 0px;
							color: white;
						}
						h1{
							margin-top: 5px;
							font-size: 18px;
							font-weight: 700;
						}
						h2{
							font-size: 12px;
							font-weight: 300;
						}
						h3{
							margin-left: 5px;
							max-width: 90%;
							font-size: 13px;
							font-weight: 500 !important;
							color: white;
						}
						h3, h4{
							margin-top: auto !important;
							margin-bottom: auto !important;
						}
						h4{
							background-color: rgba(14, 26, 41, 0.258);
							border-radius: 5px;
							font-weight: 500;
							padding: 3px 9px 3px 9px;
							margin-left: 5px;
							font-size: 12px;
							width: max-content;
						}
					}
				}
			}

			.self_presentation{
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				width: 90%;
				height: 80%;
				backdrop-filter: blur(55px);
				border-radius: 20px;
				border: 2px solid rgba(197, 228, 255, 0.164);
				img{
					margin-left: auto;
					margin-right: auto;
					width: 85px;
					height: 85px;
					border: 3px solid rgba(217, 237, 255, 0.369);
					border-radius: 10px;
				}
				h1{
					font-size: 30px;
					margin-bottom: 0px;
				}
				h2{
					margin-top: 0px;
					font-size: 21px;
					color: #1b90d8;
					span{
						font-size: 24px;
						font-weight: 800;
						background: -webkit-linear-gradient(-20deg, #1b90d8, #3fefff);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						
					}
				}
				p{
					font-weight: 300;
					font-size: 15px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 30px;
					width: 70%;
				}
				button{
					margin-bottom: 7px;
				}
				.gt{
					border: 2px solid rgba(251, 240, 255, 0.213);
					background-color: rgb(42, 42, 42);
				}
				.apl{
					border: 2px solid #ffffffc2;
					background-color:  #0085dd;
				}
				.ggl{
					border: 2px solid #ffffffc2;
					background-color: rgba(37, 220, 0, 0.903);
				}
			}


			h1{
				font-size: 60px;
				font-weight: 800;
				margin-top: 5px;
				margin-bottom: 10px;
				span{
					background: -webkit-linear-gradient(-20deg, #0f77ff, #3fefff);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			h2{
				font-weight: 300;
				color: rgb(227, 243, 255);
				font-size: 15px;
			}
			h3{
				font-size: 17px;
				font-weight: 800;
				margin-top: 0px;
				margin-bottom: 0px;
				margin-right: 12px;
				transition: 0.2s;
				cursor: pointer;
			}
			h3:hover{
				transform: translateY(1px);
			}
			.socials{
				display: flex;
				width: max-content;
				padding: 5px;
				margin-top: 5px;
				margin-bottom: 5px;
				padding-left: 13px;
				padding-right: 3px;
				border-radius: 12px;
				border: 1px solid rgba(200, 229, 255, 0.111);
				background-color: rgba(152, 207, 255, 0.123);
				backdrop-filter: blur(45px);
				flex-direction: row;

			}
		}



	}


	.bottom{
		width: 80%;
		margin-bottom: 45px;
		border-radius: 10px;
		background-color: rgba(167, 220, 255, 0.1);
		backdrop-filter: blur(30px);
		display: flex;
		margin-left: auto;
		padding-top: 27px;
		padding-bottom: 27px;
		margin-right: auto;
		flex-direction: row;
		border: 2px solid rgba(255, 255, 255, 0.068);
		justify-content: space-around;
		div{display: flex; flex-direction: column; justify-content: center;}
		.arw{
			font-size: 24px;
			color: rgba(171, 216, 255, 0.772);
		}
		h3, h4{text-align: center;margin-top: 0px; margin-bottom: 0px;}
		h3{
			margin-bottom: 0px;
			font-size: 30px;
			word-spacing: 4px;
			span{
				color: #00b3ff;
			}
		}
		h4{
			font-size: 15px;
			font-weight: 300;
			margin-top: 0px;
			color: rgb(228, 243, 255);
		}
		p{
			position: absolute;
			padding: 3px 12px;
			padding-bottom: 4px;
			border: 0px solid #b3dbff23;
			font-size: 11px;
			max-height: 20px;
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.053);
			right: 4px;
			margin-top: -63px;
			span{
				font-weight: 700;
			}
			i{
				font-size: 13px;
				font-style: normal;
			}
		}
	}
}

@media screen and (max-height : 816px){
	.home_div{
		.content{
			.top{
				h1{
					font-size: 42px;
				}
			}

			.spline_div{
				height: 83% !important;
			}
		}
		.bottom{
			margin-top: -40px !important;
		}
	}
}

@media screen and  (max-width: 1190px) {
	.home_div{
		.content{
			margin-left: 3% !important;
			margin-right: 3% !important;
			.top{
				h1{
					font-size: 42px;
				}
			}
		}
	}
}
@media screen and  (max-width: 1000px) {
	.home_div{
		.header{
			.left{margin-left: 2% !important;}
			.right{margin-right: 2% !important;}
			.mid{
				h2{
					margin-left: 2px !important;
				}
			}
		}
		.content{
			.top{
				.search{
					button{
						padding: 8px 10px !important;
						font-size: 13px !important;
					}
				}
				.discover_home{
					.discover_div{
						.bnrrr{
							min-width: 40% !important;
							max-width: 40% !important;
							max-height: 120px !important;
							min-height: 120px !important;
						}
						h1{
							font-size: 16px !important;
						}
						h3{
							font-size: 10px !important;
						}
					}
				}
			}

		}
		.bottom{
			width: 92% !important;
		}
	}
}

@media screen and  (max-width: 790px) {
	.home_div{
		.content{
			.top{
				h1{
					font-size: 38px;
				}
			}
		}
	}

}
@media screen and  (max-width: 730px) {
	.home_div{
		overflow-y: scroll !important;
		.header{
			.mid{
				h2{
					font-size: 13px !important;
				}
			}
			.right{
				button{
					font-size: 13px !important;
					padding: 7px 11px !important;
					border: 2px solid rgba(188, 224, 255, 0.381) !important;
				}
			}
		}
		.content{
			.top{
				display: flex !important;
				flex-direction: column !important;
				justify-content: center !important;

				.left{
					margin-top: 50px;

					h1,h2, h3{
						text-align: center;
					}
					.socials{
						margin-left: auto;
						margin-right: auto;
					}
					.search{
						input, ::placeholder{
							font-size: 14px !important;
						}
						margin-left: auto !important;
						margin-right: auto !important;
					}
					.btns{
						margin-bottom: 30px !important;
						justify-content: center;
					}
				}
				.left, .spline_div{
					padding-right: 0px !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
			}
		}
		.bottom{
			margin-top: 20px !important;
			padding-bottom: 0px !important;
			width: 38% !important;
			flex-direction: column !important;
			div{
				border-bottom: 1px solid rgba(198, 228, 255, 0.204);
				padding-bottom: 20px;
			}
			.arw{
				*{
					display: none;
				}
				padding-top: 10px;
				border-bottom: 0px;
			}
		}
	}
}
@media screen and  (max-width: 665px) {
	.home_div{
		.header{
			.mid{
				display: none !important;
			}
		
		}
	}
}
