@import '../settings';

.new_message_div{
    background-color: $forum-light;
    border-radius: 16px;
    border: 1px solid rgba(176, 218, 255, 0.087);
    display: flex;
    flex-direction: column;
    padding-right: 3px;
    .bottom_newmsg, .top_newmsg{
        display: flex;
        margin-top: 10px;
        flex-direction: row;
    }
    input{font-family: $alt-font;}

    .top_newmsg{
        padding-top: 2px;
        width: 97%;
        margin-left: 15px;
        margin-top: 10px;  
        p{
            position: absolute;
            margin-top: 87px;
            background-color: rgb(52, 64, 74);
            min-width: 45px;
            padding: 3px 5px 3px 5px;
            text-align: center;
            border-radius: 7px;
            border: 1px solid  rgb(19, 28, 36);
            margin-left: 11px;
            color: aliceblue;
            font-weight: 500;
            font-size: 11px;
        }
        input:hover{
            border-color: $form_blue-logo;
        }
        img{
            width: 77px;
            border: 2px solid rgba(25, 32, 38, 0.519);
            object-fit: cover;
            height: 77px;
            border-radius: 50%;
        }   
        ::placeholder{
            color: $forum-font-white;
            font-size: 17px;     
        }

        .inputs{
            display: flex;
            flex-direction: row;
            width: 87%;
            input:focus{
                outline: none;
            }

            input{
                height: 29px;
                background-color: $forum-clear;
                border: none;
                width: 78%;
                margin-bottom: auto;
                margin-top: 8px;
                padding: 7px 12px 7px 12px;
                border-radius: 10px;
                margin-left: 10px;
                font-size: 17px;
                border: 1px solid $forum-bg-alt;
                box-shadow: 0px 2px 0px $forum-bg-alt;
                color: $forum-font-white;
                transition: 0.2s;
            }
            input:focus{
                border-radius: 15px;
                border-color: $form_blue-logo;
                box-shadow: 0px 2px 0px  $form_blue-logo;
            }
            button{
                width: 45px;
                height: 40px;
                border-radius: 10px;
                padding-right: 8px;
                margin-top: 11px;
                text-align: center;
                color: aliceblue;
                background-color: $form_blue-logo;
                font-size: 15px;
                cursor: pointer;
                margin-right: 10px;
                font-weight: 600;
                border: 2px solid aliceblue;
                word-spacing: -2px;
                transition: 0.3s;
            }
            button:hover{
                border-color: rgba(240, 248, 255, 0.4);
                color: $forum-font-header;
            }
            button:active{
                transform: scale(0.92);
            }
       
            .sending{
                transform: scale(0.85);
                color: rgba(187, 223, 255, 0.836);
            }
            .chat{
                min-height: 40px;
                min-width: 40px;
                border-radius: 50%;
            }
            .photoUpld{
                white-space: nowrap;
                font-weight: 600;
                font-size: 14px;
                word-spacing: 3px;
                margin-left: 7px;
                width: 100px;
                border-radius: 7px;
            }
            .msg_upload_pic{
                display: none;
            }
        }
 
    }





    .bottom_newmsg,.tags_div{
        display: flex;
        flex-direction: row;
    }
    .bottom_newmsg{
        justify-content: start;
        margin-left: 110px;
        width: 80%;
        margin-top: -5px;
        position: relative;
        margin-bottom: 20px;
        div{
            display: flex;
            flex-direction: row;
        }
        input{font-family: $alt-font;}
        input{
            border: 0px solid transparent;
            background-color: $forum-clear;
            height: 20px;
            width: 90%;
            color: aliceblue;
            transition: 0.2s;
            box-shadow: 0px 3px 0px rgb(22, 32, 39);
            border-radius: 7px;
            border: 1px solid transparent;
            margin-left: 6px;
            padding: 4px 9px 4px 9px;      
        }
        input:focus{
            border-radius: 10px;
            border: 1px solid $form_blue-logo;
            box-shadow: 0px 2px 0px  $form_blue-logo;
        }
        p{
            font-size: 21px;
            color: rgb(205, 232, 255);
        }
        input, p{
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        input:focus{
            outline: none;
        }
        .trend{
            width: 40%;
        }
        .identify{
            width: 55%;
            margin-left: 20px;
        }
        ::placeholder{
            color:rgb(205, 232, 255)
        }


        .users_id_map{
            position: absolute;
            display: flex;
            margin-top: 40px;
            min-width: 250px;
            margin-left: 50px;
            z-index: 1;
            flex-direction: column;
            overflow-y: scroll;
            max-height: 400px !important;
            width: 60%;
            padding: 2px;
            background-color: $forum-light;
            border-radius: 20px;
            border: 1px solid rgba(167, 214, 255, 0.4);
            .card_{
                display: flex;transition: 0.2s;
                cursor: pointer;
                flex-direction: row;
                height: 43px;
                padding: 8px;
                padding-bottom: 13px;
                border-bottom: 1px solid rgba(169, 215, 255, 0.07);
                img{
                    min-height: 48px;min-width: 48px;
                    border: 2px solid transparent;
                    max-width: 48px;max-height: 48px;
                    border-radius: 50%;object-fit: cover;
                    background-color: $forum-light;
                }
                .card_txts{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 10px;
                }
                h5{
                    font-size: 15px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    color: aliceblue;
                }
                .ps{
                    color: rgba(162, 212, 255, 0.418);
                    font-size: 13px;font-weight: 400;
                }
            }
            .selected{
                h5{
                    color: $form_blue-logo;
                }
                img{
                    border-color: $form_blue-logo;
                }
                .ps{color: aliceblue;}
            }
            .card_:hover{
                transform: translateX(2px);
            }
        }
    }


    .tags_div{
        span, p {
            color: aliceblue;
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        span{
            font-size: 13px;
            transition: 0.2s;
            cursor: pointer;
            border-radius: 6px;
            border: 2px solid rgba(240, 248, 255, 0.414);
            padding: 3px 6px 3px 6px;
            background-color: $form_blue-logo;
            margin-right: 14px;
        }
        span:hover{
            border-color: aliceblue;
        }
        p{
            padding: 4px 10px 4px 10px;
            border-radius: 8px;
            background-color: rgba(174, 217, 255, 0.101);
            border: 1px solid rgba(204, 231, 255, 0.086);
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            overflow-x: hidden;
            max-width: 78%;
        }
        margin-left: 20px;
        margin-top: 0px;
        margin-bottom: 16px;
    }
    .mentions_div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-bottom: 10px;
        .mention_card{
            display: flex;
            flex-direction: row;
            padding: 2px 8px 2px 5px;
            margin-top: 8px;
            height: 35px;
            width: max-content;
            background-color: rgba(174, 217, 255, 0.101);
            border: 1px solid rgba(204, 231, 255, 0.086);
            border-radius: 15px;
            margin-right: 10px;
            img{
                min-width: 30px;min-height: 30px;
                max-width: 30px; max-height: 30px;
                border-radius: 50%;object-fit: cover;
            }
            img,.textsss{
                margin-top: auto;
                margin-bottom: auto;
            }
            .textsss{
                display: flex;
                margin-left: 6px;
                height: max-content;
                justify-content: center;
                flex-direction: column;
                h6{
                    user-select: none;
                    color: aliceblue;
                    font-size: 12px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                .ps_{
                    font-size: 11px;
                    font-weight: 400;
                    color: rgba(177, 219, 255, 0.759);
                }
            }
        }
    }

    h1{
        font-size: 12px;
        max-width: 200px;
        padding: 3px 5px 3px 5px;
        text-align: center;
        border-radius: 7px;
        border: 1px solid  rgb(19, 28, 36);
        background-color: rgb(52, 64, 74);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        color: aliceblue;
        margin-top: 0px;
        margin-left: 20px;
        margin-bottom: 10px;
    }

}



@media screen and  (max-width: 1212px){
    .top_newmsg{
        .inputs{
            width: 80% !important;
        } 
    }
}

@media screen and  (max-width: 1187px){
    .bottom_newmsg{
        .identify{
            width: 50% !important;
            margin-left: 10px !important;
        }
    }
}
@media screen and  (max-width: 1110px){
    
    .photoUpld{
        font-size: 12px !important;
    }
}
@media screen and  (max-width: 1044px){
    .bottom_newmsg{
        .identify{
            width: 45% !important;
            margin-left: 10px !important;
        }
    }
}
@media screen and  (max-width: 650px){
    .top_newmsg{
        .inputs{
            .writeMs, .writeMs::placeholder{
                font-size: 14px !important;
            }
        }

        .photoUpld{
            width: 50px !important;
            font-size: 14px !important;
            span{
                display: none !important;
            }
        }
    }
}
@media screen and  (max-width: 464px){
    .top_newmsg{
        margin-left: 7px !important;
        .inputs{
            width: 92% !important;
            input, ::placeholder{
                font-size: 15px !important;
            }
        }
        p{
            margin-top: 70px !important;
            margin-left: 5px !important;
        }
        img{
            min-width: 60px !important;
            min-height: 60px !important;
            width: 60px !important;
            height: 60px !important;
        }
    }
    .bottom_newmsg{
        margin-top: 6px !important;
        margin-left: 80px !important;
        .trend{
            width: 45% !important;
        }
        
        input{
            font-size: 12px !important;
        }
    }
}

@media screen and  (max-width: 464px){
    .top_newmsg{
        margin-left: 7px !important;
        .inputs{
            width: 92% !important;
            input, ::placeholder{
                font-size: 15px !important;
            }
        }
        p{
            margin-top: 70px !important;
            margin-left: 5px !important;
        }
        img{
            min-width: 60px !important;
            min-height: 60px !important;
            width: 60px !important;
            height: 60px !important;
        }
    }
    .bottom_newmsg{
        margin-top: 6px !important;
        margin-left: 80px !important;
        .trend{
            width: 45% !important;
        }
        p{
            font-size: 18px !important;
        }
        input{
            font-size: 12px !important;
        }
    }
    .tags{
        max-width: 62% !important;
        font-size: 13px !important;
    }
}

@media screen and  (max-width: 400px){

    .bottom_newmsg{
        max-width: 99% !important;
        width: 82% !important;
        margin-left: 78px !important;
        .identify{
            width: 47% !important;
            margin-left: 6px !important;
        }
    }
}
@media screen and  (max-width: 380px){

    .bottom_newmsg{

        .identify{
            width: 42% !important;
        }
    }
}