@import '../settings';

.parr{
    display: block;
    width: 99%;
    .nitroShop{
        height: 85vh;
        border-radius: 30px;
        width: 100%;
        display: flex;
        border: 3px solid rgba(177, 219, 255, 0.441);
        .lds-dual-ring {
            margin-top: 30px !important;
        }
        background: linear-gradient(
            -45deg,
            #8a58ff,
            rgb(207, 87, 255),
            #5b1aff,
            #8a58ff,
            #5b1aff,
            #8a58ff,
          );
        animation: fadeGradient 5s ease infinite;
        background-size: 300% 300%;
        @keyframes fadeGradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        .center_nitro{ 
            width: 95%;
            height: 95%;
            background-color: rgba(170, 210, 255, 0.09);
            border: 1px solid rgba(240, 248, 255, 0.095);
            overflow-y: scroll;
            display: flex;flex-direction: column;
            margin: 0 auto;
            margin-top: auto;margin-bottom: auto;
            border-radius: 20px 30px 20px 20px;
            .center_top{
                margin-bottom: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h1, h2, h3, h4{
                    color: aliceblue;
                    margin-top: 5px;
                    margin-left: 20px;
                    margin-bottom: 0px;
                }
                h1{
                    font-weight: 800;
                    margin-top: 15px;
                    font-size: 45px;
                }
                h2{
                    margin-top: 0px;
                    margin-bottom: 25px;
                    font-size: 24px;
                }
                h3{
                    font-weight: 400;
                    font-size: 13px;
                    max-width: 60%;
                }
                h4{
                    background-color: #1ba0ff;
                    padding: 10px 15px 10px 15px;
                    border-radius: 15px;
                    width: max-content;
                    outline: 3px white;
                    outline-offset: 3px;transition: 0.2s;
                    font-size: 14px;cursor: pointer;
                    text-align: center;
                    width: 62%;
                    margin-left: auto;margin-right: auto;
                    color: rgb(255, 255, 255);
                    border: 2px solid rgba(200, 230, 255, 0.696);
                }
                h4:hover{
                    box-shadow: 0px 4px 0px rgba(240, 248, 255, 0.181);
                    border-radius: 25px;
                }
                .lef, .rig{
                    display: flex;
                    flex-direction: column;
                }
                .lef{
                    width: 55%;
                    justify-content: space-evenly;
                    h4{height: max-content;}
                    .spendFlex{
                        border-radius: 24px;
                        width: 91%;
                        margin: 20px;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: row;
                        height: 70px;
                        border: 2px solid rgba(240, 248, 255, 0.263);
                        background-color: rgba(240, 248, 255, 0.109);
                        box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.068);
                        justify-content: space-around;
                        .spendRow{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            width: 50%;
                            padding-left: 8px;
                            padding-right: 8px;
                            .rowL{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                h6,p, h5{
                                    color: white;
                                    margin-top: 0px;
                                    margin-bottom: 0px;
                                    font-size: 10px;
                                }
                                h5{
                                    font-weight: 400;
                                }
                    
                                p{
                                    font-size: 20px;
                                    font-weight: 700;
                                }
                        
                            }
                            .rowR{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                color: white;
                                .icon{
                                    font-size: 19px;
                                }
                            }
                        }
                        .br{
                            border-right: 1px solid rgba(240, 248, 255, 0.17);
                        }
                    }
                }
                .rig{
                    width: 45%;
                    video{
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-right: 10px;
                        border: 6px solid rgba(172, 216, 255, 0.178);
                        border-radius: 30px;
                    }
                }
            }
            .usrRow{
                margin-left: auto;
                margin-right: auto;
                display: flex;
                width: 700px;
                max-height: 100px;
                overflow-x: scroll;
                min-height: 100px;
                flex-direction: row;
                border: 3px solid rgba(194, 227, 255, 0.127);
                box-shadow: 0px 3px 0px rgba(29, 39, 48, 0.103);
                background-color: rgba(156, 206, 255, 0.088);
                border-radius: 20px;
                margin-bottom: 15px;
                .row{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-left: 18px;
                    width: 33%;
                    padding-right: 18px;
                    .ll, .rr{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    p,h1, h3{
                        margin-top: 0px;
                        color: rgb(247, 251, 255);
                        margin-bottom: 0px;
                    }
                    p{
                        font-weight: 500;
                        font-size: 14px;
                    }
                    h1{
                        font-size: 32px;
                    }
                    .nms{
                        margin-top: 2px;
                        margin-bottom: 2px;
                        font-size: 19px;
                    }
                    h3{
                        font-weight: 300;
                        font-size: 12px;
                    }
    
                    h2{
                        text-align: center;
                        font-size: 30px;                    
                    }
                    .nrg{
                        color: #b6ff7e;
                    }
                }
                .cntr{
                    border-left: 1px solid rgba(214, 236, 255, 0.161);
                    border-right: 1px solid rgba(214, 236, 255, 0.161);
                }
                img{
                    width: 52px;
                    border: 3px solid rgba(179, 220, 255, 0.242);
                    height: 52px;border-radius: 50%;
                    object-fit: cover;
                }
    
            }
            .tryNitro, .card_map{
                display: flex;
                flex-direction: row;
            }
    
            .card_map{
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                .card_parnt{
                    display: flex;
                    flex-direction: column;
                    ._card{
                        border-radius: 20px;
                        margin: 9px;
                        height: max-content;
                        padding-bottom: 30px;
                        width: 255px;
                        background-color: #5228ad;
                        color: rgb(255, 255, 255);
                        position: relative;
                        text-align: center;
                        border: 3px solid #32076e;
                        overflow-y: scroll;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        box-shadow: 0px 4px 0px #32076e;
                        div{
                            height: 120px;
                            display: flex;
                            background-color: #441699;
                            flex-direction: column;
                            justify-content: center;
                            border-bottom: 3px solid #32076e;
                            img, video{
                                margin: 0 auto;
                                max-height: 117px;
                                min-height: 117x;
                                width: max-content;
                                border-radius: 12px;
                                object-fit: cover;
                                background-color: transparent;
                            }
                            video{
                                max-height: 107px;
                                min-height: 107px;
                                border-radius: 18px;
                                border: 2px solid  rgb(49, 0, 128);
                            }
                            .animChan{
                                min-width: 248px;
                                min-height: 112px;
                                border-radius: 16px 16px 2px 2px;
                            }
                            .maxChar{
                                max-height: 90px;
                                border: 3px solid rgba(179, 220, 255, 0.08);
                                min-width: 160px;
                            }
                            .animBanerDiv{
                                display: flex;
                                flex-direction: row;
                                justify-content: start;
                                width: 80%;
                                height: 90px;
                                background-color: rgb(49, 0, 128);
                                padding: 3px;
                                border-radius: 14px;
                                margin-left: auto;margin-right: auto;
                                border-bottom: 0px solid #32076e;
                                position: relative;
                                .banner{
                                    position: absolute;
                                    height: 90px;
                                    width: 97%;
                                }
                                .pfp, p{
                                    margin-top: auto !important;
                                    margin-bottom: auto !important;
                                }
                                .pfp{
                                    z-index: 2;
                                    border-radius: 50%;
                                    object-fit: cover;
                                    border: 2px solid rgb(49, 0, 128);
                                    width: 36px;
                                    height: 36px;
                                }
                                p{
                                    z-index: 2;
                                    width: 140px !important;
                                    height: max-content;
                                    font-size: 14px;
                                    margin-right: 5px;
                                    font-weight: 800;
                                    padding: 2px;
                                    background-color: #44169978;
                                    border-radius: 6px;
                                    span{
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                        h3, h4{
                            margin-top: 8px;
                            font-weight: 700;
                            margin-bottom: 8px;
                            max-width: 89%;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        h3{
                            margin-top: 12px;
                            font-size: 18px;
                        }
                        h4{
                            color: rgb(221, 238, 255);
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                    .price{
                        margin-top: -30px;
                        width: max-content;
                        margin-left: auto;margin-right: auto;
                        z-index: 3;
                        p{
                            background-color: #5b2cc0;
                            box-shadow: 0px 3px 0px #32076e;
                            border: 2px solid #32076e;
                            padding: 7px 26px 7px 26px;
                            color: white;
                            font-weight: 700;
                            transition: 0.2s;
                            border-radius: 12px;
                            margin: 0;
                            cursor: pointer;
                            span{
                                transition: 0.2s;
                                margin-left: 5px;padding: 2px 6px 2px 6px;
                                border-radius: 20px;
                                border: 2px solid rgba(202, 230, 255, 0.469);
                                background-color: $form-blue-logo;
                            }
                        }
                        p:hover{
                            border-radius: 16px;
                            transform: translateY(-1px);
                            box-shadow: 0px 1px 0px #32076e;
                        }
                    }
                }
    
            }
    
            .tryNitro, .tryNitro_channels{
                background-color: #ffffff15;
                border: 1px solid rgba(144, 203, 255, 0.3);
                border-radius: 24px;
                width: 92%;
                margin-left: auto;
                margin-right: auto;
            }
            .tryNitro{
                margin-top: 30px;
                justify-content: space-between;
                border-radius: 24px;
                border: 1px solid rgba(144, 203, 255, 0.3);
                padding: 15px;
                padding-top: 30px;
                padding-bottom: 30px;
                margin-bottom: 30px;
                .try_l, .try_r{
                    display: flex;
                    flex-direction: column;
                    
                }
    
                .try_l{
                    width: 45%;
                    z-index: 2;
                    color: white;
                    h1{
                        font-size: 26px;
                    }
                    h1,h2{
                        margin-top: 0px;
                        margin-bottom: 6px;
                        z-index: -1;
                    }
                    h2{
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 25px;
                    }
                    .inpt{
                        display: none;
                    }
                    button{
                        transition: 0.2s;cursor: pointer;
                        border-radius: 8px;
                        padding: 7px 15px 7px 15px;
                        background-color: rgb(0, 179, 255);
                        border: 2px solid rgba(255, 255, 255, 0.421);
                        color: rgb(255, 255, 255);
                        font-family: $alt-font;
                        font-weight: 500;
                    }
                    button:hover{
                        border-radius: 14px;
                        border-color: aliceblue;
                    }
                    .sve{
                        margin-top: 15px;
                    }
                    .option{
                        border-bottom: 1px solid rgba(240, 248, 255, 0.168);
                        padding-bottom: 15px;
                        p{
                            font-size: 14px;
                            font-weight: 600;
                        }
     
                        h6{
                            font-size: 14px;
                            margin-top: 5px;font-weight: 400;
                            margin-bottom: 5px;
                        }
                        .colors{
                            display: flex;
                            flex-direction: row;
                            
                            .primary, .secondary{
                                width: 80px;
                                cursor: pointer;
                                height: 60px;
                                margin-left: 10px;
                                margin-right: 10px;
                                border-radius: 8px;
                                outline-offset: 4px;
                                outline: 1px solid rgba(240, 248, 255, 0.204);
                                h4{
                                    font-size: 16px;
                                    margin-left: 59px;
                                    margin-top: 3px;
                                }
           
                            }
                 
                            .rcp{
                                position: absolute;
                                margin-left: 0px;
                                margin-top: 0px;
                                z-index: 7;
                                .rcp-fields-element{
                                    display: none;
                                  }
                                  .hex-element{
                                    display: flex;
                                  }
                            }
                            .rcp_rr{
                                margin-left: 20px !important;
                            }
                        }
                    }
                }
    
                .try_r{
                    width: 53%;
                    display: flex;
                    z-index: 1;
                    flex-direction: column;
                    h5{
                        margin-top: 0px;
                        margin-bottom: 8px;
                    }
                    .previewCardParent{
                        border-radius: 14px;
                        padding: 6px;
                        .previewCard{
                            display: flex;
                            width: 100%;
                            padding-bottom: 20px;
                            border-radius: 12px;
                            flex-direction: column;
                            position: relative;
                            background-color: rgba(0, 0, 0, 0.225);
                            .banr{
                                width: 100%;
                                max-height: 140px;
                                border-radius: 12px 12px 0px 0px;
                                object-fit: cover;
                            }
                            .prfl{
                                object-fit: cover;
                                min-width: 78px;min-height: 78px;
                                max-width: 78px;max-height: 78px;
                                border-radius: 50%;
                                margin-top: -40px;
                                margin-left: 16px;
                                background-color: rgba(0, 0, 0, 0.099);
                                border: 3px solid  rgba(0, 0, 0, 0.099);
                            }
                            .icons{
                                position: absolute;
                                margin-top: 150px;
                                margin-right: 6px;
                                border: 2px solid transparent;
                                border-radius: 6px;
                                background-color: rgba(0, 0, 0, 0.5);
                                color: white;
                                width: max-content;
                                padding: 6px;
                                display: flex;
                                right: 0px;
                                padding-right: 10px;
                                font-size: 18px;
                                svg{
                                    margin-left: 5px;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                }
                            }
                            .descCard{
                                width: 85%;
                                border-radius: 10px;
                                background-color: rgba(0, 0, 0, 0.45);
                                margin-left: auto;margin-right: auto;
                                margin-top: 20px;
                                padding: 12px;
                                .descChild{
                                    display: flex;flex-direction: row;
                                    img{
                                        width: 58px;
                                        height: 58px;
                                        border-radius: 10px;
                                        margin-right: 10px;
                                        border: 3px solid rgba(240, 248, 255, 0.145);
                                    }
                                    p{
                                        font-size: 13px;
                                        margin-top: auto;
                                        margin-bottom: auto;
                                        span{
                                            font-weight: 700;
                                        }
                                    }
                                
                                }
                                .bio{
                                    font-size: 14px;
                                }
                                h1{
                                    margin-top: 0px;
                                    margin-bottom: 0px;
                                    padding-bottom: 6px;
                                    border-bottom: 1px solid rgba(255, 255, 255, 0.402);
                                    font-size: 20px;
                                    color: white;
                                    span{
                                        font-size: 15px;
                                        font-weight: 400;
                                    }
                                }
                                h2{
                                    color: rgb(220, 239, 255);
                                    font-size: 12px;
                                }
                                p{
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
    
            .tryNitro_channels{
                padding: 15px;
                margin-bottom: 100px;
                width: 87%;
                .top{
                    h1{
                        font-size: 26px;
                    }
                    h1,h2{
                        margin-top: 0px;
                        margin-bottom: 6px;
                        color: white;
                    }
                    h2{
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 25px;
                    }
                    h3{
                        font-size: 13px;
                        color: white;
                        font-weight: 600;
                    }
                    .newbtnc{
                        transition: 0.2s;
                        border-color: rgba(209, 233, 255, 0.814) !important;
                        cursor: pointer;
                    }
                    .newbtnc:hover{
                        border-color: rgba(224, 240, 255, 0.219) !important;
                        border-radius: 20px !important;
                    }
                    .drop{
                        display: flex;
                        flex-direction: row;justify-content: space-between;
                        background-color: #12212747;
                        border: 2px solid rgba(143, 203, 255, 0.213);
                        border-radius: 12px;
                        padding-left: 10px;color: white;
                        padding-right: 10px;
                        padding-top: 5px;
                        cursor: pointer;
                        transition: 0.2s;
                        padding-bottom: 5px;
                        img{margin-right: 10px;
                            border-radius: 10px;
                            margin-top: auto;margin-bottom: auto;
                            max-width: 38px;min-width: 38px;
                            max-height: 38px;min-height: 38px;
                            object-fit: cover;
                            user-select: none;
                        }
                        h5,p{
                            user-select: none;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                        p{
                            transition: 0.2s;
                            font-size: 17px;
                        }
                        .turned{
                            transform: rotate(90deg);
                        }
                        div{
                            display: flex;flex-direction: row;
                        }
                    }
                    .drop:active{
                        transform: scale(0.98);
                    }
                    .mnu:hover{
                        transform: translateX(-4px);
                        border-color: rgb(200, 229, 255);
                    }
                    .mnu{
                        margin-left: auto;margin-right: auto;
                        width: 95%;
                    }
                }
                .customize{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    .inpt{
                        display: none;
                    }
                    .colors{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin-left: auto;margin-right: auto;
                        .hrw{display: flex;flex-direction: row;width: 50%;}
                        .colorOption{
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            height: max-content;
                            margin-top: auto;
                            margin-bottom: auto;
                            h1{
                                margin-left: auto;
                                text-align: center;
                                margin-right: auto;
                                font-size: 15px;
                                font-weight: 400;
                                margin-bottom: 10px;
                                user-select: none;
                                color: white;
                            }
                            button, .primary, .secondary{
                                width: 80%;
                                outline-offset: 3px;
                                outline: 2px solid rgba(209, 234, 255, 0.275);
                            }
                            button{
                                margin-top: 0px;
                                transition: 0.2s;
                                font-size: 13px;
                                padding: 7px 12px 7px 12px;
                                background-color: $form-blue-logo;
                                border: 2px solid rgba(240, 248, 255, 0.354);
                            }
                            button:hover{
                                border-radius: 18px;
                                border-color: aliceblue;
                            }
                            .primary, .secondary{
                                cursor: pointer;
                                height: 80px;
                                margin-left: auto;
                                margin-right: auto;
                                border-radius: 8px;
                                transition: 0.2s;
                                position: relative;
                                z-index: 1;
                                span{
                                    color: white;
                                    right: 5px;
                                    top: 4px;
                                    position: absolute;
                                }
                                .rcp{
                                    position: absolute;
                                    margin-left: 70px;
                                    z-index: 4;
                                    .rcp-fields-element{
                                        display: none;
                                      }
                                      .hex-element{
                                        display: flex;
                                      }
                                }
                            }
                            .primary:hover, .secondary:hover{
                                border-radius: 10px;
                                outline: 2px solid rgb(255, 255, 255);
                            }

                        }

                    }
                }
                .previewPrnt{
                    border-radius: 28px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: white;
                    .preview{
                        width: 97%;
                        margin: 0 auto;
                        margin-top: 6px;
                        margin-bottom: 6px;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 24px;
                        padding: 6px;
                        background-color: rgba(0, 0, 0, 0.206);
                        .bnr{
                            width: 100%;
                            border-radius: 20px;
                            min-height: 230px;
                            max-height: 230px;
                            object-fit: cover;
                        }
                        .pp{
                            border-radius: 20px;
                            min-width: 75px;max-width: 75px;
                            min-height: 75px;max-height: 75px;
                            object-fit: cover;
                            border: 3px solid rgba(0, 0, 0, 0.171);
                            margin-left: 16px;margin-top: -40px;
                        }
                        h1, h2, h3{
                            color: white;
                            margin-left: 18px;
                        }
                        h1{
                            margin-top: 10px;
                            margin-bottom: 3px;
                            font-size: 25px;
                        }
                        h2{
                            font-size: 15px;
                            font-weight: 300;
                            margin-top: 0px;
                            margin-bottom: 30px;
                        }
                        h3{
                            font-size: 15px;
                            height: max-content;
                            padding: 5px 10px 5px 10px;
                            border-radius: 10px;
                            border: 2px solid rgba(25, 55, 67, 0.094);
                            background-color: rgba(30, 55, 68, 0.118);
                            text-align: center;
                            margin-right: 10px;
                            margin-left: 0px;
                        }
                        .prvRow{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        }
                    }
                }
                button{
                    width: 100%;
                    transition: 0.2s;
                    cursor: pointer;
                    padding: 12px;
                    border-radius: 10px;
                    font-size: 16px;
                    font-weight: 700;
                    color: white;
                    border: 2px solid rgba(221, 239, 255, 0.391);
                    margin-top: 10px;
                    margin-left: auto;margin-right: auto;
                }
                button:hover{
                    border-radius: 18px;
                    border-color: aliceblue;
                }
            }
        }    
    }   
    
}


@media screen and  (max-width: 1040px){
    .parr{
        width: 95% !important;
        margin-left: -5px !important;
    }
}

@media screen and  (max-width: 835px){
    .nitroShop{
        .spendFlex{
            .spendRow{
                h5{font-size: 9px !important;}
                p{font-size: 19px !important;  }
            }
        }

        .usrRow{
            width: 590px !important;
            p{font-size: 13px !important;}
            h1{font-size: 26px !important; }
            .nms{font-size: 17px !important;}
            h3{font-size: 11px !important;}
        }
    }
}

@media screen and  (max-width: 700px){
    .nitroShop{
        .center_nitro{
            .center_top{
                flex-direction: column !important;
                .lef, .rig{
                    width: 100% !important;
                }
                .rig{
                    video{
                        margin-top: 20px !important;
                        margin-left: auto !important;
                        margin-right: auto !important;
                        max-width: 250px !important;
                    }
                }
 
            }
            .usrRow{
                display: flex;
                flex-direction: column !important;
                min-height: none !important;
                overflow-y: scroll !important;
                overflow-x: hidden !important;
                max-height: 300px !important;
                min-height: 300px !important;
                width: 250px !important;
                .row{
                    height: 100px !important;
                    width: 220px !important;
                    padding-left: 10px !important;
                    padding-right: 20px !important;
                }
                .cntr{
                    border-left: 0px !important;
                    border-right: 0px !important;
                    border-top: 1px solid rgba(214, 236, 255, 0.161);
                    border-bottom: 1px solid rgba(214, 236, 255, 0.161);
                }
            }
        }
        .tryNitro{
            flex-direction: column-reverse !important;
            .try_l, .try_r{
                width: 95% !important;
                margin-left: auto !important;
                margin-right: auto !important;
            }
            .try_l{
                margin-top: 20px;
            }
        }
    }
}
@media screen and  (max-width: 640px){
    .parr{
        margin-left: -15px !important;

        .tryNitro_channels{
            .pse{
                font-size: 11px !important;
            }
            .previewPrnt{
                h3{display: none !important;}
            }
        }
        .customize{
            .colors{
                flex-direction: column !important;
                .hrw{
                    width: 90% !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                    margin-bottom: 7px !important;
                }
            }
        }
  
    }
}
@media screen and  (max-width: 500px    ){
    .parr{
        margin-left: -22px !important;
        .center_top{
            text-align: center !important;
            h1, h2, h3{
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }
}