@import '../settings';

.whole_trends_card{
    background-color: $forum-light;
    width: $side_max_width;
    max-width: $side_max_width  !important;
    padding-bottom: 0px;
    border-radius: 25px;
    transition: 0.2s;
    .TrendTopic, .LastTrendTopic{
        margin-left: 17px;
    }
    h1{
        color: aliceblue;
        font-size: 20px;
        font-weight: 800 !important;
        margin-left: 15px;
        margin-top: 20px;
        margin-bottom: 12px;
        font-weight: 400;
    }

    .trendsScroll{
        max-height: 61vh;
        transition: 0.3s;
        overflow-y: scroll;
        border-bottom: 1px solid rgb(44, 52, 60);
    }
    .TrendTopic, .LastTrendTopic{
        width: 90%;
        border-bottom:  2px solid $forum-header-bg;
        padding-bottom: 20px;
        span, p {
            margin-left: 5px;
        }
        h2{
            color: $forum_grey;
            font-size: 14px;
            font-weight: 400;
            margin-top: 20px;
        }
        p, span{
            transition: 0.3s;
            cursor: pointer;
        }
        p{
            color: $forum-font-white;
            margin-bottom: 4px;
            margin-top: 14px;
            font-size: 17px;
        }
        span{
            color: $forum_grey;
            font-size: 15px;
        }
        .dots{
            font-size: 18px;
            color: $forum_grey;
        }
        .trendContent{
            transition: 0.3s;
            display: flex;
            flex-direction: row;
            margin-right: 7px;
            justify-content: space-between;
        }
        .trendContent:hover, .trendSelected{
            color: $form_blue-logo;
            p{
                color: $form_blue-logo;
            }
            span{
                color: $forum-font-white;
            }
        }
    
    }
    .LastTrendTopic{
        border-bottom: 0px solid !important;
 
    }
    h4{
        color: $form_blue-logo;
        font-size: 20px;
        font-weight: 600 !important;
        font-weight: 400;
        margin-top: 25px;
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

.showMedias_{
    .trendsScroll{
        max-height: 43vh !important;
    }
}
.trendSliderPrnt{
    display: flex;
    flex-direction: row;
    .swiper{
        width: 100%;
        max-height: 150px;
        position: relative;
        overflow-y: hidden;
        .swiper-slide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .swiper-pagination-bullets {
            margin-right: -5px !important;
            padding: 2px 3px;
            background-color: rgba(0, 0, 0, 0.075);
            border: 1px solid transparent !important;
            backdrop-filter: blur(14px);
            border-radius: 10px;
        }
    
        .swiper-pagination-bullet{
            width: 11px !important;
            height: 11px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            background-color: rgb(216, 237, 255) !important;
        }
        .swiper-pagination-bullet-active{
            border: 2px solid $form_blue-logo;	
        }
    }
    .trendTopParnt{
        display: flex;
        flex-direction: column;
        h1{
            font-size: 15px;
            font-weight: 700;
            margin-top: 0px;
            color: aliceblue;
            span{
                color: $form_blue-logo;
            }
        }

        .mediasTrend{
            max-width: 315px;
            transition: 0.2s;
            height: max-content;
            border-radius: 18px;
            margin-bottom: 10px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            border: 1px solid rgba(127, 195, 255, 0.172);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        
            img{
                cursor: pointer;
                margin: 0 auto;
                margin-top: auto;
                margin-bottom: auto;
                width: 100%;
                max-height: 95px;
                border-right: 1px solid rgba(127, 195, 255, 0.282);
                border-left: 1px solid rgba(127, 195, 255, 0.282);
        
                min-height: 95px;
                object-fit: cover;
            }
        }
        .mediasTrend:hover{
            border-radius: 14px;
        }
    }
    .userPrntTrend{
        display: flex;
        flex-direction: column;
        max-width: 315px;
        .userTrenddRow{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: auto;
            margin-bottom: auto;
            height: 98px;
            .userTrendd{
                display: flex;cursor: pointer;
                width: 30%;
                transition: 0.2s;
                margin-left: 2px;
                margin-right: 2px;
                border-radius: 10px;
                background-color: $forum-light;
                flex-direction: column;
                justify-content: center;
                height: 102px;
                max-height: 102px;
                overflow-y: scroll;
                border: 2px solid transparent;
                margin-bottom: 4px;

                img{
                    width: 45px;
                    height: 45px;
                    border-radius: 10px;
                    transition: 0.2s;
                    margin-bottom: 5px;
                    object-fit: cover;
                }
                img, h1, h2{
                    margin-left: auto;
                    margin-right: auto;
                }
                h1,h2{
                    color: aliceblue;
                    text-align: center;
                    max-width: 100px;
                    word-wrap: break-word;
                    font-size: 12px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                h2{
                    font-size: 11px;
                    color: rgba(199, 229, 255, 0.652);
                    font-weight: 400;
                }
            }
            .userTrendd:hover{
                border-color: $form_blue-logo;
                border-radius: 18px;
                img{
                    transform: translateY(-2px);
                }
                h2{
                    color: $form_blue-logo;
                }
            }
        }
        .bigRwUsr{
            height: 198px !important;
        }
    }
}
.bigSldr{
    .swiper{
        max-height: 250px !important;
    }
}
@media screen and  (max-height: 920px ){
    
    .trendsScroll{
        max-height: 46vh !important;
    }
}




@media screen and  (max-height: 760px ){
    
    .trendsScroll{
        max-height: 33vh !important;
    }
}

















