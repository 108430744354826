@import '../settings';

.dms_div{
    display: flex;
    color: $forum-font-header;
    flex-direction: column;
    margin-left: -15px;
    margin-right: 10px;
    height: 85vh;
    border-radius: 20px;
    display: flex;
    border: 1px solid rgba(165, 211, 255, 0.13);
    flex-direction: row;
    background-color: $forum-notif_0;
        .eri{
            margin-left: auto;
            margin-right: auto;
        }

        .left_msg_main{
            max-width: 35%;
            width: 35%;
            overflow-y: scroll;
            border-radius: 20px 0px 0px 20px;
            border-right: 2px solid $forum-notif_2;
            background-color: $forum-notif_1;
            .left_top{
                cursor: pointer;
                display: flex;
                height: 85px;
                flex-direction: row;
                border-bottom: 2px solid $forum-notif_2;
                img, div{margin-top: auto;margin-bottom: auto; user-select: none;}
                img{border-radius: 50%; width: 55px;object-fit: cover; height: 55px; margin-right: 10px; margin-left: 18px; border: 2px solid transparent; transition: 0.2s;}
                div{
                    display: flex;
                    flex-direction: column;
                    h1, h2{font-weight: 400;margin-top: 0px; margin-bottom: 2px; transition: 0.2s; user-select: none;}
                    h1{font-size: 18px; color: white;font-weight: 600; margin-top: 8px;}h2{font-size: 14px;margin-bottom: 5px; font-weight: 300; color: rgba(206, 231, 255, 0.641);}
                }
            }
            .left_top:hover{h1{color: $form_blue-logo;} h2{color: white;} img{border-color: $form_blue-logo;}}
            .title{display: flex;flex-direction: row; justify-content: space-between; h1, p {margin-top: auto;margin-bottom: auto;}margin-top: 20px;margin-bottom: 20px;
                h1{user-select: none;;font-weight: 500; color: rgba(255, 255, 255, 0.968);font-size: 21px;
                    span{background-color: $form_blue-logo; border: 2px solid rgba(203, 231, 255, 0.712); padding: 1px 7px 1px 7px;font-size: 16px;font-weight: 600;
                        border-radius: 9px;margin-left: 3px;
                        color: white;
                    }
                }
                margin-left: 20px; margin-right: 20px;
                p{font-size: 22px;color: $form_blue-logo;border-radius: 8px; transition: 0.2s; cursor: pointer; padding: 4px 7px 4px 8px;border: 1px solid transparent;}
                p:hover{color: rgb(0, 208, 255); transform: scale(1.1);}

                .editing{transform: scale(1.1);}
            }
            .srch{
                width: 88%;height: 40px;transition: 0.2s;border: 2px solid rgba(177, 219, 255, 0.17);cursor: pointer;background-color: #172129af;
                margin: 0 auto;  display: flex; border-radius: 17px;margin-top: 15px;margin-bottom: 20px;
                flex-direction: row; justify-content: center;
                p{font-size: 18px;margin-top: auto; margin-bottom: auto;}
                input{
                    padding-left: 9px;user-select: none;font-weight: 300;
                    cursor: pointer;width: 78%;font-size: 13px;
                    background-color: transparent;border: none;
                }
                input,::placeholder, p{color: rgba(197, 228, 255, 0.82);font-family: $alt-font; font-weight: 500;}
                input:focus{ outline: none;}
            }
            .srch:hover{
                border-radius: 13px; border-color: rgba(240, 248, 255, 0.82);;
            }
            .notempty, .notempty:hover{
                border-radius: 9px;
                border: 2px solid $form_blue-logo;
                input,p{color: aliceblue;}
            }
            .msgthread{
                .pindd{border-bottom: 1px solid $forum-notif_2;padding-bottom: 10px;}
                h1{ font-size: 16px;} h2{font-size: 12px !important; font-weight: 400; color: rgba(240, 248, 255, 0.82); margin-left: 14px; margin-top: 30px;}
                .subtit{border: 2px solid rgba(164, 214, 255, 0.08);background-color: $form_blue-logo;font-weight: 600;color: aliceblue; border-radius: 13px;width: max-content; padding: 6px 12px 6px 12px;}
                .def_, .pin_{background-color: #172129af;}
                .onlinerow{
                    display: flex;flex-direction: row;overflow-x: scroll; padding-left: 19px; overflow-y: scroll;border: 1px solid transparent;
                    height: 140px;border-bottom: 1px solid $forum-notif_2;padding-bottom: 10px;
                    img{border-radius: 50%; min-width: 54px;object-fit: cover; min-height: 54px; max-height: 54px; max-width: 54px; margin-right: 10px;}
                    h1{ padding-top: 8px;font-weight: 300;font-size: 14px;color: rgba(204, 231, 255, 0.651);width: 100%; max-width: 100%; white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;}
                    h1, img{margin: 0 auto; text-align: center;}
                    .usr{cursor: pointer;;position: relative;min-width: 65px;padding: 5px 4px 5px 4px;border-radius: 13px;transition: 0.2s;border: 1px solid transparent;
                        width: 65px; max-width: 65px;min-height: 100px; max-height: 120px; margin-right: 10px; display: flex;flex-direction: column; justify-content: center;
                    }
                    .usr:hover{
                        transform: translateY(1px);  
                        background-color: rgba(168, 214, 255, 0.06);
                    }
                    .selectedusr{
                        border: 1px solid rgba(194, 226, 255, 0.275);
                        background-color: rgba(168, 214, 255, 0.112);
                    }
                    .selectedusr:hover{
                        background-color: rgba(168, 214, 255, 0.112);
                        transform: translateY(0px);
                    }
                    .dot{
                        margin-left: 44px;border: 1px solid $forum-notif_1;margin-top: -83px;
                        width: 11px;height: 11px; max-width: 11px !important;border-radius: 50%;position: absolute;background-color: rgb(169, 198, 225);
                    }
                    .connected{background-color: $form_blue-logo; border-color: rgba(171, 216, 255, 0.847);}
                    .connected_pfp{border: 2px solid $form_blue-logo;}
                    .connectedText{color: aliceblue; font-weight: 500;}
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                }
                .connectedUsr, .connectedUsr:hover{
                    transform: translateY(0px) !important;  
                    border: 1px solid  rgba(155, 212, 255, 0.156) !important;
                    background-color: rgba(18, 32, 44, 0.873) !important;
                }
                .onlinerow::-webkit-scrollbar {
                    display: none;
                }
            }
            .msger_card{
                display: flex;flex-direction: row;transition: 0.3s;position: relative;
                margin: 0  auto; padding: 4px 0px 2px 10px; margin-left: 5px; margin-right: 7px;border-radius: 13px;
                margin-top: 7px;border: 2px solid transparent;
                justify-content: space-between;cursor: pointer;
                .le{
                    display: flex;
                    flex-direction: row;
                    min-width: 70%;
                    max-width: 70%;
                    .imglft{
                        position: relative;
                        text-align: center;
                        img{
                            transition: 0.3s;
                            border-radius: 50%;
                            width: 52px; object-fit: cover; height: 52px; border: 2px solid transparent;
                            margin-right: 10px;
                        }
                        .connected_pfp{
                            border-color: $form_blue-logo;
                        }
                        .nsn{
                            border: 2px solid $form_blue-logo;
                        }
                        .dot{
                            margin-left: 40px;border: 1px solid $forum-notif_1;margin-top: -57px;
                            width: 11px;height: 11px; border-radius: 50%;position: absolute;background-color: rgb(195, 218, 239);
                        }
                        .connected{background-color: $form_blue-logo;}
                    }
                    .texts{
                        max-width: 75%; width: 75%;
                        h1, h2{
                            overflow-x: hidden; max-width: 98%;
                            margin-top: 0px; margin-bottom: 0px;
                            white-space: nowrap;text-overflow: ellipsis;
                        } h1{
                            font-size: 15px; transition: 0.3s;margin-bottom: 2px;
                            color: aliceblue;font-weight: 600;
                            span{font-size: 13px; color: $forum-font-lightgray; }
                        }   h2{
                            font-size: 13px !important;font-weight: 400;
                            margin-left: 0px; margin-bottom: 6px;
                            transition: 0.3s; color: rgba(199, 229, 255, 0.499); max-width: 250px;
                            span{user-select: none; font-size: 10px;}
                        }
                        .unsnTxt{color: $form_blue-logo;font-weight: 600;}
                    }
                }
                .texts, .right_infos{display: flex;flex-direction: column; justify-content: center;}

                .right_infos{
                    min-width: 30%; max-width: 30%;
                    overflow-x: hidden;
                    justify-content: space-evenly; position: relative;
                    h1,h2, h3, h4{margin-top: 0px;margin-bottom: 4px;text-align: right; margin: 0px;padding-right: 6px;}
                    h3{color: transparent; user-select: none;}
                    h4{color: $form_blue-logo;}
                    h2{font-size: 10px !important; white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis; color: rgba(201, 230, 255, 0.756);}
                    h1{margin-left: 70%;padding: 2px 5px 2px 5px;border: 1px solid rgba(240, 248, 255, 0.421); background-color: $form_blue-logo; color: white; font-size: 11px; width: max-content;border-radius: 6px;}
                }
                
            }
            .underRow{display: flex;flex-direction: row;width: 80%; margin-left: auto;margin-right: auto;justify-content: center;margin-top: 5px;
                 span{cursor: pointer;;transition: 0.2s;border: 2px solid rgba(240, 248, 255, 0.5);border-radius: 8px;width: 100%;padding-top: 5px;
                    font-size: 14px; font-weight: 500; padding-bottom: 5px;background-color: aliceblue; color: white;text-align: center;}
                 span:hover{border-radius: 12px;}
                 .pn{background-color: $form_blue-logo;}
                 .detc{background-color: rgb(13, 28, 40); border-color: aliceblue;}
            }
            .msger_card:hover{
                transform: translateX(-1px);
                background-color: rgba(170, 217, 255, 0.086);
            }
            .selected_msg, .selected_msg:hover{
                border-radius: 20px;
                border: 2px solid rgba(125, 194, 255, 0.189) !important;
                background-color: rgba(21, 35, 47, 0.873);
                .le,.right_infos{border-bottom: 0px solid rgba(240, 248, 255, 0.745);}
                .right_infos{
                    h2{color: aliceblue;}
                }
            }

            .noresult{
                text-align: center;
                margin-top: 30px;
                h1{
                    text-overflow: ellipsis;
                    font-size: 20px;
                    color: aliceblue;
                    overflow-wrap: break-word;
                }
                h5, p{
                    font-size: 15px;
                }
                h5{
                    transition: 0.3s;
                    width: max-content; margin-left: auto; margin-right: auto;  
                    box-shadow: 0px 3px 0px $forum_font_white; border: 2px solid $forum_font_white;  padding: 10px 20px 10px 20px;
                    color: rgb(255, 255, 255); font-weight: 400; border-radius: 8px;
                    background-color: $form_blue-logo;
                }
                h5:hover{
                    box-shadow: 0px 1px 0px $forum_font_white;
                    transform: translateY(1px);
                }
                .discvr{
                    margin-left: 4px;
                }
            }
            
        }
        .right_msg_main{
            width: 65%;
            max-width: 65%;
            display: flex;
            border-radius: 20px;
            flex-direction: column;
            .top{
                height: 100%;
                width: 100%;
                display: flex;flex-direction: column;  overflow-y: scroll;
                border-radius: 0px 20px 0px 0px;
                overflow: auto;
                .infodiv{
                    display: flex; flex-direction: row; justify-content: space-between; background-color: $forum-notif_1;
                    min-height: 85px;  max-height: 85px; border-bottom: 2px solid $forum-notif_2;
                    img{width: 55px; height: 55px; border-radius: 50%;margin-right: 10px;margin-left: 20px;margin-top: auto;margin-bottom: auto; object-fit: cover;}
                    h2, h3,h5,h4,p{margin-top: 0px;margin-bottom: 3px;cursor: pointer;}
                    h2{color: white; font-size: 18px; font-weight: 600;}h3{color: $form_blue-logo;font-size: 14px; font-weight: 400;}
                    h2, h3{white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden; max-width: 100%;}
                    .info_column{
                        height: 70%;margin-top: auto; margin-bottom: auto;
                        display: flex;flex-direction: column;justify-content: center;
                    }
                    .right_btns{display: flex;justify-content: end;flex-direction: row; padding-right: 20px;
                        p{border: 2px solid rgba(182, 221, 255, 0.121);transition: 0.2s;padding: 11px 15px 11px 15px;border-radius: 10px;background-color: rgba(13, 22, 31, 0.508); color: rgb(200, 229, 255);font-size: 18px; margin-top: auto;margin-bottom: auto; margin-right: 12px;}
                        p:hover{border-radius: 16px;transform: translateY(1px);}
                        .blocked,.rprted{border: 2px solid aliceblue;color: white; background-color: #ff4444;box-shadow: 0px 2px 0px aliceblue;}
                        .rprted{ background-color: rgb(47, 158, 255)}                    
                    }
                    .rww{display: flex;flex-direction: row;div{ display: flex;flex-direction: column;justify-content: center;}}
                    .if{padding: 10px;border-radius: 10px; background-color: rgb(19, 29, 39); height: max-content;}
                }
        
                .msgs{
                    height: 95%;max-height: 95%;
                    padding-top: 20px;padding-bottom: 20px;
                    overflow-y: scroll;
                   .cards_row{display: flex; flex-direction: row; justify-content: space-evenly;
                    h2{font-size: 15px; color: aliceblue; margin-bottom: 8px;margin-left: -3px;}
                    .card{cursor: pointer; div{ width: max-content;padding: 6px 16px 6px 16px; border-radius: 12px;background-color: rgba(188, 224, 255, 0.083);border: 1px solid rgba(177, 219, 255, 0.286); box-shadow: 0px 2px 0px rgba(151, 206, 255, 0.1); }
                        h4,h5{margin-top: 4px;margin-bottom: 4px; font-size: 13px; font-weight: 300; color: rgba(208, 233, 255, 0.651); span{color: aliceblue;font-weight: 600;}}
                    }}
                    h6{margin-left: auto;margin-right: auto;max-width: 55%; position: relative;text-align: center; color: rgba(203, 231, 255, 0.816);font-weight: 300;font-size: 14px;
                        span{color: rgb(255, 255, 255); font-weight: 700;}
                    }
                    h6:before{
                        content: ""; display: block;
                        width: 30%;
                        height: 1px;
                        background: rgba(173, 217, 255, 0.1);position: absolute;
                        left: 105%;
                        top: 32%;
                    } h6:after {
                        content: ""; display: block;
                        width: 30%;
                        height: 1px;
                        background: rgba(173, 217, 255, 0.1);position: absolute;
                        right: 105%;
                        top: 32%;
                    }   
                    .dateInf{max-width: 20%;min-width: 20%; color: rgb(200, 230, 255);}
                    .dateInf:after, .dateInf:before{
                        top: 50%;
                        background: rgba(173, 217, 255, 0.1);
                        width: 180%;
                    }
                    .tday{max-width: 10%; min-width: 10%;}
                    .tday:after, .tday:before{width: 411%;}
                    h1{
                        padding: 8px;width: max-content; font-size: 14px;margin-top: 3px;
                        margin-bottom: 4px; max-width: 300px; color: white;
                        font-weight: 400;  margin-left: 13px; margin-right: 13px;
                        overflow-wrap: break-word;white-space: pre-line;
                    }
                    .relation{
                        margin-top: 30px;
                        h2{ color: rgba(203, 231, 255, 0.816); font-size: 15px;user-select: none; font-weight: 300; span{color: white; font-weight: 700;}}
                        img{width: 40px; margin-left: -15px; height: 40px; object-fit: cover; border-radius: 50%; border: 2px solid $forum-notif_0; background-color: $forum-notif_0;}text-align: center;
                    }


                    .right_msg, .left_msg{
                        img{width: 35px; height: 35px; border-radius: 50%;object-fit: cover;}
                    }
                    .left_msg{
                        display: flex;
                        h1{
                            text-align: left;
                            background-color: $forum-message;
                            border-radius: 10px 10px 10px 0px; 
                        }
                    }
                    .right_msg{
                        h1{
                            text-align: right;
                            background-color: $form_blue-logo;
                            margin-right: 7px;
                            border-radius:  10px  10px 0px 10px;
                        }
                        display: flex;
                        justify-content: end;
                    }
                    .right_msg{img{margin-right: 9px; margin-top: 0px;}}
                    .left_msg{img{margin-left: 10px; margin-top: 0px;} h1{margin-left: 6px;}}
                    .right_date, .left_date{
                        p{
                            margin-top: 0px !important;
                            color: $forum-font-lightgray;
                            font-size: 12px;
                            margin-right: 11px;
                            margin-left: 13px;
                            margin-bottom: 2px !important;
                        }
                    }
                    .left_date{
                        margin-left: 37px;
                    }
                    .right_date{
                        margin-right: 39px;
                        text-align: right;
                    }
                    .blcked{border-radius: 10px;margin: 0 auto!important; background-color: rgba(162, 211, 255, 0.106);
                        font-weight: 600; color: rgb(255, 74, 74); border: 2px solid rgb(255, 85, 85);padding: 12px;}
                }
            }
            .sendMessage{
                width: 100%;
                display: flex;
                height: 90px;
                padding-bottom: 6px;
                background-color: $forum-notif_1;
                justify-content: space-between;
                flex-direction: row;
                border-top: 2px solid $forum-notif_2;
                border-radius: 0px 0px 20px 0px;
                .sendBar{
                    border-radius: 15px;
                    border: 3px solid rgba(19, 28, 36, 0.903);
                    transition: 0.2s;
                    display: flex; flex-direction: row;margin: 0 auto;
                    height: 45px;
                    margin-top: auto;justify-content: space-between;
                    margin-bottom: auto;
                    background-color: rgba(176, 218, 255, 0.131);
                    width: 90%;
                    input{
                        border-radius: 15px;
                        padding-left: 15px;
                        width: 80%;
                        border: none;
                    }
                    input:focus{outline: none;}
                    input{font-family: $alt-font;}
                    input, ::placeholder{
                        font-size: 14px;
                        font-weight: 500 !important;
                        color: rgba(240, 248, 255, 0.82);
                    }
                    input, button{
                        background-color: transparent;
                    }
                    button{
                        margin-right: 10px;
                        font-size: 18px;
                        font-weight: 300;
                        cursor: pointer;
                        color: rgba(207, 233, 255, 0.803);
                        border: none;
                    }
                    .clp{
                        position: relative;
                    }
                    .clp::after{
                        content: ""; display: block;
                        width: 2px;
                        top: 10px;
                        left: 34px;
                        height: 23px;
                        background: rgba(190, 225, 255, 0.16);position: absolute;
                    }
                    .sendMs{
                        color: $form_blue-logo;
                    }
                }
                .sendBar:hover{
                    border-color: rgba(105, 185, 255, 0.824);
                    input{ color: aliceblue; }
                }
                .notEmpty{
                    border-color: $form_blue-logo;
                    input{color: white;}
                }
            }
            .forbdn{
                .sendBar, .sendBar:hover{
                    border-color: rgb(255, 106, 106);
                }
            }
        }
    
        .noBookmarks{
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            h2, h3, img, h4 {
                max-width: 50%;
                text-align: left;
                margin-left: auto;
                margin-right: auto;
            }
            img{
                width: 100%;
                border-radius: 15px;
                border: 4px solid $form_blue-logo;
                box-shadow: 0px 4px 0px #23abff98;
            }
            h2{
                color: aliceblue;
                margin-bottom: 8px;
                font-size: 26px;
            }
            h3{
                color: rgba(213, 236, 255, 0.51);
                font-size: 17px;
                font-weight: 300;
            }
            h4{
                text-align: center;
                max-width: 45%;
                transition: 0.2s;
                border: 2px solid rgba(240, 248, 255, 0.275);
                padding: 10px;
                border-radius: 10px;
                background-color: $form_blue-logo;
            }
            h4:hover{
                box-shadow: 0px 3px 0px $form_blue-logo;
            }
        }
}




@media screen and  (max-width: 1042px){
    .dms_div{
        max-width: 98%;
        margin-left: -27px !important;
    }
}

@media screen and  (max-width: 920px){
    .dms_div{
        .left_msg_main{
            padding-bottom: 15px;
            .msger_card{
                overflow-x: scroll;
                padding-left: 4px !important;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
                .le{
                    min-width: 50%;
                    max-width: 50%;
    
                }
         
                .right_infos{
                    max-width: 50%;
                    min-width: 50%;
                    h2{font-size: 9px !important;}
                }
            }
            .msger_card::-webkit-scrollbar{
                display: none !important;
            }
        }
    }
}



@media screen and  (max-width: 768px){
    .left_msg_main{
        .texts{
            h1{
                span{
                    display: none;
                }
            }
        }
    }
    .right_msg_main{
        .infodiv{
            h2{
                font-size: 15px !important;
            }
            h3{
                font-size: 12px !important;
            }
        }
    }
}


@media screen and  (max-width: 675px){
    .left_msg_main{
        min-width: 80px !important;
        width: 80px !important;
        max-width: 80px !important;
        .msgthread{
            h2{display: none;}
        }
        .title, .onlinerow{display: none !important;}
        .msger_card{
            margin: 0 auto !important;
            margin-top: 7px !important;
            width: max-content !important;
            img{margin-right: 4px !important;}
            padding-bottom: 1px !important;
        }
        .texts, .srch{
            display: none !important;
        }
        .right_infos{
            display: none !important;
        }
        .left_top{
            margin:  0 auto ;
            margin-left: 0px;
            
            margin-right: 0px !important;
            img{margin: 0 auto !important; margin-top: auto !important; margin-bottom: auto !important;}
            div{display: none !important;}
        }
    }
    .right_msg_main{
        width: 90% !important;
        max-width: 90% !important;
        .relation{flex-direction: column !important;}
        .infodiv{
            h4{ display: none; }
            h5{font-size: 14px !important; }
        }   
        .msgs{
            h1{max-width: 160px !important; font-size: 13px !important;}
            h3{
                max-width: 60%;
                font-size: 12px !important;
            }
        }
    }
    .bot{
        input{
            width: 89% !important;
        }
        button{
            width: max-content !important;
            text-align: center;
            padding-right: 7px !important;
            min-width: max-content !important;
            small{
                display: none;
            }
            span{
                margin-left: 0px !important;
            }
        }
    }
}

@media screen and  (max-width: 550px){
    .dms_div{
    .right_msg_main{
        .infodiv{

            img{ margin-right: 6px !important;min-width: 45px !important; min-height: 45px !important; max-width: 45px !important; max-height: 45px !important;}
        p{ font-size: 15px !important; padding: 6px 10px 6px 10px !important;}
        }
        .msgs{
            .cards_row{
                flex-direction: column !important;
                .card{
                    margin: 0 auto !important;
                    h2{
                        text-align: center !important;
                    }
                }
            }
            .left_msg{
                img{
                    margin-left: 4px !important;
                }
            }
            .right_msg{
                img{
                    margin-right: 4px !important;  
                }
            }
        }
        .right_btns{margin-right: -20px !important;}  
    }
    }
}

@media screen and  (max-width: 460px){
    .dms_div{
    .left_msg_main{
        min-width: 72px !important;
        width: 72px !important;
        max-width: 72px !important;
        img{
            width: 50px !important;
            height: 50px !important;
        }
        p{
            font-size: 10px !important;
        }
    }
    .right_msg_main{
        width: 86% !important;
        max-width: 86% !important;
        .infodiv{
            p{
                display: none;
            }
        }
    }
}

}

@media screen and  (max-width: 420px){
    .left_msg_main{
        p{
            font-size: 9px !important;
        }
    }
    .right_msg_main{
        width: 80% !important;
        max-width: 80% !important;
    }
}



@media screen and  (max-height: 826px){
    .bottt{
        height: 72vh !important;
    }
}

