@import '../settings';

.notifications_map{
    min-height: 92vh;
    height: max-content;
    background-color: #15212a;
    border-radius: 12px;
    min-width: 100%;
    width: 100%;
    overflow-x: hidden;
    border: 1px solid rgba(240, 248, 255, 0.065);
    .titleDiv{
        width: 100%;
        max-width: 100%;
        display: flex;flex-direction: row;
        height: 70px;
        justify-content: space-between;
        border-bottom: 1px solid rgba(153, 208, 255, 0.081);
        .titlee{
            margin-bottom: 0px;
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 22px;
            font-weight: 500;
            font-size: 19px;
            color: aliceblue;
            span{
                background-color: $form_blue-logo;
                padding: 10px;
                font-size: 20px;
                border-radius: 20px;
                border: 2px solid $forum-font-header;
                margin-left: 8px;
            }
        }
        .searchBar_{
            margin-right: 10px;display: flex;flex-direction: row;
            justify-content: space-between;height: 32px;
            margin-top: auto;
            margin-bottom: auto;
            padding: 1px 13px 1px 10px; border-radius: 10px;
            background-color: #101a21;
            border: 2px solid rgba(167, 214, 255, 0.103);
            input, p{
                color: aliceblue;
                margin-top: auto;
                margin-bottom: auto;
            }
            input{background-color: transparent;font-family: $alt-font;
                border: 0px solid transparent;

            }
            ::placeholder, p{
                color: rgba(214, 236, 255, 0.854);
            }
            input:focus{
                outline: none;
            }
        }
    }
 
    .options{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(153, 208, 255, 0.081);
        .opt_row{
            display: flex;
            padding-bottom: 0px;
            overflow-x: scroll;
            max-width: 90%;
            flex-direction: row;
            h2{
                cursor: pointer;
                padding: 13px 19px 13px 19px;
                border-bottom: 2px solid transparent;
                color: $forum-grey;
                font-size: 15px;
                user-select: none;
                transition: 0.2s;
                white-space: nowrap;
                margin-top: 0px;
                margin-bottom: 0px;
                font-weight: 400;
                span{
                    margin-left: 6px;
                    color: white;
                    padding: 2px 7px 2px 7px;
                    border: 1px solid rgba(197, 228, 255, 0.342);
                    background-color: rgb(42, 57, 70);
                    border-radius: 5px;
                }
            }
            h2:hover{
                transform: translateY(1px);
                border-color:rgba(153, 207, 255, 0.219);
            }
            .activated{
                color: aliceblue;
                border-color: aliceblue;
                font-weight: 500;

                span{
                    background-color: $form_blue-logo;
                }
            }
            .activated:hover{
                border-color: aliceblue;
            }
        }
        .sortbtn{
            transition: 0.3s;
            margin-right: 20px;
            font-size: 15px;
            cursor: pointer;
            font-weight: 500;
            border-radius: 10px;
            color: $forum_font_white;
        }
        .sortbtn:hover{
            transform: translateY(2px);
        }
    }

    .notification_card{
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        transition: 0.2s;
        border-left: 2px solid transparent;
        border-bottom: 1px solid rgba(153, 208, 255, 0.081);
        .top_notif{
            display: flex;
            border: 2px solid transparent;
            overflow-x: hidden;
            padding: 12px;
            transition: 0.3s;
            flex-direction: row;
            justify-content: space-between;
            .parent_notif{
                width:72%;
                display: flex;
                flex-direction: row;
                transition: 0.2s;
            }
            .parent_notif:hover{
                img{
                    border-color: rgb(0, 166, 255);
                }       
            }
            h2,h3,h4, img, p, .mid_notif{
                user-select: none;
                margin-top: auto;
                margin-bottom: auto;
            }
            .mid_notif{
                width: 65%;
                min-width: 65%;
                position: relative;
                transition: 0.2s;
                display: flex;
                flex-direction: column;
                h2, h3, h4{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
      
            img{
                transition: 0.2s;
                margin-right: 15px;
                border-radius: 50%;
                border: 2px solid transparent;
                background-color: transparent;
                min-width: 58px;max-height: 58px;max-width: 58px;min-height: 58px;
                object-fit: cover;
            }
   
            h2,h3{
                width: 95%;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
            h2, h4, h3, h5{
                font-weight: 400;
                color: rgba(247, 251, 252, 0.891);
            }
            h2{
                margin-top: 10px !important;
                font-weight: 500;
                font-size: 18px;
            }
            h3{
                color: $form_blue-logo;
                font-size: 13px;
                font-weight: 500;
            }
            h5{
                margin-top: auto;
                margin-bottom: auto;
                margin-top: 12px;
                font-size: 13px;
                font-weight: 300;
                color: rgba(184, 222, 255, 0.409);
            }

            .notif_icon{
                width: 38px;
                text-align: center;
                height: 38px;
                border: 2px solid rgba(200, 229, 255, 0.333);
                display: flex;
                margin-right: 12px;
                margin-top: 10px;
                flex-direction: column;
                justify-content: center;
                border-radius: 10px;
                p{
                    margin: 0 auto;
                    color: white;
                    font-size: 16px;
                }
            }
        }
        .bottom_notif{
            width: 100%;
            display: flex;
            flex-direction: column;
            transition: 0.3s;
            padding-bottom: 30px;
            justify-content: center;
            h4, .messageComponent{
                transition: 0.3s;
            }
            h4 ,.h7{
                margin-left: 55px !important;
            }
            h4{
                max-width: 370px;
                margin-top: 15px;
                white-space: nowrap;
                overflow-x: hidden;
                color: aliceblue;
                text-overflow: ellipsis;
                font-weight: 400;
                margin-bottom: 0px;
                small{

                }
            }
            .h7{
                quotes: none;
                color: white;
                margin: 0px;
                font-size: 13px;
                padding: 3px 9px 3px 9px;
                margin-top: 5px;
                border-radius: 10px;
                width: max-content;
                border: 1px solid rgba(181, 220, 255, 0.14);
                background-color: rgba(188, 224, 255, 0.14);
            }
            .messageComponent{
                margin-left: auto;
                margin-right: auto;
                max-width: 540px;
                width: 87%;
                .msgRelation{
                    display: none !important;
                }
                .msg_interact_btns{
                    .interactBtn{
                        q{
                            display: none;
                        }
                    }
                }
                .message_card_container{
                    border: 2px solid rgb(13, 23, 28);
                    box-shadow: 0px 4px 0px  rgb(13, 23, 28);
                    .msg_right_cntnr{
                        max-width: 80%;
                    }
                }
            }

            .channelComponent{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                width: 87%;
                transition: 0.2s;
                margin-left: auto;margin-right: auto;
                border-radius: 20px;
                background-color: rgba(187, 223, 255, 0.071);
                border: 1px solid rgba(195, 227, 255, 0.127);
                .bnrChnl{
                    border-radius: 18px 18px 0px 0px;
                    width: 100%;object-fit: cover;
                    min-height: 180px;
                    max-height: 180px;
                }
                .pfpChnl{
                    width: 72px;
                    height: 70px;
                    object-fit: cover;
                    border-radius: 15px;
                    margin-top: -40px;
                    border: 3px solid  rgb(34, 45, 54);
                }
                h1,h2, .pfpChnl{
                    margin-left: 16px;
                }
           
                h1, h2{
                    color: aliceblue;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                h1{
                    font-size: 20px;
                    font-weight: 700;
                    margin-top: 6px;
                }
                h2{
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 20px;
                }
            }
            .channelComponent:hover{
                transform: translateX(3px);
                border-color: $form_blue-logo;
            }
        }
    }
    .notification_card:hover{
        cursor: pointer;
        border-left: 3px solid rgba(153, 208, 255, 0.081);
        .bottom_notif, .top_notif{
            transform: translateX(2px);
        }
    }
    .unseen{
        background-color: rgba(160, 211, 255, 0.035);
        border-left-color: $form_blue-logo !important;
    }
    .unseen:hover{
        background-color: rgba(160, 211, 255, 0);
    }



    .noBookmarks{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        h2, h3, img{
            max-width: 60%;
            margin-left: auto;
            margin-right: auto;
        }
        img{
            width: 100%;
            border-radius: 15px;
            border: 6px solid rgba(205, 205, 205, 0.227);
        }
        h2{
            color: aliceblue;
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 20px;
        }
        h3{
            color: rgba(202, 230, 255, 0.407);
            font-size: 17px;
            font-weight: 300;
        }
    }
}

@media screen and  (max-width: 1168px){
   .messageComponent{
        .message_card_container{
            .msg_left_cntnr{
                margin-left: 5px !important;
            }
        }
   } 
}


@media screen and  (max-width: 1168px){
    .messageComponent{
        max-width: 450px !important;
    } 
 }
 

@media screen and  (max-width: 1090px   ){
    .messageComponent{
        max-width: 420px !important;
    } 
 }

 @media screen and  (max-width: 1029px){
    .messageComponent{
        .message_card_container{
            .msg_right_cntnr{
                max-width: 87% !important;
            }
        }
    } 
 }


@media screen and  (max-width: 550px){
    .messageComponent{
        width: 96% !important;
        .message_card_container{
            margin-left: 0px !important;
        }
    } 
    .bottom_notif{
        h4{
            max-width: 300px !important;
            font-size: 14px;
        }
    }
 }
 
 