@import '../settings';

.body_alt{
    font-family: $alt-font;
    @include body_alt_class(rgb(8, 8, 73));
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    background: linear-gradient(
        to right,
        #e3dfec 0%,
        #e3dfec 57%,
        #f4f5f7 57%,
        #f4f5f7 100%
      );    
    h1{
        color: $font-black;
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 10px;
    }
    h2, h3{
        font-weight: 300;
    }  
    h2{
        margin-top: 0px;
        font-size: 16px;
        color: $font-lightgray;
    }
    h3{
        color: $font-grey;

        font-weight: 300;
    }
    h4{
        font-weight: 300;
        text-align: center !important;
        margin-top: 12px;margin-bottom: 12px;
        span{
            color: $prpl-color;
            font-weight: 500;
        }
    }
    h5{
        font-weight: 300;
        color: $font-black;
    }
    h6{
        font-family: $nitro-font;
        font-size: 20px;
        font-weight: 700;
        margin-top: 24px;
        color: rgb(95, 27, 158);
        margin-bottom: 5px !important;
        span{
            font-size: 21px;
            color: $prpl-color;
        }
    }
    ::placeholder{
        color: $font-lightgray !important;
    }
    input{
        width: 90%;
        border: 1px solid $font-lightgray;
        box-shadow: 0px 2px #e8e2f5;
        border-radius: 8px;
        padding: 10px;
        padding-left: 20px;
       
    }
    button{
        background-color: $prpl-color;
        width: 97%;
        margin-top: 7px;
        font-weight: 400;
        font-size: 17px;
        box-shadow: 0px 0px $light-prpl;
        padding: 15px;
        color: white;
        border: 3px solid $light-prpl;
        border-radius: 10px;
        transition: 0.3s;
    }
    button:hover{
        border-radius: 20px;
        background-color:  rgb(121, 57, 231);;
    }

}

.parent{

    width: 92%;
    max-width: 1600px;
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    margin-left: auto;
    margin-right: auto;
    border-radius: 23px;
    background-color:  rgba(79, 14, 191, 0.252);
    padding: 20px;
    box-shadow: 0px 30px 100px #b09ac9;
    .left{
        border-radius: 20px 0px 0px 20px;
        background-color: rgb(255, 255, 255);
        text-align: left;
        padding-bottom: 40px;
        .form, .titles{
            margin-left: 13%;
            margin-right: 13%;
        }
        .form{
            margin-top: 0px;   
            h3{
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 8px;
                margin-top: 8px;
            }
            h2{
                color: #8e1fd9;
                margin-top: 7px;
                font-size: 15px;
                font-weight: 500;
                margin-left: 5px;
            }
            p{
                margin-top: 0px;
                margin-bottom: 0px;
                color: $prpl-color !important;
                padding: 10px;
                font-weight: 300;
                text-align: center;
            }
            .google_btn{
         
                h1:before {
                    content: "";
                    display: block;
                    width: 40%;
                    height: 2px;
                    background: rgb(66, 66, 66);
                    left: 0;
                    top: 13%;
                    position: absolute;
                  }
                  
                  h1:after {
                    content: "";
                    display: block;
                    width: 40%;
                    height: 2px;
                    background: rgb(66, 66, 66);
                    right: 0;
                    top: 13%;
                    position: absolute;
                  }
                h1{
                    font-size: 22px;
                    text-align: center;
                }

                button{
                    margin-top: 0px;
                    background-color: rgb(251, 87, 227);
                    border-color:rgb(255, 185, 244);
                }
                button:hover{
                    background-color:  rgb(214, 69, 192);
                }
            }
    
        }
    }
    .right{
        height: 100%;
        video{
            width: 100%;
            max-height: 100% !important;
            min-height: 100% !important;
            object-fit: cover !important;
            background-color: rgb(50, 9, 122);
            border-radius: 0px 20px 20px 0px;
        }
        p{
            display: none;
            font-size: 11px;
            right: 90px;
            color: white;
            position: absolute;
            padding: 4px 12px;
            border-radius: 10px;
            border: 0px solid transparent;
            background-color: rgba(161, 211, 255, 0.135);
            span{
                font-weight: 600;
            }
            i{
                font-style: normal;
            }
        }
    }
}


@media screen and  (max-height: 860px ){
        .parent{
            .left{
                padding-bottom: 10px !important;
            }
        }
  }
  
  
@media screen and  (max-width: 1144px ){
    .parent{
        grid-template-columns: 1fr !important;
        padding: 10px !important;
        h6, h5{
            margin-top: 12px !important;
        }
        h5{

        }
        .insc_title{
            margin-bottom: 0px !important;
        }
        button{
            padding: 10px !important;
        }
        .left{
            border-radius: 20px !important;
            .form, .titles{
                margin-left: 7%;
                margin-right: 7%;
            }
        }
        .right{
            display: none !important;
        }
    }
}


