@import '../settings';
.SpecificPage{
    border: 2px solid $forum-light;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    height: 95vh;
    min-height: 95vh;
    margin-bottom: 30px;
    height: max-content;

    .noD{
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        margin-top: 30px;
        span{
            background: -webkit-linear-gradient( #1271e7,  #47aded);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        color: $forum-font-header;
    }
}
.goBack{
    color: $forum-font-header;
    h1{
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 25px;
        span{
            transition: 0.4s;
        }
    }
    h1:hover{color: $form_blue-logo;}
    h2{
        margin-top: 5px;
        font-weight: 600;
        font-size: 16px;
        span{
            color: $form_blue-logo;
        }
    }
}

.answrRow{
    display: flex;
    flex-direction: row;
    .answrCard{
        margin-top: 20px;
        border: 1px solid rgba(240, 248, 255, 0.104);
        width: 100%;
    }
    h4{
        font-size: 30px;
        transform: rotate(180deg);
        margin-right: 10px;
        color: $forum-font-header;
        transition: 0.3s;
    }    
    h4:hover{
        color: $form_blue-logo;
    }
    .rtrn{
        margin-top: auto;
        margin-bottom: auto;
        height: max-content;
    }
}

.specificMessage{
    border-radius: 15px;
    color: $forum_font_white;
    background-color: $forum-bg-alt;
    padding: 14px 20px 14px 20px;

    .topContent{
        img{
            border-radius: 50%;
            object-fit: cover;
            width: 58px !important;
            height: 58px !important;
        }
        display: flex;
        flex-direction: row;
        .topColumn{
            margin-left: 8px;
            display: flex;
            flex-direction: column;
        }
        h3{
            font-size: 18px;
            margin-bottom: 0px;
            margin-top: 5px;
        }

        .topPseudo{
            margin-top: 1px;
            font-weight: 400;
            font-size: 16px;
            color: $form_blue-logo;
        }
    }

    .specificContent{
        max-width: 500px;
        overflow-wrap: break-word;
        img{
            width: max-content;
            max-height: 650px;
            border-radius: 20px;

            border: 1px solid rgba(215, 236, 255, 0.198);
            max-width: 95%;
        }
        display: flex;
        flex-direction: column;
        p{
            font-weight: 400;
            font-size: 20px;
            span{
                font-weight: 600;
                color: $form_blue-logo;
            }
        }
        h5{
            margin-bottom: 5px;
            font-size: 14px;
            margin-top: 16px;
            font-weight: 500;
            word-spacing: 0.5px;
        }
    }
}

.specificStats{
    margin-top: 20px;
    display: flex;
    height: max-content;
    flex-direction: row;
    border-bottom: 1px solid $forum-light;
    border-top: 1px solid $forum-light;
    h4{
        font-weight: 300;
        margin-right: 20px;
        margin-top: 14px;
        cursor: pointer;
        font-size: 15px;
        margin-bottom: 14px;
        color: $forum-font-lightgray;
        span{
            transition: 0.3s;
            padding: 6px;
            margin-right: 2px;
            border-radius: 10px;
        }
        span:hover{
            background-color: $forum-bg-alt;
     
        }
        .l:hover, .l_active{
            background-color: $forum-light;
            color: rgb(255, 96, 96);
        }
        .rt:hover, .rt_active{
            background-color: $forum-light;
            color: $form_blue-logo;
        }
    }
    i{
        transition: 0.3s;
        font-style: normal;
    }
    i:hover{
        color: $form_blue-logo !important;
    }
    strong{
        margin-right: 1px;
        color: $forum_font_white;
    }
}

.specificResponse{
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 8px;
    p{
        margin-left: 7px;
        font-size: 15px;
        color: $forum-font-lightgray;
        margin-bottom: 12px;
        strong, small{
            font-size: 15px;
            color: $forum-font-white;
        }
        span{
            color: $form_blue-logo;
        }
    }
    small{
        text-align: right;
        margin-right: 0px;
        color: $forum_font_white;
    }
    img{
        width: 62px !important;
        height: 62px !important;
        border-radius: 50%;
        object-fit: cover;
    }
    input{
        height: 40px;
        margin-top: auto;
        margin-bottom: auto;
        background-color: $forum-light;
        width: 65%;
        margin-left: 0px;
        border-radius: 10px;
        margin-right: 0px;
        padding-left: 5px;
        max-width: 65%;
        color: $forum_font_white;
        font-size: 18px;
        transition: 0.3s;
        overflow-wrap: break-word;
        border: 2px solid transparent;
    }
    input:hover, input:focus{
        border-color: $form_blue-logo;
    }
    ::placeholder{
        font-size: 16px;
        color: $forum-font-lightgray;
    }
    textarea:focus, input:focus{
        outline: none;
    }
    
    button{
        font-size: 17px;
        color: $forum_font_white;
        background-color: $form_blue-logo;
        border-radius: 15px;
        border: 0px;
        height: max-content;
        margin-right: 0px;
        transition: 0.3s;
        border: 2px solid transparent;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 600;
        padding: 10px 15px 10px 15px
    }
    button:hover{
        border-color: $forum_font_white;
    }
    .respInputs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .respLen{
        color: $forum-font-header;
        margin-top: -6px;
        margin-bottom: 0px;
        text-align: right;
    }
}

.commentsMap{
    margin-left: -20px;
    margin-right: -20px;
    .line{
        width: 2px;
        margin-left: 28.5px;
        height: 76px;
        position: absolute;
        margin-top: 65px;
        background-color: $forum-font-lightgray !important;
    }
    .long{
        height: 97px;
    }
    .commentCard{
        padding: 10px;
        display: flex;
        flex-direction: row;
        border-top: 2px solid $forum-light;
        img{
            width: 59px;
            height: 59px;
            object-fit: cover;
            border-radius: 50%;
        }
        .commentTop{
            width: 90%;
            max-width: 90%;
            margin-left: 10px;            
            h1{
                font-size: 17px;
                color: $forum_font_white;
                margin-bottom: 0px;
                span{
                    font-weight: 300;
                    color: $forum-font-lightgray;
                }
            }
            h2, h4{
                margin-top: 5px;
                font-size: 16px;
                font-weight: 400;
                color: $forum-font-lightgray;
                span{
                    color: $form_blue-logo;
                }
            }
            h2{
                font-size: 14px;
            }
            h3{
                color: $forum_font_white;
                font-weight: 400;
                max-width: 350px;
                overflow-wrap: break-word;
                font-size: 15px;
            }
            h4{
                font-size: 13px !important;
                margin-bottom: 3px;
            }
            .answr{
                width: 110%;
                display: flex;
                flex-direction: row;
                h4{
                    font-size: 14px;
                    margin-right: 20px;
                }
                .answr_btn{
                    transition: 0.3s;
                    font-weight: 500;
                    color: $forum_font_white;
                }
                .answr_btn:hover{
                    color: $form_blue-logo;
                }
                .dlt{
                    color: $font-verylight;
                }
            }
            .profileClick{
                cursor: pointer;
                transition: 0.3s;
                width: max-content;
            }
            .profileClick:hover{
                span{
                    color: $form_blue-logo;
                }
            }
        }

        .commentRight{
            margin-right: 0px;
            width: 20%;
            color: $forum-font-header;
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: max-content;
            h4{
                background-color: $forum-light;
                padding: 10px;
                width: max-content;
                cursor: pointer;
                border-radius: 10px;
                font-weight: 400;
                transition: 0.3s;
            }
            h4:hover, .liked{
                color: rgb(255, 94, 94);
            }
        }

    }
    .subChild{
        padding: 15px;
        border-top: 0px solid transparent;
        img{
            width: 50px;
            height: 50px;     
        }
        h1{
            small{
                font-weight: 200;
                margin-left: 5px;
                color: $forum-font-lightgray;
                font-size: 15px !important;
            }
        }
        .line{
            height: 60px;
            margin-top: 55px;
            margin-left: 23.5px;
        }
        .commentTop{
            h3{
                margin-bottom: 7px;
            }
        }
    }
}

.profileThreadCard{
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    background-color: $forum-light;
    justify-content: space-between;
    border-radius: 18px;
    margin-top: 15px;
    padding: 7px 10px 7px 10px;
    border: 1px solid $forum-clear;
    img{
        border-radius: 50%;
        width: 45px !important;
        height: 45px !important;
        min-width: 45px;
        min-height: 45px;
        object-fit: cover;
    }

    h1, h2{
        margin-top: 2px;
        margin-bottom: 0px;
        font-size: 18px;
    }
    .rw{
        display: flex;
        flex-direction: row;
    }
    .ps{
        margin-top: 0px;
        font-size: 14px;
        font-weight: 300;
        color: $forum-font-lightgray;
    }
    h1{
        margin-left: 5px;
        color: $forum-font-white;
    }
    h2{
        margin-top: 0px;
        font-size: 13px;
        font-weight: 600;
        color: $form_blue-logo;
    }
    .rght{
        text-align: center;
        margin-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
        height: max-content;
    }
}

.profileThreadCard:hover{
    border-color: rgba(197, 228, 255, 0.194);
    border-radius: 13px;
}

@media screen and  (max-width: 1220px   ){
    .SpecificPage{
        .commentTop{
            h3{
                max-width: 230px !important;
            }
        }
        .respInputs{
            button{
                span{
                    display: none !important;
                }
            }
        }
    }

}

@media screen and  (max-width: 1024px){
    .specificResponse{
        input{
            margin-left: 0px;
        }
    }
}

