@import '../settings';

.wholeFollows{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .follow_header{
        display: flex;
        flex-direction: column;
        background-color: $forum-bg-alt;
        border-radius: 20px;
        border: 1px solid $forum-header-bg;
        .header_top{
            display: flex;
            flex-direction: row;
            .returnBtn{
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 10px;
                margin-left: 10px;
                button{
                    transition: 0.3s;
                    margin-top: auto;
                    margin-bottom: auto;
                    cursor: pointer;
                    font-size: 20px;
                    color: $forum-font-white;
                    padding: 10px;
                    background-color: transparent;
                    border: 0px solid;
                }
                button:hover{
                    transform: translateX(-2px);
                    color: $form_blue-logo;
                }
            }
            h1{
                color: $forum-font-header;
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 0px;
            }
            h2{
                font-size: 16px;
                color: $forum-grey;
                font-weight: 300;
                margin-bottom: 0px;
                margin-top: 1px;
            }
            h3{
                margin-top: 7px;
                font-weight: 500;
                font-size: 15px;
                color: $form_blue-logo;
            }
        }
        .header_bottom{
            display: flex;
            justify-content: space-evenly;
            height: max-content;
            overflow-x: scroll;
            border-radius: 25px;
            p{
                white-space: nowrap;
                cursor: pointer;
                margin-bottom: 0px;
                text-align: center;
                transition: 0.3s;
                color:$forum-grey;
                font-size: 14px;
                border-bottom: 3px solid transparent;
                padding-top: 5px;
                font-weight: 500;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 13px;
            }
            p:hover{
                color: white;
                border-bottom: 3px solid $form_blue-logo;
            }
            .active{
                border-bottom: 3px solid $form_blue-logo;
                font-weight: 500;
                color:white;
            }
        }
        .header_bottom::-webkit-scrollbar{
            display: none;
        }
    }
    .follows_map{
        border-left: 1px solid  rgba(202, 230, 255, 0.081);
        border-right: 1px solid  rgba(202, 230, 255, 0.081);
        height: 86vh;
        background-color: $forum-bg-alt;
        padding-top: 20px;
        width: 94%;
        margin-left: auto;
        margin-right: auto;
        overflow-y: scroll;
        .lds-dual-ring{
            margin-top: 0px;
            margin-left: 43%;
        }
        img{
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 10px;
        }
        h1, h2, p{
            font-size: 17px;
        }
        p{
            padding-top: 11px;
            font-weight: 400;
            max-width: 70%;
            color: $forum-font-white;
        }
        h1{
            font-weight: 600;
            font-size: 18px;
            margin: 0px;
            transition: 0.3s;
            color: rgb(255, 255, 255);
        }
        h2{
            color: #bee1ff;
            font-weight: 400;
            transition: 0.3s;
            margin-top: 2px;
            margin-bottom: 0px;
            font-size: 15px;
        }
        p{
            font-size: 14px;font-weight: 300;
            color: #b2dcffb0;
        }
        button{
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 5px;
            cursor: pointer;
            border-radius: 22px;
            padding: 8px 18px 8px 18px;
            transition: 0.3s;
            font-weight: 600;
            font-size: 15px;
            border: 2px solid transparent;
            background-color: $forum-font-white;
            span{
                margin-left: 3px;
            }
        }
        button:hover{
            border-radius: 14px;
        }
        .flwed{
            color: $forum-font-white;
            background-color: $form_blue-logo;
        }
        .flwed:hover{
            color: $home-grey;
            border: 2px solid $forum-font-white;
            box-shadow: 0px 3px 0px $home-grey;
        }
        .flw:hover{
            color: $form_blue-logo;
            border: 1px solid $form_blue-logo;
            box-shadow: 0px 3px 0px $form_blue-logo;
        }
        .noFollows{
            h1{
                font-size: 24px;
                margin-top: 25px;
                font-weight: 600;
                span{
                    color: $form_blue-logo;
                    font-weight: 700;
                }
            }
            h2{
                margin-top: 7px;
                font-size: 20px;
            }
            button{
                background-color: $form_blue-logo;
                margin-top: 15px;
                border-radius: 7px;
                font-size: 15px;
                transition: 0.3s;
                cursor: pointer;
                font-weight: 600;
                border: 2px solid $forum-font-white;
                box-shadow: 0px 2px 0px $forum-font-header;
                color: $forum-font-white;
            }
            button:hover{
                box-shadow: 0px 0px 0px;
            }
            text-align: center;
        }
    }

    .follow_card{
        display: flex;
        transition: 0.3s;
        flex-direction: row;
        padding: 10px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        padding-top: 14px;
        .rgt, .rgt_bot, .rgt_top{
            display: flex;
            flex-direction: row;
        }
        .lft, .rgt{
            transition: 0.3s;
        }
        .rgt{
            flex-direction: column;
            width: 100%;
        }
        .e{
            height: max-content;
            margin-top: auto;
            margin-bottom: auto; 
        }
        .rgt_top{
            width: 100%;
            transition: 0.3s;
            justify-content: space-between;
        }
        .rgt:hover{
            .names{
                h1{
                    color: $form_blue-logo;
                }h2{
                    color: $forum-font-header;
                }
            }
            .rgt_bot{
                p{
                    color: $font-verylight;
                }
            }
        }
        .rgt_bot{
            p{
                margin-bottom: 9px;
                margin-top: 0px;
            }
        }
  
    }
    .follow_card:hover{
        background-color: $forum-light;
        border-top: 1px solid $forum-header-bg;
        border-bottom: 1px solid$forum-header-bg;
        .rgt, .lft{
            transform: translateX(2px);
        }
    }
}