@import '../settings';

.msgRelation{
    color: $forum-font-header;
    font-weight: 500;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -13px;
    .relCard{
        display: flex;
        flex-direction: row;
        margin-left: 6px;
        margin-right: 8px;
        .rt, .lk{
            color:  #6bc6ff
        }

        p {
            font-size: 12px;
            margin-top: auto;
            font-weight: 400;
            color: rgba(194, 226, 255, 0.651);
            margin-right: 2px;
            user-select: none;
            margin-bottom: auto;
        }
        img{
            margin-right: 6px;
            border-radius: 50%;
            object-fit: cover;user-select: none;
            min-width: 26px; max-width: 26px;
            background-color: $forum-light;
            min-height: 26px;max-height: 26px;
            cursor: pointer;transition: 0.2s;
        }
        small{
            font-size: 12px;
            color: rgb(197, 227, 255);
            transition: 0.2s;
            font-weight: 500;
            cursor: pointer;
        }
        small:hover{
            color: $form_blue-logo;
        }   
        span{
            font-weight: 600;
            background: -webkit-linear-gradient( #1271e7,  #47aded) !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
        }
        .imgs{
            margin-left: 6px;
        }
        .mapImg{
            margin-right: -8px;
        }
        img, .mapImg{
            border: 1px solid rgba(169, 214, 254, 0.1);
        }
        img:hover, .mapImg:hover{
            border-color: rgba(0, 157, 255, 0.402);
        }

    }
}

@media screen and  (max-width: 1100px){
    .relCard{
    }
}