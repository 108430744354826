@import '../settings';

.explorePage{
    color: $forum_font_white;
    width: 100%;
    min-width: 380px;
    min-height: 92vh;
    .loading{
        width: 90%;
    }
    .message_card_container{
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .pinned{
        border-top: 1px solid rgba(223, 240, 255, 0.088);
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(223, 240, 255, 0.088);
        .pinedTitle{
            text-align: center;
            font-size: 17px;
        }
    }
    .mainTitle{
        font-size: 26px;
        text-align: center;
        color: $form_blue-logo;
    }
    .ttle, .sub_title{
        width: max-content;
        text-align: left;
        margin-left: 10px;
    }
    .ttle{
        font-size: 16px;font-weight: 700;
        margin-bottom: 14px;
        background-color: rgb(24, 34, 43);
        margin-top: 8px;
    }
    .sub_title{
        margin-top: 5px;
        margin-bottom: 12px;
    }
    .users_suggz{
        display: flex;
        flex-direction: column;
        max-width: 95%;background-color: $forum-light;
        margin-left: auto;margin-right: auto;
        margin-top: 20px;margin-bottom: 20px;padding: 8px;
        border-radius: 20px;border: 2px solid rgba(211, 235, 255, 0.05);
        .flex_usr{
            display: flex; flex-direction: row;
            justify-content: space-between;margin-bottom: 10px;
            border-radius: 25px;transition: 0.2s;
            background-color: rgb(24, 34, 44);position: relative;z-index: 2;
            padding: 10px;
            border: 2px solid rgba(124, 194, 255, 0.12);
            .flex_ri, .flex_le{
                display: flex;
                width: max-content;
                flex-direction: row;
            }
            .txts{
                display: flex;backdrop-filter: blur(40px);
                padding: 0px 11px 2px 11px;border-radius: 10px;
                flex-direction: column;position: relative;
                justify-content: center;margin-left: 6px;
                background-color: rgba(49, 49, 49, 0.22);
                border: 1px solid rgba(255, 255, 255, 0.1);
                max-width: 100%;
                h1, h2{
                    margin-top: 0px;
                    margin-bottom: 0px;
                    max-width: 100%;
                    white-space: nowrap;text-overflow: clip;
                    overflow-x: hidden;
                    color: rgb(199, 228, 255);
                    font-size: 16px;
                }
                h1{
                    max-width: 210px;
                    color: rgb(240, 248, 255);
                    font-weight: 600;
                }
                h2{
                    font-size: 12px;
                    font-weight: 300;
                }
            }
            img{position: relative;z-index: 2;
                max-width: 40px;min-width: 40px;
                max-height: 40px;min-height: 40px;
                border-radius: 50%;object-fit: cover;
            } 
            
            .usr_pfp{
                background-color: rgba(32, 42, 51, 0.45);
            }

            .usr_bnr{
                min-width: 300px;max-width:300px;
                min-height: 60px;max-height: 60px;
                position: absolute;z-index: 0;
                transition: 0.2s;
                border-radius: 23px 0px 0px 23px;
                margin-top: -10px;margin-left: -10px;
                border-right: 1px solid rgba(124, 194, 255, 0.12);
            }
            button{
                padding: 7px 10px 5px 10px;border: 2px solid rgba(240, 248, 255, 0.374);border-radius: 12px;
                height: 38px;margin-top: auto;margin-bottom: auto;transition: 0.2s;cursor: pointer;;
                margin-right: 8px;z-index: 3;
                font-size: 18px;background-color: $form_blue-logo;color: white;
            }
            .dm{
                background-color: aliceblue;
                color: $form_blue-logo;border-color: $form_blue-logo;
            }
            button:hover{border-radius: 15px;}
        }
        .flex_usr:hover{
            border-radius: 15px;
            border-color: $form_blue-logo;
            .usr_bnr{
                border-radius: 11px 0px 0px 11px;
            }
        }
    }
    .dataMapping{
        background-color: $forum-light;
        padding-bottom: 10px;
        border-radius: 15px;
        padding-top: 10px;
        border-right: 2px solid $forum-light;
        border-left: 2px solid $forum-light;
        img{
            max-width: 50px;
            object-fit: cover;
            max-height: 50px;
            min-width: 50px;
            min-height: 50px;
            border-radius: 50%;
        }
        h1, h2{
            font-size: 17px;
            transition: 0.3s;
        }
        h1{
            font-weight: 600;
            color: $forum_font_white;
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 0px;
        }
        h2{
            font-weight: 500;
            margin-top: 4px;
            font-size: 14px;
            color: $forum-font-lightgray;
        }

        h5{
            color: $forum-font-lightgray;
            margin-top: 7px;
            font-weight: 400;
            margin-bottom: 0px;
        }

  
        .aMap{
            cursor: pointer;
            padding-top: 3px;
            padding-left: 15px;
            padding-bottom: 3px;
        }
        .aMap:hover, .prfileCard:hover{
            background-color: rgba(204, 231, 255, 0.03);
            h1{
                color: $form_blue-logo;
            }
            h1,h2{
                transform: translateX(1px);
            }
        }
        .prfileCard{
            padding: 10px 20px 10px 13px;
            justify-content: space-between;
            button{
                margin-top: 6px;
                width: max-content;
                min-width: max-content;
                padding: 7px 16px 7px 16px;
                border: 2px solid transparent;
                color: $forum-bg;
                height: max-content;cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                border-radius: 20px;
                transition: 0.3s;
            }
            button:hover{
                border-radius: 10px;
            }
            .followed{
                color: $forum_font_white;
                background-color: $form_blue-logo;
                border-color: $forum_font_white;
            }
            .names{
                margin-left: 11px;
            }
            h3, h4{
                margin-bottom: 0px;
                margin-top: 0px;
                user-select: none;
            }
            h3{
                font-size: 18px;
            }
            h4{
                font-size: 13px;
                font-weight: 300;
                color: $forum-font-lightgray;
            }
            p{
                color: rgba(240, 248, 255, 0.897);
                font-size: 14px;
                margin-top: 12px;
                margin-bottom: 0px;
            }
        }
    }
    .homemap{
        padding-top: 10px;padding-bottom: 10px;
        border-radius: 20px;
        border: 2px solid rgba(211, 235, 255, 0.05);
        background-color: $forum-light;
        margin-bottom: 30px;
        .aMap{
            padding-top: 2px;
            padding-bottom: 2px;
        }
        .mappp{
            margin-top: 10spx;
            margin-bottom: 17px;
            border-bottom: 1px solid rgba(186, 223, 255, 0.07); 
            border-top: 1px solid rgba(186, 223, 255, 0.07); 
        }
    }
    .homemap, .users_suggz{
        .more{
            cursor: pointer;
            margin-top: 7px;margin-bottom: 5px;
            color: $form_blue-logo;
            margin-left: 12px;
            margin-right: 12px;
            font-weight: 600;
            text-align: right;
            transition: 0.2s;
        }
        .more:hover{
            transform: translateX(-2px);
        }
        .botmore{
            padding: 0px 8px 0px 8px;
        }
    }
    .typesOptions{
        width: 100%;
        border-radius: 15px 15px 0px 0px;
        background-color: $forum-light;
        height: max-content;
        justify-content: space-around;
        h3{
            font-weight: 500;
            margin-top: 20px;
            padding-bottom: 15px;
            margin-bottom: 0px;
            border-bottom: 3px solid transparent;
        }
        .selected{
            border-bottom: 3px solid $form_blue-logo ;
            font-weight: 600;
        }
    }

    .typesOptions, .prfileCard, .row{
        display: flex;
        flex-direction: row; 
        transition: 0.3s;
    }

}


@media screen and  (max-width: 1167px ){
    .explorePage{
        .users_suggz{
            overflow-x: scroll;
            .usr_bnr{
                min-width: 230px !important;max-width:230px !important;
            }
        }
    }
}
