@import '../settings';

.message_card_container{
    background-color: $forum-light;
    display: flex;
    z-index: 0;
    flex-direction: row;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 20px;
    margin-top: 18px;
    max-width: 651.5px;
    .msg_left_cntnr{
        margin-left: 18px;
        margin-right: 10px;
        img{
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 50%;
            user-select: none;
            transition: 0.3s;
            border: 2px solid transparent;
        } 
        img:hover{
            border-color: $form_blue-logo;
            background-color: $form_blue-logo;
        }
    }
    .msg_right_cntnr{
        width: 90%;
        margin-right: 25px;
        .msg_names{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            h1{
                font-size: 16px;
                color: $forum_font_white;
                margin-top: 3px;
                font-weight: 600;
                max-width: 80%;
                white-space: nowrap;
                span{
                    font-size: 14px;
                    font-weight: 300;
                    color: $forum-font-lightgray;
                }
            }
            span{
                color: $forum-font-lightgray;
                font-size: 13px;
                transition: 0.3s;
                font-weight: 300;
            }
            .msgn_names_lft{
                span{
                    margin-top: 0px;
                }
                h1{
                    margin-bottom: 0px;
                    transition: 0.3s;
                }
               h1:hover{
                cursor: pointer;
                    color: $form_blue-logo;
                    transform: translateX(-1px);

                span{
                    color: $forum-font-header;
                }
               }
            }
            .msgn_names_rght{
                margin-top: 20px;
            }
            .reportBtn{
                transition: 0.3s;
                margin-top: 30px !important;
                padding: 12px 14px 12px 14px;
                border-radius: 50%;
                border: 1px solid $forum-clear;
                margin-right: 0px;
                background-color: rgba(0, 0, 0, 0.133);
                font-size: 15px;
            }
            .reportBtn:hover{
                border-color: $forum-font-white;
                color: $forum-font-white;
            }
        }

        .msg_content{
            .msg_desc_div{
                color: $forum_font_white;
                font-size: 10px;
                margin-top: 18px;
                max-width: 525px;
                h1{
                    transition: 0.2s;
                    font-size: 16px;
                    max-width: inherit;
                    overflow-wrap: break-word;
                    white-space: pre-line;
                    cursor: pointer;
                    margin-bottom: 4px;
                    font-weight: 400;
                    .taggg,.mtion{
                        cursor: pointer;
                    }
                    .taggg{
                        font-weight: 600;
                        transition: 0.2s;
                        color: $form_blue-logo;
                    }
                    .taggg:hover{
                        color: #47aded;
                    }
                    .mtion{
                        font-size: 15px;
                        margin-left: 3px;
                        background: -webkit-linear-gradient( #47aded,  #317cd7);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 400;transition: 0.2s;
                    }
                    .mtion:hover{
                        border-bottom: 1px solid #1da0f257;
                        text-decoration: underline !important;
                    }
                }
                .desc_txt:hover{
                    color: #c8e5ff;
                }
                strong{
                    font-weight: 600;
                }
                h2{
                    color: $form_blue-logo;
                    font-size: 16px;
                    margin-top: 0px;
                    font-weight: 600;
                    cursor: pointer;
                }
                img{
                    width: max-content;
                    max-height: 400px;
                    max-width: 100%;
                    border-radius: 24px;
                    border: 1px solid rgba(185, 222, 255, 0.323);
                }
            }
            .msg_interactions_count{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: $forum-font-lightgray;
                .inter_count ,.cmnts_count{
                    margin-top: auto;
                    margin-bottom: auto;
                    .rd{
                        background-color: #f3607a;
                    }
                    .bl{
                        background-color: #4b93ff;
                    }
                    .gr{
                        background-color: #37cea0;
                    }
                    span{
                        color: white;
                        border: 1px solid rgba(255, 255, 255, 0.146);
                        margin-top: auto;
                        text-align: center;
                        margin-bottom: auto;
                        margin-right: 5px;
                        border-radius: 7px;
                        font-size: 12px;
                        .ntrcnt{
                            padding: 3px 6px 2px 6px;
                        }
                    }
                    p{font-size: 13px;
                    margin-right: 10px;}
                }
                .inter_count{
                    display: flex;
                    flex-direction: row;
                    margin-top: 1px;
                    margin-bottom: 1px;
                }
                .cmnts_count{
                    display: flex;
                    transition: 0.3s;
                    flex-direction: row;
                    h1{
                        user-select: none;
                        cursor: pointer;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    span{
                        margin-left: 10px;
                    }
                }
                .cmnts_count:hover{
                    transform: translateX(-2px);
                    color: $forum-font-white;
                }
            }
        }

        .topComment_sect{
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            margin-top: 10px;
            padding: 12px;
            cursor: pointer;
            border: 1px solid rgba(168, 214, 255, 0.215);
            .topComment_usr, .topCommentData{
                display: flex;
                flex-direction: row;
            }
            h1, h2, p {
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 15px;
                color: aliceblue;
            }
            .topComment_usr{
                margin-bottom: 3px;
                img{
                    min-width: 22px;min-height: 22px;
                    max-width: 22px; max-height: 22px;
                    border-radius: 50%;
                    margin-right: 6px;
                    object-fit: cover;
                }
                h1{
                    margin-top: auto;
                    margin-bottom: auto;
                    font-weight: 700;
                    span, small{
                        font-weight: 300;
                        color: rgba(205, 232, 255, 0.499);
                    }
                }
            }
            .topCommentData{
                flex-direction: column;
                p{
                    font-size: 10px;
                    color: rgba(205, 232, 255, 0.499);
                }
                h2{
                    font-size: 15px;
                    font-weight: 400;
                    max-width: 450px;
                }
            }
        }


        .msg_interact_btns{
            display: flex;
            flex-direction: row;
            margin-top: 0px;          
            .interactBtn{
                display: flex;
                flex-direction: row;
                border-radius: 11px;
                background-color: $forum-clear;
                border: 2px solid transparent;
                color: $forum_font_white;
                transition: 0.2s;
                justify-content: center;
                height: 31px;
                margin-right: 10px;
                border: 2px solid rgba(18, 26, 39, 0.752);
                width: 100%;
                h5, q{
                    color: rgb(230, 243, 255);
                    width: max-content;
                    user-select: none;
                }
                h5{
                    font-weight: 500;
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                    font-size: 13px;
                }
                q{
                    font-size: 10px;
                    font-weight: 700;
                    quotes : none;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                span{
                    margin-right: 5px;
                }
                .selected{
                    display: none;
                }
            }
            .dlt{
                background-color: $forum-bg;
                border-color: $forum-font-white;
            }
            .bkmrk{
                border: 2px solid $forum-clear;
                background-color: rgba(0, 0, 0, 0.115);
            }
   
            .bkmrk, .dlt{
                min-width: 36px;
                max-width: 36px;
                font-size: 20px !important;
                width: 150px !important;
            }
            .r:hover, .retweted{
                background-color: #4b93ff;
            }
            .l:hover, .likedMsg{
                background-color: #f3607a;
            }
            .l:hover{
                box-shadow: 0px 3px 0px #f3607bcf;
            }
            .r:hover{
                box-shadow: 0px 3px 0px #4b93ffe0;
            }
            .c:hover{
                background-color: #a365ff;
            }
            .likedMsg, .retweted{
                //transform: scale(0.92);
                border-color: rgba(223, 240, 255, 0.641);
            }
            button:hover, .interactBtn:hover{
                //transform: translateX(1px);
                border-color: $forum-font-white;
                cursor: pointer;           
            }
            .likedMsg:hover, .retweted:hover{
               // transform: scale(0.92) !important;
                border-color: transparent!important;
                box-shadow: 0px 0px 0px  rgba(192, 216, 255, 0.206) !important;
            }            
            button:active, .interactBtn:active{
                transform: scale(0.95);
            }
            .bkmrked{
                border-radius: 10px !important;
                border-color: rgba(18, 26, 39, 0.752);
                background-color: $forum-clear;
            }
        }
    }
}



.comment_bg{
    position: absolute;
    width: 100vw;
    margin: 0 auto;
    z-index: 1;
    background-color: rgba(128, 128, 128, 0.162);
    height: 100vh;
    .comment_card{
        margin: 0 auto;
    }
}





.comments_card_container{
    color: $forum-font-white;
    margin-left: 5%;
    width: 92%;
    margin-top: 20px;
    .mappedComment_card{
        margin-top: 0px;
        margin-bottom: 8px;
        border-radius: 17px;
        padding: 8px 20px 8px 8px;
        border: 1px solid rgba(144, 203, 255, 0.191);
        display: flex;
        background-color: $forum-light;
        flex-direction: column;
        .top_comment{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            div{
                width: max-content;
                display: flex;
                flex-direction: row;
            }
            img{
                border: 1px solid rgba(144, 203, 255, 0.191);
                min-width: 38px;
                min-height: 38px;
                object-fit: cover;
                max-height: 38px;
                max-width: 38px;
                border-radius: 50%;
            }
            .texts{
                display: flex;
                margin-top: -5px;
                margin-left: 10px;
                flex-direction: column;
                justify-content: center;
                h1, p{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                h1{
                    color: rgb(200, 229, 255);
                    font-size: 15px;
                    font-weight: 600;
                    strong{
                        font-size: 13px;
                        font-weight: 400;
                        color: $form_blue-logo;
                    }
                }
                p{
                    font-size: 12px;
                    margin-top: 0px;
                    font-weight: 300;
                    color: rgba(189, 224, 255, 0.398);
                }
            }

            span, button{
                width: 10px;
                cursor: pointer;
                height: 10px;
                margin-top: 8px;
            }
            button{
                background-color: transparent;
                text-align: center;
                font-size: 18px;
                border-radius: 10px;
                border: 0px;transition: 0.2s;
                margin-right: 10px;
                color: rgba(171, 216, 255, 0.317);
            }
            button:hover{
                color: #ffa7a7;
            }
            .activated, .activated:hover{
                color: #ff4343;
            }
        }
        .bot_comment{
            h2{
                font-size: 14px;
                font-weight: 500;
                margin-top: 0px;
                max-width: 380px;
                text-overflow: ellipsis;
                white-space: pre-line;
                overflow-x: hidden;
                margin-bottom: 0px;
                margin-left: 50px;
            }
        }
    }
    .moreResponses{
        text-align: center;
        cursor: pointer;
        transition: 0.3s;
        font-size: 15px;
        font-weight: 600;
        color: rgba(194, 226, 255, 0.713);
        margin-top: 25px;
        margin-bottom: 40px;
        position: relative;
    }
    .moreResponses:before{
        content: ""; display: block;
        width: 30%;
        height: 1px;
        background: rgba(166, 213, 255, 0.315);position: absolute;
        top: 47%;
    } 
    .moreResponses:after {
        content: ""; display: block;
        width: 30%;
        height: 1px;
        background: rgba(166, 213, 255, 0.315);position: absolute;
        top: 47%;
        right: 0%;
    }   
    .moreResponses:hover{
        color: white;
        transform: translateY(1px);
        span{
            transform: rotate(-0.25turn) !important;
        }
    }
}


@media screen and  (max-width: 1320px){
    .msg_right_cntnr{
        width: 78% !important;
    }
}
@media screen and  (max-width: 1300px){
    .moreResponses:after, .moreResponses:before{
        width: 24% !important;
    }
}

@media screen and  (max-width: 1240px){
    .message_card_container{
        .msg_interact_btns{
            .interactBtn{
                h5{
                    span{
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }
}


@media screen and  (max-width: 1140px){
    .msg_right_cntnr{
        width: 76% !important;
    }
   .msg_interact_btns{
        h5{
            font-size: 14px !important;
        }
   }
}
@media screen and  (max-width: 1100px){
    .msg_left_cntnr{
        margin-left: 10px !important;
        margin-right: 6px !important;

        img{
            max-width: 60px !important;
            max-height: 60px !important;
        }
    }
    .msg_right_cntnr{
        width: 81% !important;
    }
}
@media screen and  (max-width: 1090px){
    .msg_content{
        .msg_desc_div{
            img{
                max-width: 90% !important;
            }
        }
    }
}
/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 863px){
    .msg_interact_btns{
        h5{
            font-size: 16px !important;
        }
        q{
            display: none !important;
        }
    }
}
@media screen and  (max-width: 863px){
    .msg_interact_btns{
        h5{
            font-size: 16px !important;
        }
        q{
            display: none !important;
        }
    }
}
@media screen and  (max-width: 790px){
    .message_card_container{
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
@media screen and  (max-width: 560px){
    .msg_right_cntnr{
        width: 75% !important;
    }
    .moreResponses:after, .moreResponses:before{
        width: 18% !important;
    }
}
@media screen and  (max-width: 500px){
    .message_card_container{
        .msg_left_cntnr{
            margin-left: 8px !important;
            margin-right: 8px !important;
        }
         .msg_right_cntnr{
            width: 100%;
            margin-right: 0px !important;
            .msg_names{
                span{
                    font-size: 12px !important;
                }
            }
            .reportBtn{
                display: none !important;
            }
         }
    }
    .msg_interact_btns{
        justify-content: space-between;
        button{
            padding: 5px 15px 5px 15px !important;
            span{
                margin-right: 0px !important;
            }
        }
    }

}

