@import '../settings';

.prv{
    small{
        position: absolute;
        bottom:-40px;
        text-align: center;
        font-weight: 300;
        color: rgba(195, 227, 255, 0.344);
        max-width: $side_max_width  !important;
    }
}

.who_follows_you{
    max-width: $side_max_width  !important;
    border-radius: 15px;
    background-color: $forum-light;
    margin-bottom: 10px;
    margin-top: -8px;
    h2,h4, .followlist{
        margin-left: 20px;
    }

    h2{
        padding-top: 17px;
        color:aliceblue;
        font-weight: 800;
        font-size: 17px;
    }
    h4{
        color: $form_blue-logo;
        font-weight: 500;
        font-size: 17px;
        margin-top: 6px;
        padding-bottom: 16px;
        cursor: pointer;
        padding-left: 10px;
    }
    .followsList{
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        overflow-y: scroll;
        h1{
            margin-top: 4px !important;
        }
        .follow_sugg{
            height: 65px;
            display: flex;
            cursor: pointer;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 13px;
            margin-left: 10px;
            margin-right: 10px;
            transition: 0.3s;
            img{
                width: 45px !important ;
                height: 45px !important;
                min-width: 45px;
                min-height: 45px;
                border-radius: 50%;
                object-fit: cover;
                transition: 0.3s;
                border: 2px solid transparent;
            }
    
            .flw_names{
                margin-top: auto;
                width: 100%;
                margin-left: 5px;
                height: 100%;
                display: flex;
                flex-direction: column;
                ::-webkit-scrollbar{
                    display: none;
                }
                overflow-x: scroll;
                white-space: nowrap;
                margin-right: 6px;
            }
    
            h1,img, button{
                margin-top: auto;
                margin-bottom: auto;
            }
            h1{
                width: 100%;
                color: $forum_font_white;
                font-size: 16px;
                max-width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
                transition: 0.4s;
                font-weight: 500;
            }
            h3{
                margin-top: 1px;
                font-size: 14px;
                font-weight: 400;
                transition: 0.3s;
                color: $forum-font-lightgray;
            }
            h1,h3{
                margin-top: 0px;
                margin-bottom: 0px;
            }
     
            button{
                border-radius: 25px;
                cursor: pointer;
                padding: 7px 17px 7px 17px;
                font-size: 14px;
                border: 2px solid transparent;
                font-weight:600 !important;
                color: $forum-bg;
                transition: 0.3s;
            }
            button:hover{
                transform: scale(0.97);
                border-color:$form_blue-logo;
                color: $form_blue-logo;
                box-shadow: 0px 2px 0px $form_blue-logo;
            }

            .followed{
                background-color: $form_blue-logo;
                color: $forum-font-white;
            }
            .followed:hover{
                box-shadow: 0px 0px 0px $form_blue-logo;
                color: $forum-font-header;
            }
        }
        .follow_sugg:hover{
            background-color: rgba(148, 205, 255, 0.015);
            transform: translateX(-2px);
            h1{
                color: $form_blue-logo;
            }
            h3{
                color: $forum-font-white;
            }

            img{
                border-color: $form_blue-logo;
            }
    
        }
    }
    .short{
        height: 135px !important;
        max-height: 135px !important;
        .follow_sugg{
            margin-top: 5px;
            .flw_names{
                display: flex;
                flex-direction: column;
                margin-top: 0px;
                justify-content: center;
                h1, h3{
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                }
            }
        }
    }
    .long{
        height: 210px !important;
        max-height: 210px !important;
        border-bottom: 1px solid rgba(240, 248, 255, 0.08);
    }


}



  
  
  
  
  
  
  
  
@media screen and  (max-height: 800px ){
    .who_follows_you{
        h2{
            padding-top: 16px;
            margin-bottom: 4px;
        }
    }
}