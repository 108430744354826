@import '../settings';

.liveChat_page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 89vh;
    min-height: 89vh;
    max-height: 89vh;
    color: $forum-font-header;
    font-size: 14px !important;
    .subLiveChat{
        width: 100%;
        border-radius: 20px;
        max-width: 100%;
        box-shadow: 0px 20px 30px #0d1014;
        border: 0.1rem solid rgba(240, 248, 255, 0.352);
        display: grid;
        grid-template-columns: 0.2fr 1fr;
        .left, .right{
            height: 86vh;
            min-height: 600px;
        }
        .right, .B, .l, .top{
            position: static !important;
        }
        .r, .userCard{
            position: relative !important;
        }
        .left{
            border-radius: 20px 0px 0px 20px;
            background-color: $liveChat-2;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .channels, .chatRooms{
                display: flex;
                flex-direction: column;
            }
           .channels{
                background-color: $liveChat-4;
                padding-left: 10px;
                padding-right: 10px;
                overflow-y: scroll;
                scrollbar-width: thin;
                ::-webkit-scrollbar {
                    width: 5px;
                }  
                border-radius: 20px 0px 0px 20px;
                width: 65px;
                min-width: 65px;
                padding-bottom: 30px;
                max-width: 65px;
                button{
                    width: max-content;
                    margin-left: auto;
                    margin-right: auto;
                    transition: 0.3s;
                    background-color: #384b5a;
                    border: 0px solid transparent;
                    color: $form_blue-logo;
                    padding: 0px;
                    font-size: 24px;
                }
                img:hover, button:hover{
                    background-color: $form_blue-logo;
                    color: white;
                    border-radius: 10px;
                }
                p, img, button{
                    user-select: none;
                }
                i{
                    width: 47px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    border-radius: 2px;
                    border-top: 3px solid  #384b5a;
                }
                p{
                    text-align: center;
                    color: aliceblue;
                    border: 2px solid $form_blue-logo;
                    padding: 4px 8px 4px 8px;
                    margin-left: auto;
                    margin-right: auto;
                    width: max-content;
                    border-radius: 10px;
                    background-color: #384b5a;
                    font-size: 13px;
                    font-weight: 600;
                    margin-top: 7px;
                    margin-bottom: 0px;
                }
                h1{
                    font-size: 30px;
                    margin-bottom: 4px;
                    margin-top: 12px;
                    text-align: center;
                    padding: 8px;
                    margin-left: auto;
                    border: 3px solid rgba(192, 226, 255, 0.574);
                    margin-right: auto;
                    color: white;
                    border-radius: 15px;
                    background-color: $form_blue-logo;
                }
                img, button{
                    margin-top: 8px;
                    width: 60px;
                    height: 60px;
                    min-height: 60px;
                    min-width: 60px; 
                    border-radius: 20px;
                }
                img, img:hover{
                    border: 1px solid rgba(198, 229, 255, 0.03);
                    background-color: $liveChat-2;
                }

                img{
                        transition: 0.3s;
                        margin-left: auto;
                        margin-right: auto;
                        object-fit: cover;
                }
                img:active{
                    transform: translateY(4px);
                }
                span{
                    display: none;
                    position: fixed;
                    top: 200px;
                    left: 200px;
                    background-color: black;
                    z-index: 2 !important;
                    width: 200px;
                    transform: translateX(50px);
                    border: 0.1rem solid rgba(240, 248, 255, 0.352);
                }
                .channel{
                    width: max-content;
                    margin-left: auto;
                    margin-right: auto;
                }
                .channel:hover{
                    cursor: pointer;
      
                }
           }


           
           .chatRooms{
                background-color: $liveChat-2;
                justify-content: space-between;
                width: 100%;
                max-width: 250px;
                min-width: 250px;
                height: 100%;
            
                .rooms{
                    width: 100%;
                    max-width: 100%;
                    height: 96%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .lds-dual-ring{
                        margin-top: 0px !important;
                        margin-left: 72px !important;
                    }
                }
     
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    object-fit: cover;
                }
                .userInfos{
                    height: 40px;
                    min-height: 40px;
                    font-size: 15px;
                    display: flex;
                    flex-direction: row;
                    justify-content:  space-between;
                    padding: 8px;
                    min-height: 40px;
                    background-color: rgba(0, 0, 0, 0.171);
                    p{
                        margin-left: 5px;
                        color: white;
                        margin-top: 4px;
                        margin-bottom: 0px;
                    }
                    .ps{
                        margin-top: 0px;
                        font-size: 13px;
                        color: rgba(218, 238, 255, 0.773);
                    }
                    .infRow{
                        display: flex;
                        flex-direction: row;
                    }
                }
                h1{
                    width: 100%;
                    overflow-x: hidden;
                    height: 25px;
                    padding-bottom: 15px;
                    border-bottom: 2px solid $forum-light;
                    font-size: 18px;
                    color: $forum-font-header;
                    font-weight: 500;
                    margin-top: 19px;
                    text-align: center;
                }
                .chatRoom{
                    overflow-x: hidden;
                    max-width:99%;
                    padding: 7px;
                    .title, .lft{
                        display: flex;
                        flex-direction: row;
                    }
                    .lft{
                        height: max-content;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                    h3{
                        margin-left: auto;
                        margin-right: auto;
                        width: max-content;
                        text-align: center;
                        margin-top: 8px;
                        padding: 10px 15px 10px 15px; 
                        border: 0px solid transparent;
                        font-size: 12px;
                        color: aliceblue;
                        transition: 0.3s;cursor: pointer;
                        font-weight: 500;
                        border: 1px solid rgba(240, 248, 255, 0.331);
                        border-radius: 10px;
                        animation: glowing 1500ms infinite;
                    }
                
                    @keyframes glowing {
                        0% {
                          background-color: $liveChat-1;
                        }
                        50% {
                            transform: scale(0.97);
                            background-color: $liveChat-2;
                        }
                        100% {
                            background-color: $liveChat-1;
                        }
                    }
                      h3:hover, .leave:hover{
                        border-color: transparent;
                        background-color: $form_blue-logo !important;
                    }
                    .title{
                        border-radius: 5px;
                        width: 95%;
                        transition: 0.3s;
                        border: 2px solid transparent;
                        height: max-content;
                        padding: 4px;
                        justify-content: space-between;
                        padding-left: 8px;
                        cursor: pointer;
                    }
                    .title:hover, .selected{
                        background-color: $liveChat-1;
                    }
                    .selected{
                        color: aliceblue;
                    }
                    p, span, h3, h1, img, h4{
                        user-select: none;
                    }
                    h4{
                        margin-top: 0px;
                        font-size: 13px;
                        margin-bottom: 0px;
                        border-radius: 5px;
                    }
                    p, span, i{
                        color: rgba(240, 248, 255, 0.661);
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                    span{
                        margin-right: 6px;
                        font-size: 18px;
                    }
                    i{
                        margin-right: 6px;
                        font-size: 10px;
                    }
                    p{
                        font-weight: 400;
                        font-size: 15px;
                    }
                    .roomTxt{
                        max-width: 135px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .leave{
                    animation: none;
                    margin-left: auto;
                    cursor: pointer;
                    margin-right: auto;
                    padding: 8px 14px 8px 14px;
                    width: max-content;
                    background-color: $form_blue-logo;
                    border-radius: 8px;
                    color: aliceblue;
                    transition: 0.3s;
                    border: 2px solid  aliceblue;
                }
                .leave:hover{
                    border: 2px solid $forum-bg;
                }
              
           }
        }


        .right{
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            border-radius: 0px 20px 20px 0px;
            max-width: 100%;
            background-color: $liveChat-1;
            .B{
                height: 100%;
                display: flex;
                margin-bottom: 0px;
                flex-direction: row;
                position: relative;
                .l{
                    display: flex;
                    flex-direction: column;
                    width: 75%;
                    .bot,.top{
                        p{
                            margin-top: 0px;
                        }
                        margin-top: 0px;
                        margin-bottom: 0px;
                        height: 6%;
                    }
                    .fadeTop{
                        padding: 5px !important;
                        .descDiv, .canvasDiv{
                            margin-left: 0px !important;
                            .sceneBg{
                                border: 0px solid rgba(240, 248, 255, 0.377) ;
                            }
                     
                        }
                        .canvasDiv{
                            .sceneBg{
                                border-radius: 16px 16px 0px 0px !important;
                            }
                        }
                        .descDiv{
                            margin-top: 0px !important;
                            background-color: #0d101443;
                            border-radius: 0px 0px 17px 17px;
                            z-index: 2;
                            padding-left: 23px !important;
                            h4{
                                margin-left: 95px !important;
                                margin-top: 10px !important;
                            }
                            .channelPictu{
                                margin-top: -40px;
                                position: absolute;
                            }
                            .similar{
                                p{color: aliceblue !important;}
                                img{border-color: rgba(194, 225, 255, 0.863) !important;}
                            }
                            .subMenu{
                                padding-left: 3px !important;
                                margin-left: -23px !important;
                                margin-right: 0px !important;
                                border-color: rgba(255, 255, 255, 0.173) !important;

                                h3{ color: rgba(220, 239, 255, 0.948) !important;}
                                h3:hover{ background-color: rgba(240, 248, 255, 0.1) !important ;border-color: transparent !important; }
                                .active{color: white !important;  }
                                .active:hover{  border-color: $form-blue-logo !important; }
                            }
                        }
                 
                    }
                    .top{
                        display: flex;
                        flex-direction: column;
                        background-color: $liveChat-5 !important;
                        padding: 2px;
                        padding-bottom: 0px;
                        margin-left: 13px;
                        margin-bottom: 4px;
                        margin-right: 13px;
                        margin-top: 6px;
                        border-radius: 17px;
                        position: static;
                        min-height: 415px;
                        max-height: 415px;
                        .topdiv{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            h4{
                                margin-top: 56px;
                                font-weight: 300;
                                font-size: 14px;
                                margin-left: 10px;
                            }
                            .rw{
                                display: flex;
                                flex-direction: row;
                            }
                            .add{
                                transition: 0.3s;
                                font-size: 20px;
                                margin-bottom: 0px;
                                margin-right: 12px;
                                margin-left: 0px !important;
                            }
                            .pin{
                                margin-right: 10px !important;
                            }
                            .add:hover{
                                color: white;
                            }
                        }
                        .canvasDiv{
                            width: 100%;
                            border-radius: 10px;
                            display: flex;
                            height: 90%;
                        }
                      
                        img{
                            user-select: none;
                            border: 2px solid $liveChat-5;
                            width: 80px;
                            height: 80px;
                            background-color: $liveChat-1;
                            object-fit: cover;
                            border-radius: 20px;
                        }
                        .relationPic{
                            width: 34px;
                            height: 34px;
                            border-radius: 50%;
                            border: 3px solid $liveChat-3;
                            margin-left: -10px;
                        }
                        .similar{
                            height: max-content;
                            margin-top: 13px;
                            display: flex;
                            margin-right: 10px;
                            flex-direction: row;
                            justify-content: end;
                            img{
                                border: 2px solid rgb(70, 89, 105);
                            }
                            p, img{
                                user-select: none;
                                margin-top: auto;
                                margin-bottom: auto;
                            }
                            p{
                                text-align: right;
                                color: $liveChat-7;
                                font-weight: 500;
                                font-size: 11px;
                                width: 110px !important;
                                max-width: 110px !important;
                            }
                        }
                        .imgs, .similar{
                            display: flex;
                            flex-direction: row;
                        }
                        video, .sceneBg{
                            width: 100%;
                            height: 100%;
                            max-height: 100%;
                            border-radius: 16px;
                            max-width: 100%;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        video{
                            object-fit: cover !important;
                        }
                       .sceneBg, video{
                            max-height: 222px;
                       }
                        .descDiv{
                            margin-left: 23px;
                            margin-top: -47px;
                            display: flex;
                            height: 100%;
                            flex-direction: column;
                            justify-content: space-between;
                            h1{
                                margin-top: 0px;
                                color: white;
                                margin-bottom: 0px;
                                font-size: 27px;
                            }
                            h2{
                                font-weight: 400;
                                margin-top: 3px;
                                font-size: 15px;
                                span{
                                    margin-left: 5px;
                                    margin-right: 5px;
                                }
                            }
                            h1, h2{
                                user-select: none;
                            }
                            .subDesc{
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 10px;
                            }
                            .subMenu, .rww{
                                display: flex;
                                flex-direction: row;
                            }
                            i{
                                margin-right: 20px;
                                margin-top: auto;
                                font-size: 18px;
                                border-radius: 10px;
                                padding: 8px;
                                transition: 0.3s;
                                margin-bottom: auto;
                            }
                            .e{
                                margin-right: 5px !important;
                            }
                            i:hover{
                                background-color: $liveChat-3;
                            }
                            .subMenu{
                                margin-left: -35px;
                                margin-right: -15px;
                                justify-content: space-between;
                                padding-left: 15px;
                                border-top: 2px solid $liveChat-1;
                                h3{
                                    white-space: nowrap;
                                    user-select: none;
                                    margin-top: 0px;
                                    margin-bottom: 0px;
                                    border-radius: 2px;
                                    transition: 0.3s;
                                    font-weight: 400;
                                    margin-left: 10px;
                                    margin-bottom: 0px;
                                    cursor: pointer;
                                    color: $liveChat-7;
                                    padding: 12px;
                                    border-bottom: 3px solid transparent;
                                }
                                h3:hover{
                                    border-color: $liveChat-2;
                                    background-color: rgba(24, 49, 71, 0.227);
                                }
                                .active{
                                    border-color: $form_blue-logo;
                                    font-weight: 500;
                                    color: white;
                                }
                                .active:hover{
                                    border-color: $form_blue-logo;
                                }
                            }
                        }
                    }

                    .mid{     
                        height: 60%;
                        overflow-y: scroll;
                        display: block;
                        overscroll-behavior: none !important;
                        padding-bottom: 20px;
                        p{
                            transition: 0.3s;
                            margin-top: 0px;
                            margin-bottom: 2px;
                            color: rgba(246, 251, 255, 0.926);
                            font-size: 16px;
                            padding: 2px 8px 2px 8px;
                            width: max-content;
                            margin-left: 58px;
                            max-width: 84%;
                            border-radius: 6px;
                            overflow-wrap: break-word;
                            white-space: pre-line;
                        }
                        p:hover{
                            background-color: $liveChat-2;
                        }
                        .topMsg{
                            margin-top: -9px;
                        }
                        .pinMessage{
                            cursor: pointer !important;
                        }
                        .pinMessage:hover:after{  
                            content: 'PIN 📌';
                            position: relative;
                            margin-left: 10px;
                            margin-bottom: 3px !important;
                            font-size: 11px !important;
                            vertical-align: top;
                            border: 1px solid rgba(83, 116, 145, 0.545);
                            background-color: rgba(73, 100, 124, 0.437) !important;
                            border-radius: 5px;
                            padding: 2px 6px 1px 6px;
                            background-color: transparent !important;
                        }
                   
                        h2, h3, i{
                            margin-left: 30px;
                        }
                        i{
                            padding: 14px 20px 14px 20px;
                            border-radius: 50%;
                            font-size: 34px;
                            background-color: $liveChat-2;
                        }
                        h2, i{
                            font-size: 26px;
                            color: white;
                        }
                        h2{
                            margin-bottom: 12px;
                        }
                        h3{
                            margin-bottom: 40px;
                            margin-top: 0px;
                            font-size: 15px;
                            font-weight: 400;
                            border-bottom: 2px solid $liveChat-2;
                            width: 86%;
                            padding-bottom: 30px;
                            span{
                                padding: 7px;
                                margin-left: 4px;
                                border-radius: 10px;
                                background-color: $liveChat-2;
                            }
                        }
                        .messagesContent{
                            margin-top: 30px;
                        }
                        .spaced{
                            margin-top: 24px;
                        }
                        
                        .roomGrid{
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 20px;
                            height: 96%;
                            width: 98%;
                            margin-left: auto;
                            margin-right: auto;
                            justify-content: center;
                            .roomSlot{
                                width: 48%;
                                border: 2px solid rgba(36, 51, 63, 0.728);
                                box-shadow: 0px 0px 20px  rgba(18, 26, 33, 0.728);;
                                margin-left: auto;
                                margin-top: 10px;
                                height: max-content;
                                margin-right: auto;
                                border-radius: 15px;
                                background-color: $liveChat-2;
                                .cameraVideo{
                                    width: 100%;
                                    display: flex;
                                    video{
                                        height: max-content;
                                        width: 100%;
                                        transform: scale(-1, 1);
                                        margin: 0 auto;
                                        border-radius: 15px;
                                    }
                                }
                            }
                        }
                        .waitingForCall{
                            display: flex;
                            flex-direction: column;
                            width: 75%;
                            margin: 0 auto !important;
                            justify-content: end;
                            margin-top: 20px !important;
                            height: max-content;
                            animation: bounce 1500ms infinite;
                            video{
                                z-index: 1;
                                width: 100%;
                                border-radius: 30px !important;
                                border: 5px solid $form_blue-logo;
                                animation: borderFade 1300ms infinite;
                            }
                            h1, button{
                                margin-bottom: 20px !important;
                                margin-left: 20px !important;
                                margin-left: auto !important;
                                margin-right: auto !important;
                                z-index: 2;
                                width: 90%;
                            }
                            h1{
                                text-align: center;
                                margin-top: -165px;
                                padding: 10px;
                                width:70% !important;
                                border-radius: 10px;
                                color: aliceblue !important;
                                border: 2px solid rgba(255, 255, 255, 0.389);
                            }
                            h1{
                                backdrop-filter: blur(6px);
                                font-size: 16px;
                            }
                            button{
                                padding: 10px;
                                background-color: $form_blue-logo;
                                color: white;
                                border-radius: 12px;
                                font-size: 16px;
                                transition: 0.2s;
                                font-weight: 600;
                                border: 2px solid aliceblue;
                            }
                            button:hover{
                                box-shadow: 0px 3px 0px aliceblue;
                                border-radius: 25px;
                            }
                        }
                        .voc{
                            button{
                                background-color: rgb(122, 131, 255) !important;
                            }
                            video{
                                border-color: rgb(122, 131, 255) !important;
                            }
                        }
                        @keyframes bounce {
                            0% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(0.97);
                            }
                            100% {
                                transform: scale(1);
                            }
                          }
                    }


                    .bot{
                        height: 63px;
                        display: flex;
                        min-height: 63px;
                        justify-content: space-between;
                        flex-direction: row;
                        .bottomBar{
                            transition: 0.3s;
                            border: 3px solid rgba(156, 210, 255, 0.137);
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-left: auto;
                            margin-right: auto;
                            border-radius: 16px;
                            width: 95%;
                            height: 47px;
                            background-color: $liveChat-5;
                        }
                        .bottomBar:hover{
                            border-color: $form_blue-logo;
                        }
                        .bottomBar:focus{
                            border-color: $form_blue-logo;
                        }
                        button, input{
                            margin-top: auto;
                            margin-bottom: auto;
                            height: 30px;
                        }
                        button{
                            margin-left: 12px;
                            margin-right: 12px;
                            font-size: 21px;
                            border: 0px;
                            transition: 0.3s;
                            background-color: transparent;
                            color: aliceblue;
                        }
                        button:hover{
                            color: $form_blue-logo;
                        }
                        .emoji{
                            padding: 0px;
                            margin-right: 0px;
                        }
                        .emoji:hover{
                            color: rgb(236, 214, 22);
                        }
                        .send:hover, .emoji:hover{
                            transform: scale(0.9);
                        }
                        input{
                            border: 1px solid transparent;
                            border-radius: 7px;
                            height: 35px;
                            padding-left: 2px;
                            background-color: transparent;
                            width: 90%;
                            color: aliceblue;
                        }
                      
                        input:focus{
                            outline: none;
                        }
                        input{
                            font-size: 16px;
                        }
                        ::placeholder{
                            color: rgba(176, 218, 255, 0.511);
                        }
                    }

                    .emojiPicker{
                        position: absolute !important;
                        width: max-content;
                        bottom: 12%;
                        right: 29%;
                        height: max-content;
                    }
                }

                .fullScreen{
                    width: 100% !important;
                }
                .r{
                    border-left: 1px solid $forum-light;
                    width: 25%;
                    overflow-x: hidden;
                    background-color: $liveChat-2;
                    overflow-y: scroll;
                    border-radius: 0px 0px 20px 0px;
                    h1, span{
                        margin-left: 15px;
                    }
                    h1{
                        text-align: left;
                        margin-top: 25px;
                        color: rgba(240, 248, 255, 0.901);
                        font-weight: 500;
                        font-size: 13px ;
                    }
                    .roleh1{
                        background-color: rgb(55, 68, 80);
                        width: max-content;
                        border: 1px solid;
                        padding: 4px 10px 4px 10px;
                        border-radius: 6px;
                        margin-left: 11px;
                    }
                    span{
                        color: $form_blue-logo;
                        font-size: 15px;
                    }
                    .title{
                        font-size: 18px !important;
                        font-weight: 600;
                        max-width: 86%;
                        overflow-x: hidden;
                        text-align: center;  
                    }
                    .sort, .subTitle{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-right: 17px;
                    }
                    .subTitle{
                        flex-direction: row;
                        justify-content: space-between !important;
                        margin-top: 18px;
                        margin-bottom: 18px;
                        div{
                            display: flex;
                            flex-direction: column;
                        }
                        h5{
                            font-size: 13px;
                            margin-left: 11px;
                            margin-top: 3px;
                            margin-bottom: 3px;
                        }
                        button{
                            margin-top: auto;
                            margin-bottom: auto;
                            border: 2px solid rgb(25, 35, 45);
                            padding: 5px 10px 5px 10px;
                            color: aliceblue;
                            font-size: 14px;
                            border-radius: 10px;
                            transition: 0.3s;
                            background-color: $liveChat-3;
                        }
                        button:active{
                            transform: scale(0.85);
                        }
                    }
                    .sort{
                        span, i{
                            margin-top: auto;
                            margin-bottom: auto;
                            padding: 5px 10px 5px 10px;
                            border-radius: 10px;
                        }
                        span{
                            cursor: pointer;
                            user-select: none;
                            transition: 0.2s;
                            font-size: 14px;
                            font-weight: 600;
                            border: 2px solid rgb(42, 59, 76);
                            color: aliceblue;
                            background-color: rgb(25, 35, 44);
                        }
                        span:hover{
                            border-color: aliceblue;
                        }
                        span:active{
                            transform: scale(0.85);
                        }
                        i{
                            background-color: rgb(22, 31, 39);
                            font-size: 16px;
                            border: 2px solid transparent;
                            animation: border2 3000ms infinite;
                        }
                        .bluebord{
                            animation: border 3000ms infinite;
                        }
                    }
                
                    @keyframes border {
                        0% { border-color: transparent; border-radius: 12px;}
                        50% {  border-color: $form_blue-logo;border-radius: 4px; }
                        100% {border-color: transparent; border-radius: 12px;}
                    }
                    @keyframes border2 {
                        0% { border-color: transparent; border-radius: 4px;}
                        50% { border-color: gold; border-radius: 12px;}
                        100% {border-color: transparent; border-radius: 4px;}
                    }
                    .userCard{
                        border-radius: 10px;
                        transition: 0.3s;
                        display: flex;
                        flex-direction: row;
                        padding: 6px;
                        max-height: 40px;
                        margin-bottom: 5px;
                        .names{
                            user-select: none;
                            margin-left: 8px;
                            margin-top: auto;
                            margin-bottom: auto;
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            h2, h3{
                                color: $font-verylight;
                                margin-top: 0px;
                                font-size: 16px;
                                max-width: 99%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-bottom: 0px;
                            }
                            i{
                                color: gold;
                            }
                            h3{
                                color: $forum-font-lightgray;
                                font-size: 14px;
                                font-weight: 400;                            
                            }
                        }
                        
                        img{
                            filter: grayscale(20%);
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }
                    .connected{
                        img{
                         border: 2px solid $form_blue-logo;
                        }
                        h2{color: white  !important;}
                        h3{color: $form_blue-logo  !important;}
                    }
                    .userCard:hover{
                        cursor: pointer;
                        background-color: $forum-light;
                    }

                }
            }

        }

        .illustration{
            display: flex;
            flex-direction: row;
            height: max-content;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 20px;
            margin-right: 20px;
            border: 2px solid $liveChat-2;
            border-radius: 7px 30px 30px 7px;
            padding: 40px 30px 40px 30px;
            img{
                border: 8px solid $liveChat-2;
                width: 57%;
                margin-left: 25px;
                margin-top: auto;
                margin-bottom: auto;
                border-radius: 25px;
                max-height: 420px;
                animation: fade 3000ms infinite;
            }
            @keyframes fade {
                0% {
                    transform: scale(1);
                }
                50% {
                    box-shadow: 5px 4px 0px #895eff;
                    transform: scale(0.95);
                }
                100% {
                    transform: scale(1);
                }
              }
            h1{
                margin-top: 0px;
                font-size: 36px;
                font-weight: 700;
            }
            h4{
                font-size: 21px;
                font-weight: 500;
                color: #895eff;
            }
            h2{
                font-weight: 400;
                font-size: 19px;
                span{
                    font-weight: 600;
                    color: #895eff;
                }
            }
            h3{
                transition: 0.3s;
                margin-top: 40px;
                border: 2px solid $liveChat-2;
                box-shadow: 0px 4px 0px $liveChat-2;
                color: white;
                background-color:  #6e49d4;
                border-radius: 10px;
                padding: 12px;
                width: max-content;
                width: 85%;
                text-align: center;
            }
            h3:hover{
                border-color: white;
                box-shadow: 0px 0px 0px $liveChat-2;
            }
        }
    }

    .subRow{
        display: flex !important;
        flex-direction: row !important;
    }

    .fadeCard{
        padding: 4px !important;
        .UserCardMenu{
            background-color: rgba(0, 0, 0, 0.3) !important;
            .profile{
                border-color: white !important;
            }
        }
    }
    .UserCardMenuParent{
        background-color: $liveChat-4 !important;
        position: absolute !important;
        width: 300px !important;
        right: 25.5vw;
        z-index: 4 !important;
        top: 510px;
        border-radius: 15px;
        padding: 7px;
        .UserCardMenu{
            position: relative;
            height: max-content;
            border-radius: 15px;
            display: flex;

            flex-direction: column;
            padding-bottom: 17px;
            overflow-y: scroll;
            .banner{
                min-height: 125px !important;
                border: 0px solid transparent !important;
                width: 100% !important;
                min-height: 120px;
                max-height: 120px;
                object-fit: cover !important;
                border-radius: 10px 10px 0px 0px !important;
            }
            .hitbox{
                width: 370px !important;
                margin-left: auto;
                display: block;
                height: 500px;
                position: absolute;
                margin-left: auto;
                color: transparent !important;
            }
            .profile{
                border-radius: 50%;
                object-fit: cover;
                margin-top: -45px;
                border: 2px solid $liveChat-4;
                background-color: $liveChat-4;
                width: 75px !important;
                height: 75px !important;
            }
            h1,h2,h3,.profile, p, h5, small{
                margin-left: 8px !important;
            }
            h1, h2, h3, h5, small{
                font-size: 14px !important;
                margin-top: 0px !important;
                margin-bottom: 8px !important;
            }
            h1{
                font-weight: 600 !important;
                margin-top: 0px !important;
                margin-bottom: 1px !important;
                font-size: 18px !important;
            }
            h2{
                color: $liveChat-6 !important;
                margin-bottom: 15px !important;
                font-weight: 400 !important;
            }
            h3{
                span{
                    background: -webkit-linear-gradient( #1271e7,  #47aded);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .vrf{
                margin-top: 6px !important;
            }
            h5{
                color: white;
                font-size: 12px !important;
                span{
                    padding-top: 10px;
                    border-radius: 10px;
                    padding: 4px 9px 4px 9px;
                    border: 1px solid transparent;
                    background-color: rgba(61, 85, 109, 0.231);
                    font-size: 12px !important;
                }
                width: max-content;
            }
            .permissions{
                display: flex;
                margin-top: 6px;
                flex-direction: row;
                small{
                    border: 2px solid rgba(240, 248, 255, 0.264);
                    background-color: rgba(64, 96, 125, 0.487);
                    padding: 4px 8px 4px 8px;
                    width: max-content;
                    border-radius: 7px;
                }
                .auth{
                    transition: 0.3s !important;
                    border-color: aliceblue;
                    color: aliceblue;
                    cursor: pointer;
                    background-color: $form_blue-logo;
                }
                .auth:hover{
                    transform: scale(0.95);
                    border-radius: 12px !important;
                }
            }
            p{
                margin-top: 3px !important;
                margin-bottom: 10px !important;
                color: rgba(132, 172, 207, 0.683);
            }
            button{
                background-color: $form_blue-logo;
                border-radius: 10px;
                cursor: pointer;
                border: 2px solid aliceblue;
                padding: 6px;
                font-weight: 600;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                transition: 0.2s;
                color: aliceblue;
            }
            button:hover{
                border-color: rgba(240, 248, 255, 0.693);
                border-radius: 13px;
            }
        }
    }
    .msgUserInfoCard{
        display: flex;
        flex-direction: row;
        margin-left: 13px;
        margin-top: 10px;
        h1{
            color: rgb(246, 251, 255);
            height: max-content;
            margin-bottom: 0px;
            margin-left: 10px;
            font-weight: 500;
            span{
                font-size: 13px;
                font-weight: 400;
                margin-left: 5px;
                color: rgba(146, 174, 198, 0.707);
            }
            font-size: 17px;
            margin-top: 5px;
        }
        img{
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover;
        }
    }


    .chatRoomUserCard{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 14px;
        margin-top: 8px;
        div{
            display: flex;
            flex-direction: row;
        }
        img,h2{
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        img{
            border: 0px solid transparent !important;
            width: 30px !important;
            height: 30px !important;
            min-width: 30px;
            min-height: 30px;
            margin-right: 10px;
            object-fit: cover;
        }
        h2{
            font-size: 15px !important;
            padding: 0px !important;
            width: max-content !important;
        }
    }

    .callUserCard{
        display: flex;        
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
        padding-top: 15px;
        img{
            margin-left: auto;
            margin-right: auto;
            width: 100px;
            height: 100px;
            object-fit: cover !important;
            border-radius: 50%;
        }
        h1{
            color: aliceblue;
            font-size: 20px !important;
            text-align: center;
            font-weight: 500 !important;
            span{
                color: $liveChat-9;
                font-size: 15px !important;
            }
        }
    }
    .liveCallUser{
        display: flex;
        flex-direction: row;
        padding: 5px;
        justify-content: space-between;
        margin-top: -47px;
        position: relative;
        div{
            position: relative;
            display: flex;
            flex-direction: row;   
            justify-content: start;
        }
        p, img{
            margin: 0 auto !important;
        }
        p, span{
            margin-top: auto !important;
            height: max-content;
            margin-bottom: auto !important;
            background-color: rgba(34, 44, 54, 0.565);
        }
        p{
            font-size: 14px;
            font-weight: 600;
        }
        span{
            padding: 7px;
            border-radius: 10px;
            font-size: 16px;
            margin-left: 6px;
            border: 1px solid rgba(240, 248, 255, 0.339);
        }
        .le{
            margin-left: 20px;
        }
        img{
            width: 35px;
            height: 35px;
            margin-right: 3px !important;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .botMsg{
        h1{
            font-size: 16px;
            font-weight: 400 !important;
            margin-left: 15px ;
            color: rgba(185, 210, 231, 0.828);
            small{
                font-size: 17px ;
                margin-right: 12px;
                background-color: $liveChat-2;
                padding: 8px 13px 8px 13px;
                border-radius: 10px;
            }
            span{
                color: white !important;
                font-weight: 600 !important;
            }
            strong{
                background-color: transparent !important;
                font-size: 14px !important;
                margin-left: 7px;
                font-weight: 400 !important;
                color: rgba(146, 174, 198, 0.707);
            }
        }
    }
    .discoverParent{
        width: 86%;
        margin: 0 auto;
        margin-top: auto;
        margin-bottom: auto;
        height: 80vh !important;
        overflow-y: scroll;
        background-color: $liveChat-3;
        border: 2px solid  rgba(240, 248, 255, 0.352);
        border-radius: 22px;

        .templateDiscover{
            display: flex;
            justify-content: center;
            margin-top: 15px;
            margin-bottom: 20px;
            width: 98%;
            max-width: 98%;
            margin-left: auto;
            position: relative;
            margin-right: auto;
            img, video, .videotmplt{
                position: relative;
                border: 5px solid rgba(18, 28, 37, 0.825);
                width: 96%;
                min-height: 360px;
                max-height: 390px;
                object-fit: cover;
                border-radius: 24px;
            }
            .searchDiscover{
                width: 80%;
                position: absolute;
                padding: 25px;
                margin-top: 70px;
                h1, h2, .searchdiv, .button{
                    margin-left: 5px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                h1, h2{
                    width: max-content;
                    max-width: 90%;
          
                    text-align: left;
                    color: white;
                }
                h1{
                    border-radius: 5px;
                    font-size: 30px;
                }
                h2{
                    border-top: 1px solid rgba(255, 255, 255, 0.304);
                    padding-top: 15px;
                    font-weight: 300;
                    font-size: 15px;
                }
                button{
                    display: none;
                    margin-left: 7px;
                    padding: 8px 15px 8px 15px;
                    background-color: rgba(255, 255, 255, 0.214);
                    backdrop-filter: blur(4px);
                    border: 1px solid rgba(255, 255, 255, 0.281);
                    color: white;
                    font-weight: 600;
                    border-radius: 20px;
                }
                .searchdiv{
                    background-color: rgba(181, 220, 255, 0.2);
                    backdrop-filter: blur(3px);
                    cursor: pointer;
                    padding: 8px;
                    border: 2px solid white;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 80%;
                    max-width: 330px;
                    border-radius: 10px;
                    transition: 0.2s;
                    input{
                        font-weight: 600;
                        font-size: 14px;
                        color: white;
                        background-color: transparent;
                        width: 85%;
                        border: 0px;
                    }
                    span{
                        padding: 2px;
                    }
                    input:focus{
                        outline: none;
                    }
                    ::placeholder, span{
                        color: white;
                    }
                }
                .searchdiv:hover{
                    border-radius: 20px;
                }
            }
        
        }
        .discover{
            display: grid;

            margin: 0 auto;
            grid-template-columns: 1fr 1fr 1fr;
            overflow-y: scroll;
            .discoverChannel{
                transition: 0.3s;
                display: flex;
                flex-direction: column;
                height: max-content;
                margin: 10px;
                border-radius: 20px;
                border: 2px solid transparent;
                box-shadow: 0px 3px 0px $forum-bg;
                background-color: $liveChat-4;
                img, video{
                    border-radius: 18px 18px 0px 0px;
                }
                img, video{
                    max-width: 100%;
                }
                video{
                    min-width: 100% !important;  
                }
                img{
                    object-fit: cover !important;
                    background-color: $liveChat-2;
                }
                .channelPic, h1, h2, h3, h4, p{
                    margin-left: 17px;
                    color: $liveChat-6;
                }
                .chanBanner{
                    min-height: 245px;
                    max-height: 260px;
                }
                p{
                    margin-top: 4px;
                    font-size: 13px;
                    color: white;
                }
                h1{
                    color: white;
                    margin-bottom: 0px;
                    font-size: 19px;
                }
                h2{
                    margin-top: 7px;
                    font-weight: 400;
                    margin-right: 8px;
                    font-size: 16px;
                }
                h3{
                    font-size: 15px;
                    font-weight: 400;
                    margin-right: 15px;
                    span{
                        font-size: 10px;
                    }
                    .onln{
                        color: rgb(54, 232, 54);
                    }
                }
                .date{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                h4{
                    margin-top: 7px;
                    width: 85%;
                    text-align: center;
                    padding: 7px;
                    font-size: 16px;
                    font-weight: 500;
                    transition: 0.3s;
                    border-radius: 16px;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $liveChat-2;
                    border: 2px solid aliceblue;
                    color: aliceblue;
                    user-select: none;
                }
                .joinChannel{
                    cursor: pointer;
                    background-color: $form_blue-logo;
                }
                .joinChannel:hover{
                    border-radius: 7px;
                    box-shadow: 0px 3px 0px aliceblue;
                }
                .channelPic{
                        border-radius: 10px;
                        margin-top: -32px;
                        border: 4px solid $liveChat-4;
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                }
                .row{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
            .discoverChannel:hover{
                transform: translateY(-3px);
                border-color: $form_blue-logo;
            }
    
        }
    }

    .Discovered{
        width: 90px ;
        min-width: 90px ;
        max-width: 90px ;
        .chatRooms{
            display: none !important;
        }
        .channels{
            width: 100% !important;
        }  
    }
}

@media screen and  (max-height: 940px){
    .UserCardMenuParent{
        top: 340px !important;
    }
}

@media screen and (min-width: 1100px) and  (max-height: 830px){
    .liveChat_page{
        .right{
            .l{
                .top{
                    min-height: 330px !important;
                    max-height: 330px !important;
                    .sceneBg, video{
                        max-height: 153px !important;
                    }
           
                }

            }
        }
    }
}



@media screen and  (max-height: 810px){
    .UserCardMenuParent{
        top: 250px !important;
    }
}



@media screen and  (max-width: 1394px){
    .liveChat_page{
        .right{
        .similar{
            display: none !important;
        }
        }
    }
}

//// 1300
@media screen and  (max-width: 1300px){
    .liveChat_page{
    .illustration{
        flex-direction: column !important;
        text-align: center;
        
        img, h3{
            margin-left: auto !important;
            margin-right: auto !important;
        }
        h3{
            margin-bottom: 40px !important;
        }
    }
    }
}




@media screen and  (max-width: 1142px){
    .liveChat_page{
    .left{
        max-width: 87px !important;
        min-width: 87px !important;
    }
    .subLiveChat{
        display: flex !important;
        flex-direction: row !important;
        .right{
            width: 100% !important;

            .mid{
                .botMsg{
                    h1{
                        font-size: 14px;
                    }
                    small{
                        padding: 5px 10px 5px 10px;
                        font-size: 17px !important;
                        margin-right: 4px !important;
                    }
                }  
            }
        }
    }
    .chatRooms{
        display: none !important;
    }
    .discoverParent{
        width: 84% !important;
        .templateDiscover{
            width: 100% !important;
            max-width: 100% !important;
            .searchDiscover{
                width: 90% !important;
                .searchdiv{
                    max-width: 270px !important;
                }
            }
            img{
                width: 95% !important;
                max-width: 95% !important;
            }
        }
    }
    }
}

@media screen and  (max-width: 1100px){
    .discover{
        grid-template-columns: 1fr 1fr !important;
    }
}

@media screen and  (max-width: 900px){
    .liveChat_page{
   .right{
    .B{
        .l{
            min-width: 95% !important;
            width: 95% !important;
            overflow-x: scroll !important;
        }
        .r{
            min-width: 200px !important;
            width: 200px !important;
        }
    }
   }
    }
}

@media screen and  (max-width: 800px){
    .discoverParent{
        width: 81% !important;
    } 
}
@media screen and  (max-width: 760px){
  .searchDiscover{
    margin-top: 32px !important;
  }
}
@media screen and  (max-width: 715px){
    .subMenu{
        .rww{
            i{
                font-size: 15px !important;
                padding-left: 4px !important;
                padding-right: 4px !important;
                border-radius: 4px !important
            };
        }
    }
}



@media screen and  (max-width: 700px){
    .liveChat_page{
    .subLiveChat{
        display: flex !important;
        flex-direction: row !important;
    }
    .right{
        .mid{
            .botMsg{
                strong{
                    font-size: 11px !important;
                }
            }  
        }
    }
    }
}

@media screen and  (max-width: 660px){
    .liveChat_page{
    .discoverParent{
        width: 76% !important;
    }
    .discover{
        grid-template-columns: 1fr !important;
    }
    }
}

@media screen and  (max-width: 683px){
    .liveChat_page{
    .left, .Discovered{
        min-width: 67px !important;
        max-width: 67px !important;
        .channels{
            padding-left: 3px !important;
            min-width: 60px !important;
            max-width: 60px !important;
            padding-right: 3px !important;
            img, button{
                margin-top: 8px;
                width: 49px !important;
                height: 49px !important;
                min-height: 49px !important;
                min-width: 49px !important; 
                border-radius: 12px !important;
            }
        }
        ::-webkit-scrollbar {
            display: none !important;
          }
          -ms-overflow-style: none !important;  /* IE and Edge */
          scrollbar-width: none !important;  /* Firefox */
    }
    }

}


@media screen and  (max-width: 648px){
    .liveChat_page{
        .illustration{
            h1{
                font-size: 24px !important;
            }
            h4{
                margin-top: 0px !important;
            }
            h2{
                font-size: 16px !important;
            }
        }
        .right{
                .fadeTop{
                    .descDiv{
                        .channelPictu{
                            margin-top: -50px !important;
                        }
                    }
                 }
            .top{
                padding-left: 4px !important;
                padding-right: 4px !important;
                margin-left: 6px !important;
                margin-right: 6px !important;
                .descDiv{
                    margin-left: 16px !important;
                }
                h4{
                    margin-left: 6px !important;
                    font-size: 10px !important;
                }
                .add{
                    font-size: 15px !important;
                }
            }
            .subMenu{
                .rww{
                    h3{
                        font-size: 14px !important;
                        padding-left: 8px !important;
                        padding-right: 8Px !important;
                    }
                    i{
                        padding-left: 3px !important;
                        padding-right: 3px !important;
                        border-radius: 4px !important
                    };
                }
            }
        }
    }

}

@media screen and  (max-width: 648px){
    .liveChat_page{
    .right{
        .subDesc{
            h1{
                font-size: 20px !important;
            }
            h2{
                font-size: 13px !important;
            }
        }
        
        .top{
            overflow-x: hidden;
            h4{display: none !important;}
            min-height: 250px !important;
            max-height: 250px !important;
            margin-left: 9px !important;
            padding-top: 3px !important;
            .channelPictu{
                width: 55px !important;
                height: 55px !important;
           }
           .canvasDiv{
                max-height: 130px !important;
           }
           .sceneBg, video{
            max-height: 130px !important;
            }  
        }

        .mid{
            overflow-x: hidden !important;
            .roomSlot{
                width: 96% !important;
            }
            .botMsg{
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90% !important;
                margin-left: -5px !important;
                h1{
                    font-size: 12px !important;
                }
            }
            p{
                margin-left: 47px !important;
                max-width: 70% !important;
            }
            img{
                width: 37px !important;
                height: 37px !important;
            }
            .messagesContent{
                i, h2,h3{
                    margin-left: 18px !important;
                }
                i{
                    font-size: 27px !important;
                    padding: 9px 14px 9px 14px !important;
                }
                h2{
                    font-size: 22px !important;  
                }
                h3{
                    font-size: 12px !important;
                    span{
                        font-size: 10px !important;
                        border-radius: 5px !important;
                        padding: 4px !important;
                    }
                }
            }
           p{
            font-size: 14px !important;
           }
           .illustration{
                h1, button{
                    font-size: 13px !important;
                }
           }
           .waitingForCall{
            width: 91% !important;
                h1, button{
                    font-size: 12px !important;
                }
                button{
                    width: 84% !important;
                }
           }
        }

        .msgUserInfoCard{
            margin-left: 8px !important;
            
            h1{
                font-size: 15px !important;
            }
            span{
                font-size: 11px !important;
            }
        }
        .topMsg{
            margin-top: -11px !important;
        }
   
        .subMenu{
            .rww{
                h3{
                    padding: 8Px !important;
                    font-size: 16px !important;
                }
            }
        }

 
        .B{
            .l{
                min-width: 99% !important;
                width: 99% !important;
            }
        }
        .bot{
            height: 48px !important;
            min-height: 48px !important;
            .bottomBar{
                height: 37px !important;
            }
            input{
                font-size: 14px !important;
            }
            ::placeholder{
                font-size: 13px!important;
            }
        }
    }
    .searchDiscover{
        h1{
            font-size: 25px !important;
        }
        h2{
            font-size: 13px !important;
        }
    }
    .discoverChannel{
        h3{
            margin-right: 8px !important;
            font-size: 12px !important;
        }
    }
    }
}



@media screen and (max-width: 380px) and  (max-height: 700px){
    .liveChat_home{
    
        .liveChat_page{
            max-height: 564px !important;
            overflow-y: scroll;
            min-height: 564px !important;
            .subLiveChat{
                .right, .left{
                    max-height: 562px !important;
                    min-height: 562px !important;
                    .mid{
                    }
                }
            }
        }
    }
}