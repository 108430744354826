@import '../settings';

.comment_body{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    z-index: 1;
    background-color: rgba(135, 168, 187, 0.137);

}
.comment_modal_card{
    position: absolute;
    top: 25%;
    left: 35%;
    color: $forum-font-white;

    border-radius: 18px;
    border: 1px solid  $forum-font-header;
    background-color: $forum-bg;
    z-index: 2;
    padding: 20px;
    width: 500px;
    img{
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 50%;
    }
    .close_btn{
        background-color: transparent;
        border: 0px solid $form_blue-logo;
        color: $forum-font-white;
        border-radius: 70px;
        font-size: 22px;
        transition: 0.3s;
    }
    .close_btn:hover{
        transform: scale(1.1);
        color: $form_blue-logo;
    }
    .cmnt_modalContent{
        margin-left: 20px;
        padding-top: 30px;
        padding-bottom: 10px;
    
        .top,.bottom{
            display: flex;
            flex-direction: row;
        }
        ///////////////////////////
        .bottom{
            .input_div{
                width: 85%;
            }
            textarea:focus,  select:focus{
                outline: none;
                resize: vertical;
            }
             textarea{
                font-family: $alt-font;
                background-color: $forum-bg;
                border: 2px solid transparent;
                margin-top: 20px;
                font-size: 16px;
                color: $forum-font-header;
                margin-left: 10px;
                white-space: normal;
                overflow-x: hidden;
                max-width: 100%;
                padding: 0px;
                width: 100%;
                height: 50px;
                overflow-wrap: break-word;
    
            }
            ::placeholder{
                color: $forum-font-lightgray;
            }
            .bottom_img:before{
                    content: "";
                    display: block;
                    width: 2px;
                    height: 46px;
                    background: $forum-font-header;
                    left: 13.25%;
                    top: 42.5%;
                    position: absolute;
            }
            
        }
        //////////////////////
        .top{
            .text_cntnt{
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                h1{
                    font-size: 18px;
                    font-weight: 400;
                    oc
                    span{
                        color: $font-grey;
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
                p{
                    margin-top: 0px;
                    color: $forum-font-lightgray;
                    font-weight: 400;
                    span{
                        font-weight: 400;
                        color: $form_blue-logo;
                    }
                }
                h3{
                    margin-top: -5px;
                    font-size: 20px;
                    overflow-x: hidden;
                    max-width: 400px;
                    font-weight: 400;
                }
            }
        }
    }

    .reply_div{
        margin-top: 0px;
        display: flex;
        font-size: 10px;
        justify-content: end;
        flex-direction: row;
        transition: 0.3s;
        button{
            padding: 10px;
            border-radius: 10px;
            font-size: 15px;
            border: 2px solid transparent;
            background-color: $form_blue-logo;
            color: $forum-font-white;
            transition: 0.3s;
        }
        button:hover{
            transform: scale(0.96);
            border-color: $forum-font-header;
        }
        p{
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;
            color: $forum-font-header;
            font-size: 15px;
        }
    }
    .reply_div:hover{
        transform: translateX(-1px);
    }
}