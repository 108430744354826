@import '../settings';

.nm_messages{
    .rtrn{
        transition: 0.2s;
        color: aliceblue;
        cursor: pointer;
        font-size: 21px;
        span{
            transition: 0.2s;
            background: -webkit-linear-gradient(-40deg, #1271e7,  #47aded);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 2px;
        }
    }
    .rtrn:hover{
        span{
            margin-left: 8px;
        }
    }
    .memberTrendCard{
        display: flex;
        flex-direction: row;
        background-color: $forum-light;
        margin-top: 6px;
        transition: 0.2s;
        justify-content: space-between;
        border: 1px solid rgba(207, 232, 255, 0.049);
        cursor: pointer;
        padding: 12px 16px;
        padding-left: 11px;
        border-radius: 12px;
        color: aliceblue;
        img{width: 50px;height: 50px;
            border-radius: 50%;margin-right: 8px;
            object-fit: cover;
        }
        .l{display: flex;flex-direction: row;}
        .texts{
            display: flex;
            flex-direction: column;
        }
        h1,h2, p{
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 15px;
        }
        h1{font-weight:700;}
 
        h2{font-size: 14px;font-weight: 400;color: rgba(217, 237, 255, 0.8);}
        p{font-size: 14px;margin-top: 10px;}
        button{
            margin-top: auto;
            margin-bottom: auto;
            height: max-content;
            padding: 6px 16px;
            border: 2px solid transparent;
            cursor: pointer;
            transition: 0.2s;
            font-weight: 700;
            font-size: 14px;
            border-radius: 15px;
        }
        button:hover{
            border-radius: 10px;
            border-color: $form_blue-logo!important;
            box-shadow: 0px 2px 0px  $form_blue-logo;
            color: $form_blue-logo;
        }
        .followed, .followed:hover{
            color: white;
        }
        .followed{
            background-color: $form_blue-logo;
        }
        .followed:hover{
            border-color: white !important;
            box-shadow: 0px 2px 0px  white;
        }
    }
    .memberTrendCard:hover{
        border-radius: 18px;
        border-color:  $form_blue-logo;
    }
    .trendOptions_{
        margin-top: 7px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        justify-content: space-evenly;
        border-bottom: 1px solid rgba(240, 248, 255, 0.056);
        h3{
            font-size: 15px;
            color: rgb(218, 238, 255);
            font-weight: 400;
            transition: 0.2s;
            cursor: pointer;
            margin-bottom: 0px;
            padding-bottom: 10px;
            border-bottom: 2px solid transparent;
        }
        h3:hover{
            border-color: rgba(167, 214, 255, 0.138);
        }
        .selected_o, .selected_o:hover{
            font-weight: 700;
            color: white;
            border-color: $form_blue-logo;
        }
    }
    .searchRtrn{
        display: flex;flex-direction: row;justify-content: space-between;
        overflow: hidden;
        .xtst{display: flex;flex-direction: column;}
        .srchTrend{
            display: flex;
            flex-direction: row;
            background-color: $forum-light;
            border: 2px solid rgba(170, 215, 255, 0.157);
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 8px;
            margin-left: 20px;
            padding-right: 8px;
            transition: 0.2s;
            border-radius: 30px;
            width: 250px;
            height: 42px;
            input,p{
                margin-top: auto;
                margin-bottom: auto;
                color: aliceblue;
            }
            input{border: none; background-color: transparent;max-height: 42px; padding: 12px;}
            input:focus{outline: none; border-color: $form_blue-logo !important;}
            input, ::placeholder{color: aliceblue; font-size: 15px;}
            p{padding-left: 12px;cursor: pointer;padding-right: 4px;}
        }

        .srchTrend:hover{
            border-color: $form_blue-logo;
            border-radius:  14px;
        }
        margin-top: 15px;
        margin-bottom: 10px;
        h1, h2{
            margin-top: auto;
            margin-bottom: auto;
        }
        h1{
            cursor: pointer;
            transition: 0.2s;
            font-size: 24px;
            min-width: 120px;
            color: $form_blue-logo;
        }
        h1:hover{
            transform: translateX(3px) !important;
        }
        h2{
            font-size: 24px;
            color: $forum-font-white;
        }

   
    }
    
    .messagesThread{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: max-content;
        border-bottom: 1px solid $forum-light;
        h2{
            font-size: 18px;
            cursor: pointer;
            transition: 0.3s;
            border-bottom: 3px solid transparent;
            padding-bottom: 10px;
            margin-bottom: 0px;
            margin-top: 20px;
            color: $forum-font-white;
        }
        h2:hover, .active{
            border-color: $form_blue-logo;
        }
    }

    .loadprnt{
        min-width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        .lds-dual-ring{
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
    
}
