@import '../settings';

.body_home_alt{
    @include body_alt_class($forum-bg);
    overflow-y: scroll;
    font-family: $alt-font;
}

.header_home{
	background-color: $forum-bg;
    position: sticky;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    z-index: 1;
}
.homepage{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 0.575fr 1fr 0.575fr;

   .left_side, .right_side{
    display: flex;
    justify-content: center;
    overflow-y: scroll;
   }
   .left_side{
        .newNitrochatt{
            padding: 8px;border: 3px solid rgba(205, 232, 255, 0.505);
            border-radius: 15px;background-color: $form_blue-logo;
            font-weight: 600;color: white;
            margin-top: -5px;
            width: 95%;margin-left: auto;margin-right: auto;min-width: 95%;
            font-size: 16px;transition: 0.2s;cursor: pointer;
        }.newNitrochatt:hover{
            border-color: rgba(250, 253, 255, 0.65) ;box-shadow: 0px 3px 0px $form_blue-logo ;
            border-radius: 10px ;
        }
   }
    .middle_page{
        z-index: 0;
        position: relative;
        img{
          width: 100px;
        }
    }
    .side_content{
        position: fixed;
        display: flex;
        flex-direction: column;
        width: max-content;
   }
   .hidden{
     display: none !important;
   }
}
.liveChat_home{
    max-width: 1390px;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;
}

.message_homepage{
    grid-template-columns: 0.8fr 2fr;
}

    .message_fix{
        max-width: 98% !important;
        width: 98% !important;
        min-width: 98% !important;
    }
.loading_logo{
    @include body_alt_class($forum-bg);
    span{
        position: absolute;
        color: $form_blue-logo;
        font-size: 3rem;
        top: 50%;
        left: 48%;
    }
}

@media screen and  (max-width: 1330px){
    .middle_page{
        margin-left: auto;
        margin-right: auto;
        width: 94% !important;
        max-width: 94% !important;
    }
    .right_side,.left_side{
        .whole_trends_card, .profile_card_content, .who_follows_you{
            max-width: 300px !important;
        }
        .mediasTrend, .userPrntTrend{
            max-width: 275px !important;
        }
    }
    .right_side{
        margin-left: -30px;
    }
    .left_side{
        margin-left: 30px;
    }


    .message_homepage{///////{//////////////{//////////////{//////////////
        .middle_page{
            max-width: 100% !important;
        }
        .left_side{
            margin-left: 0px;
        }
    }
}


@media screen and  (max-width: 1210px){
    .middle_page{
        margin-left: auto;
        margin-right: auto;
        max-width: 92% !important;
    }
    .right_side,.left_side{
        .whole_trends_card, .profile_card_content, .who_follows_you{
            max-width: 280px !important;
        }
        .mediasTrend, .userPrntTrend{
            max-width: 260px !important;
        }
    }
    .right_side{
        margin-left: -40px;
    }
    .left_side{
        margin-left: 40px;
    }

    .message_homepage{///////{//////////////{//////////////{//////////////
        .middle_page{
            max-width: 100% !important;
        }
        .left_side{
            margin-left: 0px;
        }
    }
}
@media screen and  (max-width: 1210px){


    .message_homepage{///////{//////////////{//////////////{//////////////
        .left_side{
            margin-left: -10px ;
        }
    }
}


@media screen and  (max-width: 1166px){

    .right_side{
        margin-left: -40px ;
    }
    .left_side{
        margin-left: 40px;
    }

    .message_homepage{///////{//////////////{//////////////{//////////////
        .left_side{
            margin-left: -10px ;
        }
    }
}


@media screen and  (max-width: 1125px){
    .middle_page{
        max-width: 90% !important;
    }

    .whole_trends_card, .profile_card_content, .who_follows_you, .mediasTrend, .userPrntTrend{
        min-width: 250px !important;
         max-width: 250px !important;
     }


     .message_homepage{///////{//////////////{//////////////{//////////////
        .left_side{
            margin-left: -10px ;
        }
    }
 }

 @media screen and  (max-width: 1070px){

    .right_side{
        margin-left: -25px !important;
    }
    .left_side{
        margin-left: 25px !important;
    }

    .message_homepage{///////{//////////////{//////////////{//////////////
        .left_side{
            margin-left: -10px !important;
        }
    }
}

@media screen and  (max-width: 1042px){
    .whole_trends_card{
        max-width: 150px !important;
    }
    .message_homepage{///////{//////////////{//////////////{//////////////
        .left_side{
            display: none !important;
        }
        .middle_page{
            margin-left: 60px !important;
        }
        grid-template-columns: 1fr !important;
    }
}



/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 1030px){
    .homepage{
        grid-template-columns: 0.575fr 1fr !important;
    }
    .left_side{
        margin-left: 0px !important;
        .profile_card_content, .who_follows_you{
            max-width: 320px !important;
        }
    }
    .middle_page{
        min-width: 97%;
        max-width: 100% !important;
        margin-left: -5px !important;
    }
    .right_side{
        display: none !important;
    }
    .message_homepage{///////{//////////////{//////////////{//////////////
        grid-template-columns: 1fr !important;
        .middle_page{
            max-width: 95% !important;
            margin-left: 40px !important;
        }
    }
}



/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 984px){
    .left_side{
        min-width: 360px !important;
        max-width: 360px !important
    }
    .middle_page{
        max-width: 97% !important;
        min-width: 97% !important;
    }
    .message_homepage{///////{//////////////{//////////////{//////////////
        grid-template-columns: 1fr !important;
    }
}

/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 876px){
    .left_side{
        .profile_card_content, .who_follows_you{
            max-width: 260px !important;
            margin-left: -60px !important;
        }
        .newNitrochatt{
            width: 240px !important;
            margin-left: -50px !important;
        }
    }
    .middle_page{
        max-width: 111% !important;
        min-width: 111% !important;
        margin-left: -65px !important;
    }

    .message_homepage{///////{//////////////{//////////////{//////////////
        grid-template-columns: 1fr !important;
        overflow: hidden;
        .middle_page{
            max-width: 96% !important;
            min-width: 96% !important;
            margin-left: 40px !important;
        }
    }
}


/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 790px){

    .middle_page{
        max-width: 114% !important;
        min-width: 114% !important;
        margin-left: -69px !important;
    }
}

/// BREAKPOINT NO TRENDS - - - - -
@media screen and  (max-width: 790px){
    .homepage{
        grid-template-columns: 1fr !important;
    }
    .left_side{
        display: none !important;
    }
    .middle_page{
        max-width: 95% !important;
        min-width: 95% !important;
       margin-left: auto !important;
       margin-right: auto !important;
    }
}


