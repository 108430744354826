@import '../settings';

.settingsWindow{
    width: 95%;
    max-width: 95%;
    height: 100%;
    max-height: 100%;
    margin: 0 auto !important;
    margin-top: 10px !important;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: $liveChat-5;
    display: flex;
    flex-direction: column;
    .pic_upload{
        display: none;
    }
    h1{
        font-size: 22px;
        color: white;
        font-weight: 500;
        margin-left: 30px;
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .w{
        color: white !important;
    }
    h2{
        margin-top: 0px !important;
        margin-left: 0px !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        color: $liveChat-9 !important;
    }
    p{
        padding: 0 !important;
        font-weight: 300;
        font-size: 14px !important;
        color: rgba(229, 243, 255, 0.851)!important;
        margin: 0 !important;
    }
    strong{
        position: fixed;
        padding: 7px 11px 7px 11px;
        margin-left: 97px;
        border-radius: 50%;
        margin-top: -10px;
        font-size: 18px;
        background-color: aliceblue;
        color: $liveChat-3;
        border: 2px solid $liveChat-3;
    }
    span, p, h1, h2, img, button{
        user-select: none;
    }
    button{
        border-radius: 4px;
        margin-top: 15px;
        font-size: 14px;
        width: 85%;
        color: white;
        transition: 0.3s;
        background-color: transparent;
        padding: 10px 20px 10px 20px;
        border: 1px solid rgb(66, 77, 87);
    }
    button:hover{
        background-color: $liveChat-1;
    }
    input{font-family: $alt-font;}
    input{
        padding: 12px;transition: 0.2s;
        border-radius: 7px;
        border: 2px solid transparent;
        color: aliceblue;
        font-size: 14px;
        background-color: $forum-bg;
        margin-bottom: 20px;
    }
    input:focus{
        outline: none;
        border-radius: 13px;
    }
    input:hover, input:focus{
        border-color: aliceblue;
    }
    ::placeholder, input{
        color: $liveChat-10;
    }
    .settings_mid, .settings_top, .settings_bot {
        max-width: 100%;
        border-bottom: 2px solid $liveChat-1 !important;
        margin: 20px 30px 30px 30px;
    }

    .settings_top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 50px;
        border-bottom: 1px solid white;
    }
    .clmn{
        width: 47%;
        display: flex;
        flex-direction: row;
    }
    .rw{
        display: flex;
        flex-direction: column;
        width: 60%;
    }
    .imgs{
        width: max-content;
        margin-right: 30px;
    }
    .settings_mid{
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;

        button{
            font-weight: 600 !important;
        }
    }
    .channelPic, .channelBnr{
        object-fit: cover;
        border-radius: 15px;
    }

    .channelPic{
        transition: 0.3s;
        width: 110px; min-width: 75px;
        padding: 6px;
        border: 2px solid $liveChat-1;
        height: 110px;min-height: 75px;
    }
    .channelPic:hover{
        transform: scale(0.96);
    }
    .rght{
        flex-direction: column;
    }
    .saveRow{
        display: flex;
        flex-direction: row;
        .save{
            margin-top: 0 !important;
            padding: 10px !important;
            width: max-content;
        }
        h2{
            margin-top: auto !important;
            margin-bottom: auto !important;
            height: max-content;
            margin-right: 7px;
            color: aliceblue !important;
            font-size: 13px !important;
        }
        button{
            background-color: $form_blue-logo;
            margin-left: 20px;
            font-size: 11px;
        }
        button:hover{
            border-radius: 20px;
            border-color: aliceblue;
        }
    }


    h3:hover, .leave:hover{
        border-color: transparent;
        background-color: $form_blue-logo !important;
    }

      
    .lftcolm{
        height: max-content;
        margin-top: auto;
        margin-bottom: auto;

        small{
            text-align: center;
            font-size: 14px;
            padding-top: 6px;
            color: rgba(229, 243, 255, 0.851)!important;
        }
        button {
            font-size: 13px;
        }
    }
    .bannerClmn{
        display: flex;
        flex-direction: column;
        width: 100%;
        .channelBnr{
            padding: 4px;
            border: 2px solid $liveChat-1;
            min-width: 99%;
            max-width: 99%;
            margin-left: auto;
            margin-right: auto;
            min-height: 250px;
            max-height: 300px;
        }
        h2{
            font-size: 18px !important;
            margin-left: 4px !important;
            color: white !important;
        }
    }
    .bannerDesc{
        font-size: 16px !important;
        margin-top: 12px !important;
    }
    .bannerBtn{
        transition: 0.3s;
        width: 100% !important;
        border: 2px solid white;
        background-color: $form_blue-logo !important;
    }
    .bannerBtn:hover{
        border-radius: 20px !important;
    }




    .settings_bot{

        h2{
            font-size: 19px !important;
            color: white !important;
        }
        .h2title{
            margin-top: 15px !important;
            margin-bottom: 8px !important;
        }
        .ttle{
            margin-top: 20px !important;
            margin-bottom: 20px !important;
        }
        .searchRole, .role_row, .defaultRole, .rww{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        button{
            width: max-content;
            transition: 0.2s;
            border: 2px solid transparent;
            background-color: $form_blue-logo;
        }
        button:hover{
            border-color: aliceblue;
            box-shadow: 0px 2px 0px aliceblue;
        }
        button, input{
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        input {
            width: 75%;
            font-size: 17px !important;
            font-weight: 400 !important;
            border: 2px solid rgb(64, 82, 97);
            padding: 9px !important;
            transition: 0.2s;
        }
        ::placeholder{
            font-size: 15px !important;
        }
        input:focus, input:hover{
            box-shadow: 0px 3px 0px rgb(64, 82, 97);
        }
        .defaultRole{
            border-radius: 5px;
            background-color: rgb(26, 33, 40);
            padding: 10px;
            margin-bottom: 25px;
            height: 50px;
            .rww, h4, h2{
                height: max-content;
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
            h4{
                background-color: $liveChat-5;
                padding: 14px 14px 14px 14px;
                text-align: center;
                font-size: 16px;
                border-radius: 50%;
            }
            h2{
                margin-left: 14px !important;
                font-size: 15px !important;
                color:  rgba(229, 243, 255, 0.851) !important;
                span{
                    font-size: 12px !important;
                    font-weight: 300 !important;
                }
            }
            p{
                font-size: 20px !important;
                margin-right: 15px !important;
            }
        }
        .defaultRole:hover{
            background-color: rgb(53, 66, 77);
        }
        .role_row{
            width: 100%;
            height: 60px;
            transition: 0.3s;
            border-radius: 3px;
            border-bottom: 1px solid $liveChat-1;
            p{
                color: $liveChat-9 ;
                font-size: 15px !important;
                margin-top:  auto !important;
                margin-bottom: auto !important;
            }
            p:hover{
                background-color: transparent !important;
            }
            span{
                margin-top: auto;
                font-size: 16px;
                margin-bottom: auto;
            }
            .roleName{
                width: 200px ;
                max-width: 200px ;
                min-width: 200px;
                font-weight: 500 !important;
            }
            .roleCount{
                width: 200px;
                min-width: 200px;
            }
            .eye{
                background-color: rgb(26, 33, 40);
                font-size: 16px !important;
                padding: 10px 12px 10px 12px !important;
                border-radius: 50% !important;
                border: 2px solid transparent;
                transition: 0.2s;
            }
            .eye:hover{
                border: 2px solid aliceblue;
            }
        }
        .role_row:hover{
            background-color: rgb(29, 40, 47);
        }
        .mx{
            margin-top: 30px !important;
            height: max-content !important;
            h2{
                font-size: 13px !important;
                color: $liveChat-9 !important;
                font-weight: 500 !important;
            }
            .one{
                margin-right: 35px !important;
            }
        }
        .mx:hover{
            background-color: transparent;
        }


        .roleCreation{
            display: flex !important;
            flex-direction: row !important;
            padding-bottom: 40px;
            height: 600px;
            ::-webkit-scrollbar {
                display: none !important;
            }
            .saveChannelSettings{
                display: block;
                font-weight: 600 !important;
                width: 90%;
                transition: 0.3s;
                font-size: 13px !important;
                margin-left: auto !important;
                border: 2px solid rgba(240, 248, 255, 0.476);
                margin-right: auto !important;
            }
            .saveChannelSettings:hover{
                border: 2px solid aliceblue;
            }
            .menu{
                width: 180px !important;
                display: flex !important;
                flex-direction: column !important;
                justify-content: start !important;
                border-right: 2px   solid  $liveChat-1;

                .return_role{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-right: 10px;

                    h2:hover{
                        color:  aliceblue !important;
                    }
                }

                h1{
                    font-size: 17px !important;
                    margin-top: 0px !important;
                    margin-bottom: 25px !important;
                    margin-left: 0px !important;
                }

                h2{
                    color: $liveChat-10 !important;
                    font-size: 17px !important;
                }

                h5{
                    color: aliceblue !important;
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    border: 1px solid transparent;
                    margin-top: 0px;
                    border-radius: 7px;
                    cursor: pointer;
                    padding: 8px 12px 8px 12px;
                    transition: 0.3s;
                    margin-right: 10px;
                    margin-bottom: 3px !important;
                }
                h5:hover{
                    border-color: rgb(46, 57, 65);
                }
                .selectedRole, .selectedRole:hover{
                    background-color: rgb(46, 57, 65);
                }
            }


    .displayRole{
                margin-left: 20px;
                overflow-y: scroll;
                margin-right: 20px;
              
                width: 70%;
                .setting{
                    margin-bottom: 30px;
                    width: 100%;
                    .colorStg{
                        transition: 0.3s !important;
                        box-shadow: 0px 0px 15px $forum-bg;
                        border: 2px solid white;
                    }
                    .actualColor, .defaultColor{
                        width: 15%;
                        border-radius: 7px;
                        height: 50px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                    .actualColor{
                        transition: 0.3s !important;
                        border: 2px solid white;
                        p{
                            position: relative;
                            color: aliceblue !important;
                            margin-top: 3px !important;
                            margin-left: 3px !important;
                            font-size: 18px !important;
                        }
                    }
                    .defaultColor{
                        margin-left: 8px;
                        background-color: #84a5bf;
                        p{
                            position: relative;
                            margin-top: 50px !important;
                            text-align: center !important;
                            margin-left: 15px !important;
                            font-size: 12px !important;
                        }
                    }
                    .colorPickers, .hexClr{
                        display: flex;
                        flex-direction: row;
                    }
                    .hexClr{
                        h4{
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                        input {
                            width: 50%;
                            margin-left: 10px;
                            font-size: 14px;
                            padding: 5px !important;
                            background-color: $liveChat-1;
                            border: 1px solid $liveChat-8;
                        }
                    }
                    .colorPickers{
                        height: max-content;
                        margin-bottom: 10px;
                        input{
                            display: none !important;
                        }
                        .issou{
                            border: 1px solid white;
                            background-color: transparent!important;
                            box-shadow: 0px 0px 0px !important;
                            padding: 0px !important;
                            margin: 0px !important;
                            margin-left: 5px  !important;
                            div{
                                div:hover{
                                    transform: scale(0.95);
                                }
                            }
                        }
                     
                    }
                    h4{
                        margin-bottom: 4px;
                        color: $liveChat-9;
                        padding: 5px 10px 5px 10px;
                        border-radius: 6px;
                        width: max-content;
                        background-color: $liveChat-1;
                    }

                    .perm{
                        padding-top: 20px;
                        padding-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        border-bottom: 1px solid $liveChat-1 ;
                        small{
                            color: $liveChat-9 !important;
                            font-size: 14px !important;
                            font-weight: 300 !important;
                            margin-top: 15px !important;
                        }
                        
                        .setting_rw{
                            display: flex;
                            flex-direction: row !important;
                            justify-content: space-between;
    
                            p{
                                font-size: 17px !important;
                                font-weight: 500 !important;
                                color: aliceblue !important;
                            }
    
                        }
                    }
                }
                     
            .add_setting{
                min-height: 400px;
                .add_rw{
                    margin-bottom: 6px !important;
                    input, button{
                        margin-top: auto !important;
                        margin-bottom: auto !important;
                    }
                    input {
                        font-size: 15px !important;
                        width: 59% !important;
                        border: 1px solid rgba(240, 248, 255, 0.269);
                    }
                    button{
                        border: 1px solid aliceblue !important;
                        width: 31% !important;
                        font-size: 12px !important;
                        font-weight: 600 !important;
                        padding: 8px 10px 8px 10px !important;
                    }
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 20px !important;
         
                }
                p{
                    font-size: 12px !important;
                }
                p:hover{
                    background-color: transparent !important;
                }
            }
            .added_members{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 10px;
                small{
                    height: max-content;
                    background-color: $liveChat-1;
                    border-radius: 10px;
                    font-size: 13px;
                    font-weight: 500;
                    border: 2px solid transparent;
                    margin-right: 10px;
                    margin-top: 6px;
                    transition: 0.3s;
                    padding: 7px;
                }
                small:hover{
                    border-color: aliceblue;
                }
            
            }
           
            .deleted{
                small{
                    border-color: rgba(255, 94, 94, 0.611);
                }
            }
            .new_mbmr{
                margin-top: 30px !important;
            }
            .add_user_row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 6px;
                transition: 0.3s;
                div{
                    display: flex;
                    flex-direction: row;
                }
                img{
                    width: 30px;
                    height: 30px !important;
                    object-fit: cover !important;
                    border-radius: 50%;
                    border: 1px solid rgb(72, 91, 108);
                }
                small, p {
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                }
                p{
                    margin-left: 7px !important;
                    span{
                        font-weight: 600 !important;
                        color: aliceblue !important;
                    }
                }
                p:hover{
                    background-color: transparent !important;
                }
                small{
                    cursor: pointer;
                    transition: 0.3s;
                    margin-right: 3px !important;
                    padding: 7px 12px 7px 12px;
                    border-radius: 7px;
                    word-spacing: 2px;
                    background-color: $liveChat-1;
                }
            }
            .add_user_row:hover{
                background-color: $liveChat-1;
                small{
                    word-spacing: 4px;
                    background-color: rgb(35, 44, 52);
                }
            }

            button{
                margin-bottom: 8px !important;
            }
        }
      
    }
  
    }

    .ban_row{
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        .ban_illustration{
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            text-align: center !important;
            img{
                width: 250px;
                border-radius: 30px;
                padding: 10px 5px 10px 5px;
                background-color: rgb(29, 39, 47);
            }
            p{
                margin-top: 20px !important;
                font-size: 17px !important;
                font-weight: 600 !important;
            }
            h4{
                font-size: 15px !important;
                font-weight: 300 !important;
            }
            p, h4, img{
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }

        .ban_list{
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            .banRow{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                background-color: rgba(71, 97, 120, 0.164);
                padding: 5px 12px 5px 12px;
                border-radius: 12px;
                margin-bottom: 7px;
                div{
                    display: flex;
                    flex-direction: row;  
                }
                img{
                    width: 40px;
                    object-fit: cover;
                    height: 40px;
                    margin-right: 6px;
                    border-radius: 50%;
                }
                h2{
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                    font-size: 17px !important;
                    span{
                        font-size: 14px;
                        color: $liveChat-9;
                        font-weight: 400;
                    }
                }

                .unbn{
                    transition: 0.2s;
                    padding: 7px 12px 7px 12px;
                    border-radius: 14px;
                    font-size: 14px !important;
                    border: 2px solid rgba(127, 151, 173, 0.5);
                    background-color: rgb(34, 46, 56);
                }
                .unbn:hover{
                    border-radius: 6px;
                    border-color: rgb(80, 201, 253);
                }
            }
        }
    }
   
}


@media screen and  (max-width: 1462px){
    .settings_bot{
        .searchRole{
            input{
                width: 65% !important;
            }
        }
    }
}
@media screen and  (max-width: 1300px){
    .settings_top{
        .clmn{
            width: 42%;
        }

    }
}

@media screen and  (max-width: 1140px){
    .settings_top{
        .clmn{
            width: 45%;
        }

    }
}

@media screen and  (max-width: 795px){
    .settings_top{
        flex-direction: column !important;
        .clmn{
            width: 100%;
        }
        .lftcolm{
            margin-bottom: 20px !important;
        }
    }
}

@media screen and  (max-width: 742px){
    .settings_bot{
        .searchRole{
            input{
                width: 62% !important;
            }
            button{
                font-size: 12px !important;
                padding: 3px 6px 3px 6px;
            }
        }
    }
}

@media screen and  (max-width: 694px){
    .settingsWindow{
        .defaultRole{
            h2{
                font-size: 12px !important;
            }
        }
        .roleName{
            font-size: 14px !important;
            width: 100px !important;
            max-width: 100px !important;
            min-width: 100px !important;
        }
        .roleCount{
            width: 60px !important;
            min-width: 60px !important;
        }
    } 
}
@media screen and  (max-width: 648px){
    .settingsWindow{
        .lftcolm{
            margin-top: 12px;
            justify-content: space-between;
        }
        strong{
            margin-left: 66px !important;
            margin-top: -12px !important;
        }
        .lftcolm{
            p{margin-left: 0px !important;}
        }
    .settings_mid, .settings_top, .settings_bot {
        margin: 10px 16px 16px 16px !important;
    }

}
}
