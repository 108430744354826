@import '../settings';

.fadeActive{
  .profile_page_top{
  }
}
.profile_page_container{
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding-bottom: 20px;

  
  .fadeTop{
    border: 0px  !important;
    padding: 4px;

    .profile_page_subtop{
      margin-top: -5px;
      background-color: rgba(0, 0, 0, 0.2);

      h1, .bio, h3 > span, .followed{
        color: white !important;
      }
      h2, h3, p{
        color: rgba(211, 235, 255, 0.876)!important;
      }
      .subtop_right{
        .wr{
          color: $forum-bg !important;
        }
      }
    }
    .profile_picture{
      background-color: rgba(255, 255, 255, 0.26) !important;
    }
    .buttons_flex{
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 25px 25px;
      border-top: 2px solid rgba(255, 255, 255, 0.1);
      div{border-radius: 0px !important;}
      span{
        color: rgba(211, 235, 255, 0.876)!important;
      }
      .active, div:hover{ background-color: transparent!important;span{color: white !important;}}
      .left{ border-radius:  0px 0px  0px  25px !important;}
      .right{ border-radius: 0px 0px  25px  0px !important;}
    }

  }


  
  .profile_page_top{
    background-color: $forum-light !important;
    border: 1px solid rgba(129, 196, 255, 0.1);
    border-radius: 25px;
    .profile_banner{
      width: 100%;
      max-height: 195px;
      min-height: 195px;
      object-fit: cover;
      border-radius: 25px 25px 0px 0px;
    }


    .profile_page_subtop{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      color: $forum-font-lightgray;
      padding-bottom: 15px;
      h1, h2{
        white-space: normal;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      h1{
        max-width: 250px;
        color: $header_white_alt;
        margin-top: 10px;
        font-size: 26px;
        margin-bottom: 0px;
      }
      h2{
        font-size: 17px;
        width: 150%;
        font-weight: 200;
        margin-top: 3px;
      }
      p{
        margin-bottom: 0px;
      }
      h3{
        font-size: 15px;
        font-weight: 400;
        transition: 0.3s;
        margin-right: 5px;
        user-select: none; /* Standard syntax */
        span{
          font-weight: 600;
          color: $forum-font-header;
        }
        .right_span{
          margin-left: 10px;
        }
      }
      h4{
        color: $forum-font-header;
        margin-top: 10px;
        margin-bottom: 0px;
        span{
          background: -webkit-linear-gradient( #1271e7,  #47aded);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .channelStats{
        font-size: 14px;
      }
      h6{
        font-size: 15px;
        margin-top: 12px;
        margin-bottom: 0px;
        span{
          text-decoration: underline;
          color: aliceblue ;
        }
        a{
          font-weight: 400;
          margin-left: 6px;
        }
      }
      h6:hover{
        span{
          text-decoration: none;
          background: -webkit-linear-gradient( #1271e7,  #47aded);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .relationsRowProfile{
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        width: 150%;
        img{
          max-height: 28px;max-width: 28px;
          min-width: 28px; min-height: 28px;
          object-fit: cover;
          margin-right: 3px;
          border-radius: 50%;
          transition: 0.2s;
          border: 1px solid $forum-light;
        }
        img:hover{
          border-color: $form_blue-logo;
        }
        .rwimg{
          cursor: pointer;
          margin-right: -8px;
        }
        img, h6{
          margin-top: auto;
          margin-bottom: auto;
        }
        h6{
          font-size: 12px;
          margin-right: 4px;
          color: rgba(189, 224, 255, 0.621);
          font-weight: 400;
          strong{
            color: aliceblue;
            font-weight: 700;
          }
        }
      }
      
      .subtop_left{
            .follows{
              margin-top: 0px;
              padding-top: 0px;
              display: flex;
            }
            h3:hover{
              color: $forum-font-header;
              transform: translateX(1px);
            }
            .bio{
              color: $forum-font-white;
              font-size: 15.5px;
              max-width: 100%;
              font-weight: 400;
            }
            p{
              font-size: 15px !important;
            }
            .city{
              margin-top: 0px;
            }
            .profile_picture{
              border-radius: 50%;
              width: 140px;
              height: 140px;
              object-fit: cover;
              margin-top: -80px;
              padding: 0px;
              background-color: $forum-light;
              border: 3px solid $forum-light;
            }
      }
      .subtop_right{
        position: relative;
            display: flex;
            margin-top: 0px;
            h4{
              padding: 10px 23px 10px 23px;
              margin-top: 7px;
              margin-right: 15px;
              background-color: $forum-font-white;
              border-radius: 20px;
              color: $forum-bg;
              width: max-content;
              height:max-content;
              font-weight: 400;
              border: 2.5px solid $forum-font-white;
              transition: 0.3s;
              cursor: pointer;
            }
       
            .followed{
              background-color: $form_blue-logo;
              color: $forum-font-white;
            }
            .followed:hover{
              border-color: $forum-bg;
              color: $forum-bg;
              box-shadow: 0PX 2PX 0PX $forum-bg;
            }
            h4:hover{
              box-shadow: 0PX 2PX 0PX $form_blue-logo;
              border-color: $form_blue-logo;
              color: $form_blue-logo;
              border-radius: 15px;
            }
            .bell{
              width: 40px;height: 40px;display: flex; margin-top: 6px;margin-right: 13px;
              border-radius: 18px;background-color: $form_blue-logo;cursor: pointer;
              border: 2px solid aliceblue;position: relative;transition: 0.2s;
              h4, h4:hover{
                padding: 0px !important; margin: 0 auto !important;text-align: center !important;
                margin-top: auto !important;margin-bottom: auto !important;font-size: 20px;box-shadow: 0px 0px 0px;
                color: aliceblue;background-color: transparent;border: 0px solid transparent;
              }
              span{display: none !important; position: absolute; font-size: 9px;margin-left: 28px;color: $forum-bg;margin-top: 6px;}
            }
        
            .bell:hover{
              border-color: rgba(222, 240, 255, 0.5) !important;
              border-radius: 13px;
            }
            .bell:active{
              transform: scale(0.9);
            }
            .maskedBell{
              background-color: $forum-font-white!important;border-color: rgba(119, 159, 193, 0) !important;h4{color: $forum-bg !important;}
            }
            .maskedBell:hover{
              border-color: rgb(119, 158, 193) !important;
            }

        .nitro_fd{
          bottom: 0px;
          right: 20px;
          position: absolute;
          border-radius: 14px;
          padding: 8px 12px;
          border: 2px solid rgba(180, 220, 255, 0.347);
          background-color: rgb(94, 217, 255);
          background: linear-gradient(
            -45deg,
            #5869ff,
            #112de0,
            rgb(87, 202, 255),
            #112de0,
            #5869ff,
          );
          animation: fadeGradient 7s ease infinite;
          background-size:400% 400%;
          @keyframes fadeGradient {
              0% {
                  background-position: 0% 50%;
              }
              50% {
                  background-position: 100% 50%;
              }
              100% {
                  background-position: 0% 50%;
              }
          }
          h5{
            font-size: 13px;
            color: rgb(221, 239, 255);
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }
  }

    .buttons_flex{
      display: flex;
      width: 100%;
      flex-direction: row;
      div{
        text-align: center;
        padding: 13px;
        width: 100%;
        margin-right: 10px;
        transition: 0.3s;
        cursor: pointer;
        border-radius:  15px 15px  0px 0px;
        span{
          padding-bottom: 10px;
          border-bottom: 3px solid transparent;
          transition: 0.3s;
          font-size: 16px;
          color: $forum-font-lightgray;
          font-weight: 300;
        }
      }
      .left{
        margin-right: 10px;
        border-radius:  0px 15px  0px  25px;
      }
      .right{
        margin-right: 0px;
        border-radius: 20px 0px  25px  0px;
      }
      .active, div:hover{
        background-color: #0f1a2471;
        span{
          font-weight: 500;
          color: aliceblue;
          border-color: $form_blue-logo;
        }
      }
    } 

  }


/////////////////////////////////////////////////
  .profile_page_bot{
    margin-top: 0px;
    .profile_page_messages{
      border-radius: 15px;
      max-width: 640px !important;
      background-color: $forum-light;
      margin-bottom: 0px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      padding-top: 10px;
      color: $forum-font-header;
      font-size: 10px;
      display: flex;
      flex-direction: row;
      .message_page_mid{
        display: flex;
        flex-direction: row !important;
        .left{
          img{
            width: 60px;
            border-radius: 50%;
            height: 60px;
            object-fit: cover;
            margin-right: 10px;
            margin-left: 10px;
          }
        }
        .top{
          transition: 0.3s;
          margin-top: -5px;
          span{
            transition: 0.3s;
            color: $user_profile_grey;
            font-weight: 300;
            font-size: 14px;
          }
          h1{
            transition: 0.3s;
            color: $forum-font-header;
            font-weight: 600 !important;
            font-size: 17px;
          }
        }
        .top:hover{
          h1{
            color: $form_blue-logo;
          }
          span{
            color: $forum-font-header;
          }
          .pfp{
            border: 2px solid $form_blue-logo;
          }
        }
        .mid{
          transition: 0.3s;
          margin-top: 0px !important;
          max-width: 525px;
          padding-bottom: 10px;
          h1{
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            max-width: 330px;
            overflow-wrap: break-word;
            white-space: pre-line;
          }
          img{
            width: max-content;
            max-height: 600px;
            margin-top: 10px;
            margin-bottom: 10px;
            max-width: 90%;
            border-radius: 24px;
            border: 1px solid rgba(198, 228, 255, 0.299)
          }
        }

        .stats{
          margin-top: 5px;
          padding-top: 0px;
          margin-bottom: 10px;
          width: 75%;
          justify-content: space-between;
          display: flex;
          flex-direction: row;
          margin-bottom: 0px;
          padding-bottom: 10px;
          h1{
            color: $user_profile_grey;
            margin: 0;
            cursor: pointer;
            font-size: 15px;
            transition: 0.3s;
          }
          h1:hover{
            transform: translateX(2px);
          }
          span{
            color: $user_profile_grey;
            margin-left: 10px;
          }
          .unliked:hover, .liked{
            color: #f3607a; 
            span{
              color: #f3607a;
            }
          }
          .notretweeted:hover, .retweeted{
            color: $form_blue-logo; 
            span{
              color: $form_blue-logo;
            }
          }
          .retweeted:hover, .liked:hover{
            color: $user_profile_grey;
            span{
              color: $user_profile_grey;
            }
          }
        }
      }
      .message_page_mid{
        color: $forum-font-white;
        flex-direction: column;
        h1{
          font-weight: 300;
        }
        span{
          color: $form_blue-logo;
        }
      }
    }

    .templte{
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }
    .templte_text{
      display: block;margin: 0 auto;padding: 7px 14px 7px 14px;
      margin-top: 17px;margin-bottom: 17px;border-radius: 15px;background-color: rgba(189, 224, 255, 0.142);
      border: 2px solid rgba(188, 223, 255, 0.107);
      color: rgb(224, 241, 255);
      text-align: center !important;
      font-size: 12px;
      font-weight: 600;
      max-width: 70%;width: max-content ;
    }
    .templte{
      width: 70%;
      max-height: 340px; min-height: 340px;
      object-fit: cover;
      border-radius: 38px;
      border: 5px solid rgba(202, 229, 255, 0.255);
    }
  }
}



@media screen and (min-width: 1030px) and  (max-width: 1310px){
  .profile_page_subtop{
    .relationsRowProfile{
      border: 1px solid rgba(240, 248, 255, 0.072);
      padding-right: 4px;
      border-radius: 10px;
      width: 90%;
      max-width: 90%;
      overflow-x: scroll;
    }
  }
}

@media screen and  (max-width: 1280px){

    .subtop_right{
      flex-direction: column;
      padding-right: 10px;
      .bell{
        order: 3;
        float: right !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      h4{
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
}


@media screen and  (max-width: 1095px ){
    .profile_page_subtop{
      h1{
        max-width: 220px !important;
      }
    }
}
  
@media screen and  (max-width: 1075px ){
  .subtop_right{
    margin-left: -50px;
  }
}



@media screen and  (max-width: 1020px ){
  .profile_page_container{
    margin-right: 0px;
    margin-left: 0px;
  }
}









@media screen and  (max-width: 694px ){
  .profile_page_top{
    .buttons_flex{
      span{
        font-size: 14px !important;
      }
    }
    .profile_picture{
      margin-top: -50px !important;
      width: 100px !important;
      height: 100px !important;
    }

  }
  .buttons_flex{
    div{
      font-size: 16px !important;
    }
  }
}



@media screen and  (max-width: 510px ){
  .profile_page_top{
    .nitro_fd{
      display: none !important;
    }
  }

}


@media screen and  (max-width: 450px   ){
  .profile_page_container{
    .profile_page_subtop{
      .subtop_right{
          h4{
            padding: 8px 16px 8px 16px!important;
            font-size: 15px !important;
          }
      }
    }
  }
}











































