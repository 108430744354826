@import '../settings';


.modal_bg{
    background-color: rgb(248, 252, 255);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    position: absolute;
    box-shadow: 0px 5px 20px $forum-bg;
    margin-left: 28%;
    margin-top: 10%;
    max-width: 400px;
    .top{
        color: $forum-bg;
        h1, h2, h4{
            text-align: center;
        }
        h1{
            font-size: 25px;
        }
        h2{
            color: rgba(60, 60, 60, 0.791);
            font-weight: 400;
            font-size: 16px;
        }
        h3{
            color: rgb(125, 125, 125);
            font-size: 13px;
            font-weight: 400;
            width: 85%;
            span{
                font-weight: 500;
                color: $form_blue-logo;
            }
        }
        p, input{
            color: rgb(69, 69, 69);
        }
        p{
            font-weight: 600;
            margin-top: 30px;
            margin-bottom: 8px;
            text-align: left;
        }
        input{
            width: 95%;
            border-radius: 6px;
            padding: 11px;
            border: 0px solid transparent;
            background-color: rgb(204, 210, 215);
            font-size: 15px;
        }

        .uploadBtn{
            width: 100px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            transition: 0.3s;
            border: 2px dashed $forum-bg ;
            text-align: center;
            .icon{
                transition: 0.3s;
                margin-top: 26px;
                font-size: 24px;
            }
            h4{
                font-size: 15px;
                color: $forum-bg;
                margin-top: auto;
                margin-bottom: auto;
            }
            h5{
                font-size: 24px;
                color: $form_blue-logo;
                top: 98px;
                margin-left: 92px;
                position: absolute;
            }
            input{
                display: none;
            }
            .file{
                font-size: 12px;
            }
        }
        .uploadBtn:hover{
            border: 2px solid $form_blue-logo;
            .icon{
                color: $form_blue-logo;
            }
        }
    }
    .bottom{
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 30px;
        margin-bottom: -10px;
        background-color: rgb(230, 238, 243);
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 0px 0px 10px 10px;
        button{
            border-radius: 8px;
            font-size: 16px;
            background-color: transparent;
            color: $forum-bg;
            padding-left: 8px;
            transition: 0.3s;
            border: 0px solid transparent;
        }
        .return{
            padding: 7px 16px 7px 14px;
            box-shadow: 0px 3px 0px rgb(174, 217, 255);
            border: 2px solid rgb(174, 217, 255);
            background-color:  rgb(209, 233, 255);
        }
        .return:hover{
            border-color: $form_blue-logo;
            box-shadow: 0px 0px 0px  $form_blue-logo;
        }
        .create{
            padding: 12px 27px 12px 27px;
            border: 2px solid #198bd2;
            box-shadow: 0px 3px 0px #198bd2;
            color: white;
            background-color: $form_blue-logo;
        }
        .create:hover{
            border-color: transparent;
            box-shadow: 0px 0px 0px;
        }
    }
}