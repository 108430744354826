@import '../settings';

.header_bg{
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    height: 85px;
    width: 100%;
    padding-bottom: 13px;
    justify-content: space-between;
    color: $forum-font-lightgray;
    .left_part{
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            flex-direction: row;
            span, small{
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
            }
            span{
                color: $form_blue-logo;
                font-size: 20px;
                margin-left: 35px;
            }
            small{
                font-family: $nitro-font;
                background: -webkit-linear-gradient( #1271e7,  #47aded);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 18px;
                margin-left: 8px;
                font-weight: 700;
            }
            .searchBar{
                margin-left: 15px;
                border-radius: 20px;
                display: flex;
                width: max-content;
                background-color: $forum-light;
                transition: 0.3s;
                flex-direction: row;
                height: max-content;
                margin-top: auto;
                margin-bottom: auto;
            }
            .searchBar:hover{
                input, input:focus, .searchIcon{
                    border-color: $form_blue-logo;
                }
            }
            .searchIcon{
                height: 20px;
                font-size: 13px;
                padding: 10px 15px 10px 10px;
                border-radius: 0px 16px 16px 0px;
                color: rgba(219, 238, 255, 0.901);
                margin-left: -4px;
                transition: 0.2s;
                background-color: $forum-light;
                border-right: 2px solid $forum-header-bg;
            }
            input{font-family: $alt-font;}
            input{
                border-left: 2px solid  $forum-header-bg;
                border-radius: 16px 5px 5px 16px;
                width: 200px;
                padding: 10px 10px;
                border-right: 0px;
                background-color: rgb(21, 31, 40);
                color: $forum-font-header;
                transition: 0.2s;
                font-size: 14px;
                caret-color: transparent;
                font-weight: 400 !important;
            }
            input:focus{
                outline: none;
            }
            input, .searchIcon{
                border-top: 2px solid  $forum-header-bg;
                border-bottom: 2px solid  $forum-header-bg;
            }
            ::placeholder{
                padding-left: 2px;
                color: rgba(123, 148, 170, 0.785);
                font-size: 14px;
            }

            .searchResponses_stg{
                right: inherit !important;
                margin-top: 8px !important;
                margin-left: -20px !important;
                small{
                    margin-left: 0px;
                    font-size: 15px;
                    font-family: $home-font;
                }
            }
             
    }
    .nitroinfs{
        margin-left: 8px;
        padding-left: 4px !important;
        padding-right: 8px !important;
        border: 1px solid rgba(168, 214, 255, 0.093) !important;
        border-radius: 15px !important;
        @keyframes fadeGradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        background: linear-gradient(
            -45deg,
            rgb(1, 3, 48),
            #003249,
            #0e2350,
            #042549,
            rgb(33, 78, 141),
          );
          animation: fadeGradient 10s ease infinite;
          background-size: 200% 200%;
        .ntro{  
            background: linear-gradient(
                -35deg,
                #5869ff,
                #243ee7,
                rgb(87, 202, 255),
                #243ee7,
                #5869ff,
            );
            background-size: 200% 200%;
        }

    }
    .right_part{
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: row;
        .ntro_title, .ntro{
            margin-bottom: auto;
            margin-top: auto;
        }
        .ntro_title{
            margin-left: 5px;font-weight: 800;
            color: rgb(213, 235, 255);
            font-size: 13px;background: -webkit-linear-gradient( #1271e7,  #47aded);
            -webkit-background-clip: text;-webkit-text-fill-color: transparent;
            padding-right: 6px;position: relative;
        }

        .ntro{
            height: 24px;width: 24px;border: 2px solid rgba(201, 230, 255, 0.447);
            display: flex;border-radius: 9px;flex-direction: column;justify-content: center;
            text-align: center;background-color: $form_blue-logo;
            color: aliceblue;font-size: 15px;
        }
        div{
            display: flex;
            flex-direction: row;
            border-radius: 20px;
            transition: 0.2s;
            margin-top: auto;
            margin-bottom: auto;
            border: 2px solid transparent;
            padding: 4px 1px 4px 1px;
        }
        .notifCounts{
            background-color: $form_blue-logo ;
            border: 2px solid rgba(151, 206, 255, 0.994);
            margin-left: 19px;
            position: absolute;
            width: max-content;
            min-width: 11px;
            margin-top: -9px;
            padding: 1px !important;
            border-radius: 6px;
            small{
                color: aliceblue;
                font-size: 11px;
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                font-weight: 600;
            }    
        }
        .right_child{
            height: max-content !important;
            margin-top: 0px;
            padding: 6px 3px 6px 3px !important;
            height: 50px;
            border: 2px solid rgba(71, 102, 131, 0.7);
            border-radius: 12px;
            background-color: rgba(53, 65, 76, 0.434);
        }
        .right_child:active{
            border-color: $form_blue-logo;
            transform: scale(0.97);
        }
        .selected{
            transition: 0.2s;
            background-color: #1da0f228;
            border: 2px solid #1da0f28c;
            padding: 4px 14px 4px 10px;
            button{
                color: #1da1f2;
                margin-right: 4px;
            }
            
        }
        button{
            color: rgba(71, 102, 131, 0.8);
            font-size: 20px;
            border: none;
            background-color: transparent;
            transition: 0.3s;
            cursor: pointer;
            user-select: none;
        }
        .notiff:hover{
            .notifCounts{
                transform: translateY(-1px) !important;
            }
        }
        button:hover{
            color: $form_blue-logo;
            transform: translateY(-1px);
      
        }
        span, button{
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        span{
            color: $forum-font-header;
            font-weight: 600;
            font-size: 15px;

            user-select: none;
        }
   

        .disconnect{
            display: flex;
            flex-direction: row;
            background-color: $forum-header-bg;
            padding: 4px 10px 4px 4px;
            border-radius: 25px;
            border: 2px solid rgb(50, 79, 103);
            cursor: pointer;
            color: $header_white_alt;
            height: 37px;
            margin-left: 25px;
            transition: 0.2s;
            margin-right: 30px;
            img, p{
                user-select: none;
            }
            img{
                width: 35px;
                margin-top: auto;
                margin-bottom: auto;
                height: 35px;
                min-width: 35px;
                border: 2px solid  rgb(27, 47, 62);
                min-height: 35px;
                object-fit: cover;
                transition: 0.2s;
                margin-right: 10px;
                margin-top: -1px;
                border-radius: 50%;
            }
            p{
                max-width: 170px;
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
                margin-bottom: auto;
                margin-top: auto;
            }
            .eb{
                margin-left: 10px;
                margin-right: 5px;
                transition: 0.2s;
            }
            button{
                position: absolute;
                display: none;
                transition: 0.2s;
                margin-left: 15px;
                font-weight: 600;
                color: $form_blue-logo !important;
                margin-top: 30px !important;
                border: 2px solid transparent;
                padding: 5px 10px 5px 10px;
                opacity: 0;
            }
            button:hover{
                transform: scale(0.95);
            }
        }
        .disconnect:hover{
            border-color: $form_blue-logo;
            img{
                border-color: $form_blue-logo;
            }
            button{
                opacity: 100%;
                transition: 0.2s;
                border-radius: 10px;
                font-size: 12px;
                background-color: rgb(43, 58, 72);
                display: block;
                animation: slideTop 1500ms infinite;
            }
            .eb{
                transform: rotateZ(-90deg);
            }
        }
        @keyframes slideTop {
            0% {
                border-color: $form_blue-logo;
                transform: translateY(17px);
            }
            50% {
                border-color: aliceblue;
                transform: translateY(21px);
            }
            100% {
                border-color: $form_blue-logo;
                transform: translateY(17px);
            }
        }
        .disconnect:before{
            content: "";
            display: block;
            width: 2px;
            height: 37px;
            background: $forum-light;
            top: 0%;
            left: -20px;
            position: relative;
        }
    }

}
@media screen and  (max-width: 1200px){
    .left_part{
        small{
            display: none !important;
        }
    }
}
@media screen and  (max-width: 1100px){
    .left_part{
        input{
            width: 150px !important;
        }
    }

    .right_part{
        span{
            display: none !important;
        }
        .selected{
            padding: 4px 6px 4px 10px !important;
        }
    }
}

@media screen and  (max-width: 960px){

    .right_part{
        .disconnect{
            display: none !important;
        }
        margin-right: 20px !important;
    }
}

@media screen and  (max-width: 780px){
    .header_bg{
        .nitroinfs, .ntro_title, .ntro{
            display: none !important;
        }
    }
}
@media screen and  (max-width: 690px){
    .left_part{
        display: none !important;
    }
    .right_part{
        margin-left: auto !important;
        margin-right: auto !important;

        .disconnect{
            margin-right: 0px !important;
        }
    }
}

@media screen and  (max-width: 560px){

}