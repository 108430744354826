@import '../settings';

.connect_first_p{
    height: 100vh;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    background-color: #e5e5ec;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    a{
        font-style: none !important;
    }
    .vdBg{
        z-index: 1;
        width: 100%;
        margin-left: 0%;
        height: 950px;
        position: absolute;
    }
    h1, h2, .btns, h3{
        margin-left: auto;
        text-align: center;
        margin-right: auto;
        z-index: 2;
    }
    h1, h2, h3{
        width: 400px;
        color: #675282c7;
        span{
            background: -webkit-linear-gradient(-20deg, #0f77ff, #3fefff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 800;
        }
    }
    h1{
        margin-top: 0px;
        font-size: 45px;
        font-weight: 800;
        background: -webkit-linear-gradient(-35deg, #8426ff, #ebb0ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0px;
    }
    h2{
        font-size: 15px;
        margin-top: 3px;
        margin-bottom: 3px;
        font-weight: 400;
        i{
            font-weight: 800;
            font-style: normal;
            color: #0f77ff;
        }
    }
    .tp{
        padding-bottom: 6px;
        border-bottom: 2px solid rgba(231, 244, 255, 0.314);
    }
    h3{
        margin-top: 0px;
        color: #7a42d5dc;
    }
    .btns{
        display: flex;
        flex-direction: column;
        width: 200px;
        p{
            font-size: 18px;
            font-weight: 800;
            background: -webkit-linear-gradient(-20deg, #a826ff, #07d6ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
        }
        p:before {
            content: "";
            display: block;
            width: 40%;
            height: 2px;
            left: 0;
            top: 45%;
            position: absolute;
            background: -webkit-linear-gradient(-20deg, #a826ff, #07d6ff);
          }
        p:after {
            content: "";
            display: block;
            width: 40%;
            height: 2px;
            right: 0px !important;
            top: 45%;
            position: absolute;
            background: -webkit-linear-gradient(-20deg, #07d6ff, #a826ff);
        }
        button{
            cursor: pointer;
            color: white;
            font-weight: 800;
            transition: 0.2s;
            padding: 8px 16px 8px 16px;
            border: 2px solid rgba(224, 241, 255, 0.759);
            background-color: rgba(219, 165, 255, 0.106);
            border-radius: 15px;
            backdrop-filter: blur(50px);
        }
        button:hover{
            border-radius: 10px;
        }
        div{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            a{
                width: 45%;
            }
            button{
                width: 100%;
            }
        }
    }
}

@media screen and  (max-width: 800px) {
    .connect_first_p{
            .vdBg{
                width: 180%;
                margin-left: -40%;
            }
    }
}