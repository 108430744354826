@import '../settings';

.addFriends_window{
    display: flex;
    flex-direction: column;
    position: fixed !important;
    border-radius: 10px;
    padding: 20px;
    background-color: $liveChat-2;
    border: 1px solid rgba(240, 248, 255, 0.374);
    box-shadow: 0px 0px 5px $forum-bg;
    width: 35%;
    z-index: 2;
    margin-top: 160px;
    margin-left: 11%;
    width: 475px;
    height: 425px;
    h1{
        margin-top: 0px;
        color: aliceblue;
        margin-bottom: 0px;
    }
    p{
        margin-top: 3px;
        margin-bottom: 23px;
    }
    input{
        border: 2px solid transparent;
        padding: 10px;
        margin-bottom: 12px;
        transition: 0.3s;
        font-size: 15px;
        color: aliceblue;
        background-color: rgb(25, 32, 39);
    }
    input:hover, input:focus{
        outline: none;
        border: 2px solid  $form_blue-logo;
    }
    ::placeholder{
        color: $liveChat-6 !important;
    }
    input, button{
        border-radius: 7px;
    }
    .toppp{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .tags{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        flex-wrap: wrap;
        .tag{
            transition: 0.3s;
            user-select: none;
            margin-right: 7px;
            height: max-content;
            padding: 6px;
            font-weight: 500;
            background-color: $liveChat-2;
            margin-bottom: 5px;
            font-size: 13px;
            border: 1px solid transparent;
            margin-bottom: 0px;
            color: $liveChat-6;
            border-radius: 10px;
        }
        .tag:hover{
            border-color: $liveChat-6;
            transform: scale(0.97);
        }
    }

    .usersScroll{
        width: 100%;
        height: 50%;
        overflow-y: scroll;
        display: flex;
        background-color: rgba(26, 34, 42, 0.511);
        flex-direction: column;
        border-radius: 5px;
        padding-bottom: 10px;
        border-bottom: 2px solid rgb(62, 77, 90);
        .userRow{
            transition: 0.3s;
            height: max-content;
            display: flex;
            flex-direction: row;
            padding: 4px 10px 4px 10px;
            height: max-content;
            justify-content: space-between;
            .lef{
                display: flex;
                flex-direction: row;
            }
            img{
                width: 35px !important;
                height: 35px !important;
                border: 0px !important;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 8px;
                background-color: transparent !important;
            }
             h3, h4, img, .case, h5 {
                user-select: none;
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
            h3{
                color: aliceblue;
                margin-right: 7px;
            }
            h4{
                font-weight: 300 !important;
                color: $liveChat-7;
            }
            h5{
                margin-top: 10px !important;
                color: rgb(255, 129, 129);
                font-size: 13px;
            }
            .case{
                color: white;
                height: 20px;
                text-align: center;
                width: 20px;
                border-radius: 8px;
                transition: 0.3s;
                border: 1px solid $liveChat-7;
                h1{
                    font-size: 17px !important;
                    margin: 0   auto !important;
                }
            }
            .checked{
                img{
                    border: 2px solid $form_blue-logo;
                    background-color: $form_blue-logo;
                }
                border-radius: 4px;
                border-color: $form_blue-logo;
                background-color: $form_blue-logo;
            }
        }
        .userRow:hover{
            background-color: $liveChat-2;
        }
        .banned:hover{
            background-color: transparent !important;
        }
    }
    button{
        margin-top: 15px;
        padding: 10px;
        background-color: $form_blue-logo;
        border: 2px solid transparent;
        font-size: 14px;
        font-weight: 600;
        color: white;
        transition: 0.3s;
    }
    .close{
        font-size: 12px !important;
        border: 1px solid rgba(240, 248, 255, 0.28);
        padding: 5px 8px 5px 8px;
        background-color: rgba(98, 126, 151, 0.322);
    }
    .moreInvite{
        width: 60%;
        margin-left: 10px;
        padding: 7px;
        margin-top: 0px;
        border-color: aliceblue;
    }
    button:hover{
        border-radius: 12px;
        border-color: white;
    }
}