@import '../settings';

.wdwRing{
    width: 30px !important;
    height: 30px !important;
    margin-top: 8% !important;
    margin-bottom: 3% !important;
    border-top: 3px solid #FFF !important;
    border-right: 3px solid transparent !important;
}
.wdwRing:after {
    width: 30px !important;
    height: 30px !important; 
    border-left: 3px solid $form_blue-logo !important;
    border-bottom: 3px solid transparent !important;
}

.suggs_window_p{
    margin-top: 30px;
    border: 1px solid rgba(197, 229, 255, 0.03);
    background-color: $forum-light;
    border-radius: 12px;
    overflow: hidden;
    .swiper{
        width: 100%;
        max-height: 400px;
        position: relative;
        overflow-y: hidden;
        .swiper-slide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .swiper-pagination-bullet{
            width: 11px !important;
            height: 11px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            background-color: rgb(216, 237, 255) !important;
        }
        .swiper-pagination-bullet-active{
            border: 2px solid $form_blue-logo;	
        }
    }
    .userRow{
        cursor: pointer;
        transition: 0.2s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: hidden;
        max-height: 80px;
        z-index: 2;
        .top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            div{
                display: flex;
                flex-direction: row;
            }
            .txts{
                display: flex;
                flex-direction: column;
            }
        }
        padding: 12px 16px;
        padding-left: 10px;
        img{
            min-width: 48px;max-width: 48px;
            object-fit: cover;
            min-height: 48px;max-height: 48px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .chanPct{
            border-radius: 10px;
            border: 1px solid rgba(167, 214, 255, 0.12);
        }
        h1,h2, p{
            font-size: 15px;
            color: aliceblue;
            margin-top: 0px;
            margin-bottom: 2px;
        }
        h1{font-size: 16px;}
        h2{color: rgba(184, 222, 255, 0.553);font-weight: 300;}
        p{margin-top: 5px; text-overflow: ellipsis; overflow-y: scroll;max-height: 50px; }
        .chndc{font-size: 12px;}
        button{
            min-width: max-content;
            padding-left: 16px; padding-right: 16px;
            border-radius: 20px; font-size: 14px;
            margin-right: 23px;
            border: 2px solid transparent;
            background-color: rgb(226, 241, 255);
            height: 33px;
            cursor: pointer;
            transition: 0.2s;
            color: $forum-bg;
            font-weight: 700;
        }
        .followed{
            background-color: $form_blue-logo;
            color: white;
        }
        button:hover{
            border-radius: 12px;
            border-color: $form_blue-logo!important;
            box-shadow: 0px 2px 0px  $form_blue-logo;
            color: $form_blue-logo;
        }
        .followed, .followed:hover{
            color: white;
        }
        .followed{
            background-color: $form_blue-logo;
        }
        .followed:hover{
            border-color: white !important;
            box-shadow: 0px 2px 0px  white;
        }
    }
    .chnRow{
    }
    .userRow:hover{
        background-color: rgba(194, 226, 255, 0.033);
    }
    .chNe{margin-top: -3px !important;}
    .ttl, .shwm{margin-top: 0px;margin-bottom: 0px;}
    .ttl{font-weight: 800;font-size: 20px; color: aliceblue;padding: 12px 16px;padding-top: 18px;padding-bottom: 16px; border-bottom: 1px solid rgba(204, 231, 255, 0.05);span{font-weight: 700;color: $form_blue-logo;}}
    .shwm{font-size: 16px; color: $form_blue-logo;font-weight: 400; padding: 14px;cursor: pointer; border-top: 1px solid rgba(207, 233, 255, 0.05);}
}
