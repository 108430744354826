@import '../settings';

.edit_body{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    z-index: 1;
    background-color: rgba(135, 168, 187, 0.1);
}

.edit_card{
    input{font-family: $alt-font;}
    .upload_pic{
        display: none;
    }
    background-color: $forum-bg;
    width: 600px;
    height: max-content;
    margin: auto;
    height: 650px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    padding-top: 0px;
    padding: 20px;
    border-radius: 20px;
    color: $forum-font-white;
    z-index: 2;
    .one, .thr, .ne, .title{
        display: flex;
        flex-direction: row;
    }
    .thr{
        flex-direction: column;
    }
    .close{
        background-color: $forum-clear;
        padding: 5px 10px 4.5px 10px;
        border-radius: 50%;
        transition: 0.3s;
        margin-right: 45px;
    }
    .close:hover{
        color: $form_blue-logo;
    }
    .one{
        margin-bottom: 15px;
        justify-content: space-between;
    }
    .two{
        display: flex;
        flex-direction: column;

        p{
            cursor: pointer;
        }
    }
    img{
        transition: 0.3s;
    }
 
    .pfp:hover{
      transform: scale(0.96);
    }
    .pfp{
        width: 115px;
        border: 2px solid $forum-bg;
        background-color: $forum-bg;
        margin-left: 10px;
        margin-top: -50px;
        height: 115px;
        object-fit: cover;
        border-radius: 50%;
    }
    .prf{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .ef{
            display: flex;
            flex-direction: row;
            p{
                margin-right: 10px;
            }
        }
        p{
            margin-top: auto;
            margin-bottom: auto;
            transition: 0.3s;
            border: 2px solid transparent;
            margin-right: 0px;
            padding: 6px 10px 6px 10px;
            border-radius: 10px;
            background-color: $forum-clear;
            font-size: 16px;
            font-weight: 300;
        }
        p:hover{
            border-color: $forum-font-white;
            transform: scale(0.95);
        }
        span{
            font-weight: 600;
            color: $form_blue-logo;
        }
        input{
            border-radius: 20px;
            margin-top: auto;
            margin-bottom: auto;
            width: 190px;
            background-color: $forum-bg;
            font-size: 19px;
            padding: 5px;
            color: $forum-font-white;
            border: 2px solid $form_blue-logo;
            height: 25px;
        }
        .pfp_d{
            font-size: 17px;
            margin-left: 5px;
            margin-top: 3px;
        }
        .dd{
            display: flex;
            flex-direction: row;
        }
    }
    .banner{
        border: 2px solid $forum-bg;
        width: 100%;
        object-fit: cover;
        max-height: 195px;
        border-radius: 15px;
    }
    img:hover{
        border-color: $form_blue-logo;
    }


    h2, h3{
        margin-top: auto;
        margin-bottom: auto;
    }
    h2{
        font-size: 20px;
    }
    h3, h6{
        border: 1px solid transparent;
        transition: 0.3s;
        color: $forum-bg;
        font-size: 14px;
        background-color: $forum-font-white;
        font-weight: 600;
        border-radius: 20px;
        padding: 10px 20px 10px 20px;
    }
    h4{
        margin-left: 5px;
        font-size: 18px;
        margin-bottom: 0px;
    }
    h5{
        font-size: 16px;
        margin-top: 10px;
        margin-left: 5px;
        font-weight: 300;
        margin-bottom: 0px;
        color: $forum-font-lightgray;
    }
    h6{
        margin-top: 20px;
        margin-bottom: 10px;
        width: max-content;
    }

    h6:hover, h3:hover{
        box-shadow: 0px 2px 0px $forum-font-white;
        background-color: $form_blue-logo;
        color: $forum-font-white;
        border-color: $forum-font-white;
    }
    .namesArea {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .fst{
            margin-right:15px !important;
            width: 40% !important;
        }
        .scd{
            width: 60%;
        }
        .bot_caut{
            margin-top: 10px;
            font-size: 17px;
            margin-bottom: 0px;
            color: $form_blue-logo;
        }
    }

    .textArea{
        transition: 0.3s;
        padding-left: 7px;
        padding-right: 7px;
        margin-top: 20px;
        border-radius: 13px;
        border: 1px solid #45505a;
        .title{ 
            justify-content: space-between;
        }
        p{
            margin-top: 10px;
            font-size: 13px;
            margin-bottom: 5px;
            color: $forum-font-lightgray;
        }
        input{
            border: 0px solid;
            background-color: $forum-bg;
            margin-bottom: 6px;
            width: 100%;
            font-size: 18px;
            color: $forum-font-white;
        }
        input:focus{
            outline: none;
        }
    }
    .textArea:hover{
        border-color: $form_blue-logo;
        p{
            color: $form_blue-logo;
        }
    }
    .psw{
        flex-direction: column;
    }
 
}

@media screen and  (max-width: 650px){
    .edit_card{
        width: 360px !important;
    }
    .one{
        h2{
            font-size: 17px !important;
        }
        h3{
            padding:10px !important;
            text-align: center;
            font-size: 15px !important;
        }
    }
    .prf{
        p{
            margin-right: 0px !important;
            text-align: center !important;
            padding: 3px !important;
            font-size: 12px !important;
        }
    }
}

@media screen and  (max-width: 407px    ){
    .edit_card{
        width: 325px !important;
    }
  
}