@import '../settings';

.Bookmarks_Page{
    color: $forum-font-header;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 5px;

    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    border: 1px solid rgba(193, 226, 255, 0.089);
    min-height: 92vh;
    background-color: #15212a;
    .tttle{
        font-size: 31px;
        margin-left: 10px;
        margin-bottom: 8px;
    }
    .titlesnc{
        font-size: 15px;
        background-color: rgb(17, 25, 33);
        padding: 6px 10px 6px 10px;
        border-radius: 8px;
        width: max-content;
        border: 1px solid rgba(194, 227, 255, 0.098);
        margin-top: 0px;
        margin-left: 13px;
        span{
            color: $form_blue-logo;
        }
    }
    .bookmark{
        margin-top: 20px;
        padding: 6px;
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        border-radius: 28px;
        border: 1px solid rgba(148, 205, 255, 0.183);
        .message_card_container{
            margin: 0px !important;
            .msg_desc_div{
                max-width: 410px;
            }
            .msg_left_cntnr{
                margin-left: 7px;
            }
            .msg_right_cntnr{
                max-width: 78% !important;
                margin-right: 10px;
            }
        }
        .msgRelation{
            display: none;
        }
    }
    .noBookmarks{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        h2, h3, img{
            max-width: 50%;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
        }
        img{
            width: 100%;
            border-radius: 15px;
            border: 6px solid rgba(205, 205, 205, 0.227);
        }
        h2{
            color: aliceblue;
            margin-bottom: 8px;
            font-size: 26px;
        }
        h3{
            color: rgba(240, 248, 255, 0.337);
            font-size: 17px;
            font-weight: 300;
        }
    }

}
@media screen and  (max-width: 1240px){
    .message_card_container{
        .msg_left_cntnr{
            img{
                width: 60px !important;
                height: 60px !important;   
            }
            margin-right: 5px !important;
        }
        q{
            display: none;
        }
        .msg_right_cntnr{
        }
    } 
 }

 @media screen and  (max-width: 1108px){
    .bookmark{
        padding: 6px !important;
        max-width: 97% !important;
        .message_card_container{
            overflow-x: scroll !important;
        }
    }
 }

