@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap'); // 1
//@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap'); // 2
//@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap'); // 3
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap'); // 4
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700&display=swap');// 5

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'toes';
  src: url('../media/font/Ginto-700.ttf');
}

$main:'Roboto', sans-serif; /// <= DO NOT DELETE 'Inter' > 'Works Sans' > 'Anuphan' > 'Roboto
$home-font:  'Inter', sans-serif;
$alt-font:'Inter', sans-serif; //// <= MAIN FONT

//$nitro-font: 'Bungee Inline', cursive; // 1
//$nitro-font: 'Russo One', sans-serif; // 2
//$nitro-font: 'Archivo Black', sans-serif; // 3
//$nitro-font: 'Readex Pro', sans-serif;// 4
$nitro-font: 'toes';// 5

$homle-page-bg: rgb(12,21,40);
$color-1 : rgb(22, 38, 73);
$color-2 : #b8b2c1;
$color-3 : #9a4dff;
$color-4 : #6637a3;
$color-5 : rgb(35, 53, 94);
$color-6:   rgb(15, 30, 62);

$prpl-color: rgb(79, 14, 191);
$light-prpl: rgb(221, 163, 255);

$home-grey: rgb(237, 239, 245);
$home-semigrey : rgb(195, 199, 211);
$home-darkgrey : rgb(118, 118, 118);
$home-shadow :  rgb(210, 210, 210);

$font-black: #374049;
$font-grey: #4c565f;
$font-lightgray: #99a2aa;
$font-verylight: rgb(183, 202, 218);

$forum-notif : #08202e;
// #15202c

// #06141d
$forum-bg : #091a25;
$forum-bg-alt : #15212a;
$forum-light : #182631;
$forum-clear: #263642;
$forum-message: #23394a;
$forum-header-bg: #30404c;
$form_blue-logo: #1da1f2;
$forum_grey :#59768e;

$forum-notif_0 : #101b23c5;
$forum-notif_1 : #192631;
$forum-notif_2 : #263542;
$forum-notif_3 : #182730;
$forum-notif_font : #4d666d;

$header_white_alt : #dcecf0;
$forum_font_white: #edeff0;
$forum-font-lightgray: #748ba1;

$forum-font-header: #d8e8f8;

$forum-semilight : #14222c;

$new_messg_grey: #adbcca;

$alt_msg_border:#313f4b;

$user_profile_grey: #71889c;

$live-input: #364450;

$liveChat-1:  #2c3842;
$liveChat-2:  #222e38;
$liveChat-3:  #2e3c47;
$liveChat-4:  #15202a;
$liveChat-5:  #212b33;
$liveChat-6:  #9aaec2;
$liveChat-7:  #84a5bf;
$liveChat-8:  #384b5a;

$liveChat-9:  #99adbd;
$liveChat-10:  rgb(217, 230, 241);


$livechat-font:  rgba(207, 224, 239, 0.773);
*{
  //  font-family: $main-font;
    text-decoration: none;
    a:link, a:visited {
      color: inherit !important;
      text-decoration: none !important;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}
*::-webkit-scrollbar {
  display: none;
}


body{
    margin: 0px !important;
    background-color: $forum-bg;
    overscroll-behavior: none !important;
}

@mixin body_alt_class($bg-color){
  display: inline-block;
  padding: 0 !important;
  margin: 0 !important;
  background-color: $bg-color;
  width: 100vw ;
  height: 100vh ;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

$side_max_width: 325px;


.settingsWindow , .parr{
    .switch {
      width: 40px;
      height: 20px;
      background-color: $liveChat-6;
      border-radius: 25px;
      margin-top: auto;
      margin-bottom: auto;
      display: block !important;
      cursor: pointer;
      border: 2px solid transparent;
      transition: 0.3s;
      .rd{
          background-color: aliceblue;
          font-size: 15px;
          color: $liveChat-6;
          text-align: center;
          margin-left: 2px;
          margin-top: 1px;
          border: 1px solid #06141da7;
          width: 16px;
          height: 16px;
          transition: 0.3s;
          border-radius: 50%;
      }
  }

  .switch:active{
      transform: scale(0.90);
  }
  .clicked{
    border-color:  $form_blue-logo;
    border: 2px solid white;
    background-color: $form_blue-logo;
    .rd{
        color: $form_blue-logo;
        border: 1px solid $form_blue-logo;
        transform: translateX(18px);
    }
  }
}

.searchResponses_stg{
  z-index: 7;
  position: absolute;
  right: 0px;
 margin-top: 60px;
  background-color: $forum-bg;
  border-radius: 10px;
  padding: 12px;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid rgba(176, 218, 255, 0.071);
  color: white;
  width: 300px;
  h1{
      font-size: 21px;
      text-align: center;
      overflow-x: hidden;
      color: $form_blue-logo;
      text-overflow: ellipsis;
  }
  h4{
      text-align: center;
      font-size: 15px;
      small{
          font-size: 15px;
          color: $form_blue-logo;
      }
  }
  h5{
      cursor: pointer;
      padding: 10px;
      background-color: $form_blue-logo;
      border-radius: 10px;
      border: 2px solid white;
      text-align: center;
  }
  p{cursor: pointer;}
  .profileSearched{
      cursor: pointer;
      background-color: $forum-light;
      margin-bottom: 4px;
      display: flex;
      height: 52px;
      border-radius: 10px;
      transition: 0.2s;
      border: 2px solid transparent;
      flex-direction: row;
      div{
          display: flex;
          flex-direction: column;
          justify-content: center;
      }
      img{
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-left: 8px;
          margin-right: 8px;
      }
      h2{
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 15px;
      }
      .gry{
          font-weight: 400;
          font-size: 14px;
          color: rgba(200, 229, 255, 0.584);
      }
  }
  .profileSearched:hover{
      border-color: $form_blue-logo;
  }
}


// .lds-dual-ring {
//   display: inline-block;
//   width: 80px;
//   height: 80px;
//   margin-top: 30%;
//   margin-left: 48%;
// }
// .lds-dual-ring:after {
//   content: " ";
//   display: block;
//   width: 64px;
//   height: 64px;
//   margin: 8px;
//   border-radius: 50%;
//   border: 8px solid #fff;
//   border-color: #fff transparent #fff transparent;
//   animation: lds-dual-ring 1.2s linear infinite;
// }
// @keyframes lds-dual-ring {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}



.lds-dual-ring {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;

  margin-top: 30%;
  margin-left: 47%;

  animation: rotation 1s linear infinite;
}
.lds-dual-ring:after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid $form_blue-logo;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
